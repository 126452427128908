import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { NavBar, Alert } from '../../components';
import { useFeatureContext } from '../../context/FeatureContext';
import { useAdminContext } from '../../context/AdminContext/admincontext';

const formData = {
  name: '',
  email: '',
  password: '',
};
const AdminPannel = () => {
  const [loginType, setloginType] = useState(true);

  const { showAlert, updatePageName } = useFeatureContext();

  const { customerSubId, token, AdminLogin, customerSignIn } =
    useAdminContext();

  const navigate = useNavigate();

  const [values, setvalues] = useState(formData);

  const handleChange = (e) => {
    setvalues({ ...values, [e.target.name]: e.target.value });
  };

  const adminSignIn = async () => {
    const info = {
      name: values.name,
      email: values.email,
      password: values.password,
    };

    await AdminLogin(info);
  };

  useEffect(() => {
    if (token) {
      updatePageName('admin');
      navigate('/adminDashboard');
    } else if (customerSubId) {
      updatePageName('customer');
      navigate('/customerProfile');
    }
  }, [token, navigate, updatePageName, customerSubId]);

  const customerLogin = async () => {
    const data = {
      name: values.name,
      email: values.email,
      subId: values.password,
    };
    await customerSignIn(data);
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (loginType) {
      await adminSignIn();
    } else {
      await customerLogin();
    }
  };

  const handleCustomerLogin = (e) => {
    e.preventDefault();
    setloginType(!loginType);
  };

  return (
    <div className=' flex flex-col items-center justify-center  '>
      {showAlert && <Alert />}
      <NavBar />
      <div className='flex flex-col w-[1200px] justify-center items-center relative'>
        <p className='font-aulire text-4xl sm:text-2xl text-ss_primary mt-8 '>
          {loginType ? 'Admin Login' : 'Customer Login'}
        </p>
        <button
          onClick={handleCustomerLogin}
          className='absolute top-6 right-4 py-2 px-4 h-[51px] sm:h-auto text-[16px] leading-[34px] bg-ss_primary rounded-[5px] text-ss_white sm:mt-2 z-50'
        >
          {loginType ? 'Customer Login' : 'Admin Login'}
        </button>
        <div className='flex flex-col justify-start items-center sm:mt-6'>
          <form
            onSubmit={onSubmit}
            className=' flex flex-col gap-12 justify-center items-center mt-16 sm:mt-10'
          >
            <div className=' flex flex-row gap-10 justify-center items-center'>
              <label className='text-[24px] sm:text-lg sm:leading-6 leading-[34px]  text-ss_primary font-aulire'>
                User Name :
              </label>
              <input
                onChange={handleChange}
                required
                name='name'
                value={values.name}
                className='w-[350px] h-[57px] sm:w-[200px] sm:h-[33px] bg-ss_grey text-ss_black text-xl sm:text-lg rounded-[5px] p-4'
              />
            </div>
            <div className=' flex flex-row gap-10 justify-center items-center'>
              <label className='text-[24px] sm:text-lg sm:leading-6 leading-[34px]  text-ss_primary font-aulire'>
                User Email :
              </label>
              <input
                onChange={handleChange}
                required
                name='email'
                value={values.email}
                className='w-[350px] h-[57px] sm:w-[200px] sm:h-[33px] bg-ss_grey text-ss_black text-xl sm:text-lg rounded-[5px] p-4'
              />
            </div>
            <div className=' flex flex-row gap-8 justify-center items-center'>
              <label
                className={`text-[24px] leading-[34px] ${
                  loginType ? 'ml-0' : 'ml-[-40px]'
                } sm:text-lg sm:leading-6 text-ss_primary font-aulire`}
              >
                {loginType ? 'PassWord :' : 'SubscriptionId :'}
              </label>
              <input
                onChange={handleChange}
                value={values.password}
                name='password'
                type='password'
                required
                className={` text-xl sm:text-lg  w-[350px] h-[57px] sm:w-[200px] ${
                  loginType ? 'ml-5' : 'ml-0'
                } sm:h-[33px] bg-ss_grey text-ss_black rounded-[5px] p-4`}
              />
            </div>

            <button className='py-2 px-4 w-[113px] h-[51px] sm:h-auto text-[23px] leading-[34px] bg-ss_primary rounded-[5px] text-ss_white sm:mt-2 z-50'>
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminPannel;

import axios from 'axios';
import React, { useContext, useReducer } from 'react';
import { useFeatureContext } from '../FeatureContext';
import reducer from './reducer';

import { AUTH_SUCCESS, CUSTOMER_LOGIN } from './actions';

const AdminContext = React.createContext();

const token = localStorage.getItem('token');
const admin = JSON.parse(localStorage.getItem('admin'));

const initialState = {
  token: token ? token : null,
  admin: admin ? admin : {},
  customerSubId: null,
  customer: {},
};

const AdminProvider = ({ children }) => {
  const apiUrl = process.env.REACT_APP_API_ENVIRONMENT_URL;

  const { setIsLoading, displayAlert, updatePageName } = useFeatureContext();
  const [state, dispatch] = useReducer(reducer, initialState);

  const config = {
    headers: { Authorization: `Bearer ${state.token}` },
  };

  const setLocalStorage = (token, admin) => {
    localStorage.setItem('token', token);
    localStorage.setItem('admin', JSON.stringify(admin));
  };

  const removeLocalStorage = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('admin');
  };

  const AdminLogin = async (values) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${apiUrl}/signInAdmin`, values);
      const { token, admin } = response.data.data;
      setLocalStorage(token, admin);
      dispatch({
        type: AUTH_SUCCESS,
        payload: {
          token: token,
          admin: admin,
        },
      });
      setIsLoading(false);
      displayAlert(response.data.message, true);
      updatePageName('admin');
    } catch (error) {
      setIsLoading(false);
      displayAlert(error.response.data.message, false);
    }
  };

  const AdminLogout = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${apiUrl}/signInAdmin`, config);
      removeLocalStorage();
      setIsLoading(false);
      displayAlert(response.data.message, true);
    } catch (error) {
      setIsLoading(false);
      displayAlert(error.response.data.message, false);
    }
  };

  //!get customer data
  const getAllCustomer = (searchField) => {
    setIsLoading(true);
    axios
      .get(`${apiUrl}/getCustomers`, config)
      .then((res) => {
        setIsLoading(false);
        return res.data.data;
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const authorizedEnv = process.env.REACT_APP_ENVIRONMENT_AUTHORIZED_NET;
  const authorizedName = process.env.REACT_APP_CLINT_KEY_AUTHORIZED_NET;
  const authorizedTranscation =
    process.env.REACT_APP_TRANSACTION_KEY_AUTHORIZED_NET;

  const customerSignIn = async (info) => {
    setIsLoading(true);
    await axios
      .post(authorizedEnv, {
        ARBGetSubscriptionRequest: {
          merchantAuthentication: {
            name: authorizedName,
            transactionKey: authorizedTranscation,
          },
          subscriptionId: info.subId,
          includeTransactions: true,
        },
      })
      .then((res) => {
        if (res.data.messages.resultCode !== 'Error') {
          const name =
            res.data.subscription.profile.paymentProfile.billTo.firstName +
            ' ' +
            res.data.subscription.profile.paymentProfile.billTo.lastName;
          const email = res.data.subscription.profile.email;
          if (
            email === info.email &&
            name.toLowerCase() === info.name.toLowerCase()
          ) {
            dispatch({
              type: CUSTOMER_LOGIN,
              payload: {
                id: info.subId,
                customer: res.data.subscription,
              },
            });
            updatePageName('customer');
            displayAlert('Successfully Logged In !', true);
          } else {
            displayAlert('Information does not match !', false);
          }
        } else {
          displayAlert('Please Input Correct Information !', false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <AdminContext.Provider
      value={{
        ...state,
        AdminLogin,
        AdminLogout,
        getAllCustomer,
        customerSignIn,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

const useAdminContext = () => {
  return useContext(AdminContext);
};

export { AdminProvider, initialState, useAdminContext };

import React from 'react';

const CreditPageNav = ({ processState }) => {
  return (
    <div className=' grid grid-flow-col sm:grid-flow-row sm:grid-cols-3 gap-20 sm:gap-4 place-self-center text-ss_grey text-center ml-[-30px] sm:ml-[-48px] p-6 sm:mt-4'>
      <div className='place-items-center text-[23px] sm:text-[11px] leading-[34px] sm:leading-[16px]'>
        <span
          className={`${
            processState === 'register' ? 'bg-ss_primary' : 'bg-ss_grey'
          } rounded-md py-1 px-2 mr-4 sm:mr-1 text-ss_white`}
        >
          1
        </span>
        Register
      </div>
      <div className=' text-[23px] leading-[34px] sm:text-[11px] sm:leading-[16px]'>
        <span
          className={`${
            processState === 'verify' ? 'bg-ss_primary' : 'bg-ss_grey'
          } rounded-md py-1 px-2 mr-4 sm:mr-1 text-ss_white`}
        >
          2
        </span>
        Verify Identity
      </div>
      <div className=' text-[23px] leading-[34px] sm:text-[11px] sm:leading-[16px]'>
        <span
          className={`${
            processState === 'package' ? 'bg-ss_primary' : 'bg-ss_grey'
          } rounded-md py-1 px-2 mr-4 sm:mr-1 text-ss_white`}
        >
          3
        </span>
        Package
      </div>
      <div className=' text-[23px] leading-[34px] sm:text-[11px] sm:leading-[16px] sm:ml-[4px]'>
        <span
          className={`${
            processState === 'payment' ? 'bg-ss_primary' : 'bg-ss_grey'
          } rounded-md py-1 px-2 mr-4 sm:mr-1 text-ss_white`}
        >
          4
        </span>
        Payment
      </div>
      <div className='text-[23px] leading-[34px] sm:text-[11px] sm:leading-[16px] sm:ml-[-22px]'>
        <span
          className={`${
            processState === 'complete' ? 'bg-ss_primary' : 'bg-ss_grey'
          } rounded-md py-1 px-2 mr-4 sm:mr-1 text-ss_white`}
        >
          5
        </span>
        Complete
      </div>
    </div>
  );
};

export default CreditPageNav;

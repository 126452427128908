import React from 'react';

const Header = ({ head }) => {
  return (
    <div className=' font-semibold text-center text-[24px] sm:text-xs leading-[29px] sm:leading-4 text-ss_old_grey '>
      {head}
    </div>
  );
};

export default Header;

import React, { useContext, useState } from 'react';

const initialState = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  sponsorCode: '',
  ssn: '',
  ssnShort: '',
  birthDate: '',
  streetAdress: '',
  zipCode: '',
  city: '',
  state_country: '',
  psw: '',
  trackingToken: '',
  customerToken: '',
  premiumPackage: false,
};
const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [state, setState] = useState(initialState);
  const updateFirstName = (firstName) => {
    setState({ ...state, firstName: firstName });
  };

  const stateUpdateA = (
    firstName,
    lastName,
    phoneNumber,
    email,
    sponsorCode,
    psw,
    trackingToken
  ) => {
    setState({
      ...state,
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      email: email,
      sponsorCode: sponsorCode,
      psw: psw,
      trackingToken: trackingToken,
    });
  };

  const stateUpdateB = (
    ssn,
    birthDate,
    streetAdress,
    zipCode,
    city,
    state_country,
    customerToken
  ) => {
    setState({
      ...state,
      ssn: ssn,
      birthDate: birthDate,
      streetAdress: streetAdress,
      zipCode: zipCode,
      city: city,
      state_country: state_country,
      customerToken: customerToken,
    });
  };
  const stateUpdateD = (city, state_country) => {
    setState({
      ...state,
      city: city,
      state_country: state_country,
    });
  };
  const premiumPackageUpdate = (value) => {
    setState({ ...state, premiumPackage: value });
  };
  const stateUpdateAgrement = (
    firstName,
    lastName,
    birthDate,
    ssnShort,
    streetAdress,
    city,
    state_country,
    zipCode,
    phoneNumber,
    email
  ) => {
    setState({
      ...state,
      firstName: firstName,
      lastName: lastName,
      birthDate: birthDate,
      ssnShort: ssnShort,
      streetAdress: streetAdress,
      city: city,
      state_country: state_country,
      zipCode: zipCode,
      phoneNumber: phoneNumber,
      email: email,
    });
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        updateFirstName,
        stateUpdateA,
        stateUpdateB,
        stateUpdateD,
        premiumPackageUpdate,
        stateUpdateAgrement,
      }}>
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, initialState, useAppContext };

import React, { useState } from 'react';
import MainImg from '../../components/MainImg';
import Header from '../../components/Header';
import Steps from '../../components/Steps';
import NavBar from '../../components/NavBar';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../../../context/appContext';
import { useFeatureContext } from '../../../../../context/FeatureContext';
import CompanyLogo from '../../../../../components/CompanyLogo';
import { Alert } from '../../../../../components';

const page__data = {
  FirstName: '',
  LastName: '',
  dateOfBirth: '',
  ssnShort: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
  PhoneNumber: '',
  Email: '',
};

const ContactInformation = () => {
  const navigate = useNavigate();
  const [values, setvalues] = useState(page__data);
  const {
    firstName,
    lastName,
    birthDate,
    ssnShort,
    streetAdress,
    city,
    state_country,
    zipCode,
    phoneNumber,
    email,
    stateUpdateAgrement,
  } = useAppContext();
  const { updatePageName, displayAlert, setIsLoading, showAlert } =
    useFeatureContext();

  const handleChange = (e) => {
    setvalues({ ...values, [e.target.name]: e.target.value });
  };

  //! email vlidation

  const isEmail = (emailAdress) => {
    const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (emailAdress.match(regex)) return true;
    else return false;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (isEmail(values.Email)) {
      updatePageName('plans');
      stateUpdateAgrement(
        values.FirstName,
        values.LastName,
        values.dateOfBirth,
        values.ssnShort,
        values.address,
        values.city,
        values.state,
        values.zipCode,
        values.PhoneNumber,
        values.Email
      );
      navigate('/plans');
      setIsLoading(false);
    } else {
      setIsLoading(false);
      displayAlert('Invalid Email');
    }
  };
  return (
    <div className='flex justify-center items-center sm:overflow-hidden'>
      <form
        onSubmit={onSubmit}
        className='flex flex-col box_shadow_universal w-[1170px] sm:w-[auto] mx-[200px] sm:mx-6 mt-[100px] sm:mt-6 mb-[200px] sm:mb-4'
      >
        <CompanyLogo company={true} />
        <NavBar active='1' />
        <div className=' sm:hidden'>
          <Steps stepNo='1' />
        </div>
        <div className='sm:hidden ml-[28px]'>
          <Header head='Sign Up' />
        </div>

        <div className='flex flex-row sm:flex-col sm:mt-8'>
          <div className=' p-[42px] sm:hidden'>
            <MainImg />
          </div>

          <div className='p-[48px] sm:p-0 sm:flex sm:flex-col sm:gap-2 sm:text-[11px] sm:leading-4'>
            <label>First Name</label>
            <input
              defaultValue={firstName}
              onChange={handleChange}
              name='FirstName'
              required
              className='input__style'
            />
            <label>Last Name</label>
            <input
              defaultValue={lastName}
              onChange={handleChange}
              name='LastName'
              required
              className='input__style'
            />
            <label>Date of Birth</label>
            <input
              onChange={handleChange}
              defaultValue={birthDate}
              type='date'
              min='1970-01-01'
              max='2002-01-01'
              name='dateOfBirth'
              required
              className='input__style'
            />
            <label>Last 4 of SSN</label>
            <input
              onChange={handleChange}
              defaultValue={ssnShort}
              type='text'
              maxLength='4'
              minLength='4'
              name='ssnShort'
              required
              className='input__style'
            />
            <label>Address</label>
            <input
              onChange={handleChange}
              maxLength={60}
              defaultValue={streetAdress}
              required
              name='address'
              className='input__style'
            />
          </div>

          <div className='p-[48px] sm:p-0 sm:flex sm:flex-col sm:gap-2 sm:text-[11px] sm:leading-4'>
            <label>City</label>
            <input
              onChange={handleChange}
              name='city'
              defaultValue={city}
              maxLength={100}
              required
              className='input__style'
            />
            <label>State</label>
            <input
              onChange={handleChange}
              maxLength={2}
              minLength={2}
              defaultValue={state_country}
              required
              name='state'
              className='input__style'
            />
            <label> Zip Code</label>
            <input
              onChange={handleChange}
              name='zipCode'
              minLength={5}
              maxLength={5}
              type='tel'
              pattern='^\d{5}$'
              defaultValue={zipCode}
              className='input__style'
              required
            />
            <label>Phone Number</label>
            <input
              onChange={handleChange}
              name='PhoneNumber'
              defaultValue={phoneNumber}
              required
              type='tel'
              minLength={10}
              maxLength={10}
              className='input__style'
            />
            <label>Email Address</label>
            <input
              onChange={handleChange}
              name='Email'
              autoComplete='email'
              defaultValue={email}
              type='email'
              required
              className='input__style'
            />
          </div>
        </div>
        <div className=' ml-[750px] mt-5 sm:mt-8 sm:ml-[110px]'>
          {showAlert && <Alert />}
        </div>

        <div className='w-[200px] sm:w-[80px] ml-[900px] sm:ml-[200px] mt-[10px] sm:mt-6 mb-[48px] sm:mb-4 justify-end'>
          <button
            type='submit'
            className=' w-[130px] sm:w-[80px] h-[48px] sm:h-[33px] bg-ss_primary rounded-md sm:rounded-sm font-semibold text-ss_white2 cursor-pointer z-10 sm:text-xs sm:leading-4'
          >
            Next Step
          </button>
        </div>
      </form>
    </div>
  );
};
export default ContactInformation;

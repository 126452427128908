import React from 'react';
import { Footer, NavBar } from '../../components';

const Shipping = () => {
  return (
    <div className=' flex flex-col justify-center items-center overflow-hidden'>
      <NavBar pagename='home' />

      <div className='flex flex-col sm:justify-start sm:items-start justify-center items-center my-[64px] sm:my-6'>
        <div>
          <p className=' text-[32px] leading-[48px] sm:text-[16px] sm:leading-[24pxpx] font-[aulire] pb-6 sm:pb-4'>
            Shipping Policy
          </p>
          <p className='text-[16px] sm:text-[11px] sm:leading-[16px] leading-[24px] w-[1070px] sm:w-[300px] pb-20 sm:pb-8'>
            As the world shifts from dropping by a store to packages dropped on
            your doorstep, clear and concise standards around shipping, in the
            form of a shipping policy, are becoming a necessity for web vendors
            of all forms. A shipping policy is simply a clear statement of how
            your company ships goods. It can include:
            <br></br>
            <br></br>
            <ul className=' list-disc ml-5'>
              <li>Costs</li>
              <li>Methods and how long each method will take</li>
              <li>Handling times</li>
              <li>
                Times orders must be placed for certain guarantees such as
                same-day shipping
              </li>
              <li>Restrictions on delivery, such as no P.O. boxes</li>
              <li>Any other useful information for the customer</li>
            </ul>
            <br></br>
            <br></br>
            In this article we’ll discuss why you need a policy, where it should
            go, what should be contained in the policy, and how it interacts
            with other documentation such as your terms of service.
          </p>
          <p className=' text-[32px] leading-[48px] sm:text-[16px] sm:leading-[24pxpx] font-[aulire] pb-6'>
            Why Have A Shipping Policy?
          </p>
          <p className='text-[16px] leading-[24px] sm:text-[11px] sm:leading-[16px] w-[1070px] sm:w-[300px] pb-20 sm:pb-6'>
            Shipping policies are not required by law, but they are useful to
            your customers and can help explain relevant laws and policies
            quickly and clearly. Fundamentally, a shipping policy is part of the
            overall implied contract between you and your customer that forms
            the basis of an exchange. It’s in the interest of both parties that
            this contract be as clear and detailed as possible.
            <br></br>
            <br></br>
            In some circumstances, a shipping policy is also useful to explain
            two different legal systems to your customers, in the case of
            importing goods, or to explain interstate shipping restrictions,
            such as with chemicals.
            <br></br>
            <br></br>
            In many cases it’s important to have these concerns fully documented
            in your policy, even if they only detail the relevant laws. Do not
            copy and paste these laws into your shipping policy; instead,
            include links to the necessary statute so customers can refer to
            their own counsel as needed.
            <br></br>
            <br></br>
            Shipping policies also may be a part of your other policies. For
            example, as part of your website’s terms of service, you can make
            clear that all customer orders and requests must abide by your
            shipping policy.
            <br></br>
            <br></br>
            This allows you to add important options to the contract between
            yourself and a customer, such as the ability to cancel orders if
            proper permits have not been obtained, to issue or deny refunds over
            shipping problems, and to explain what authorities you may be
            expected to provide inventories, bills of lading, and other
            information to and at which points in the shipping process.
          </p>
          <p className=' sm:text-[16px] sm:leading-[24pxpx] text-[32px] leading-[48px] font-[aulire] pb-6'>
            What Should Your Shipping Policy Include?
          </p>
          <p className='text-[16px] leading-[24px] w-[1055px] sm:w-[300px] pb-20 sm:pb-6 sm:text-[11px] sm:leading-[16px]'>
            Above all, your shipping policy should make clear what your
            responsibilities are for shipping, and what your customer will need
            to take care of. These will vary depending on what you ship and
            where you ship, so before drafting a policy, consider your most
            common shipping concerns.
          </p>
          <p className=' sm:text-[16px] sm:leading-[24pxpx] text-[32px] leading-[48px] font-[aulire] pb-6'>
            Methods of Shipping
          </p>
          <p className='text-[16px] leading-[24px] w-[1055px] sm:w-[300px] pb-20 sm:pb-6 sm:text-[11px] sm:leading-[16px]'>
            Detail which shipping methods and shipping companies you use, and
            their specific requirements. Make a point of linking to the specific
            shipping companies you use and providing their contact information,
            where necessary. If goods are tied up in transit, this will ease
            customer issues.
            <br></br>
            <br></br>
            If you have a firm shipping fee, be sure to disclose it in your
            shipping policy. Otherwise, include links to calculators for
            shipping, or explanations of how shipping is determined. This is
            also a good place to clarify any handling fees or related costs.
            <br></br>
            <br></br>
            However, this shouldn’t be the only place you disclose shipping
            costs. If you have an online cart, be sure that shipping and its
            options have their own separate page, with links to your policy and
            other relevant information, and clearly marked pricing.
          </p>
          <p className='sm:text-[16px] sm:leading-[24px] text-[32px] leading-[48px] font-[aulire] pb-6'>
            Where You Ship To
          </p>
          <p className='text-[16px] leading-[24px] w-[1055px] sm:w-[300px] pb-20 sm:pb-6 sm:text-[11px] sm:leading-[16px]'>
            Detail both locations you deliver to, with any appropriate legal
            information, and locations your specific shipping method can’t
            accept, such as PO boxes. This is particularly important when you’re
            shipping overseas, or engage with different mail systems, such as
            Army Post Offices (APOs) or Fleet Post Offices (FPOs).
            <br></br>
            <br></br>
            Never assume that your customer will automatically grasp that
            certain products can’t be shipped to certain states. For example,
            martial arts equipment dealers will need to disclose which states it
            can ship certain products to and which it can’t.
            <br></br>
            <br></br>
            International Shipping Concerns Specify who pays customs fees, and
            direct your customers to the proper authorities to pay fees and
            mediate disputes. This is also a good place to discuss what will be
            expected of the customer when the goods arrive at port or on the
            airstrip, such as fees, quarantines, tariffs, and other costs.
            <br></br>
            <br></br>
            This should also be disclosed before the customer buys, especially
            if you can’t arrange for them to pre-pay these fees. If you email an
            order slip, include explicit instructions on what they should
            expect, what rates are common, and what forms they may need to fill
            out.
          </p>
          <p className='sm:text-[16px] sm:leading-[24px] text-[32px] leading-[48px] font-[aulire] pb-6'>
            Where Should I Display or Link to My Shipping Policy?
          </p>
          <p className='text-[16px] leading-[24px] w-[1055px] sm:w-[300px] pb-20 sm:pb-6 sm:text-[11px] sm:leading-[16px]'>
            First, your overall shipping policy should simply be all the
            information in one place, but pieces of it should be placed where
            necessary on your website to clarify certain aspects of the purchase
            process.
            <br></br>
            <br></br>
            For example, if there are specific shipping restrictions on an item
            you sell, display those on that item’s page instead of linking to
            the entire shipping policy.
            <br></br>
            <br></br>
            Beyond this, your shipping policy should be located on every page
            within easy access of your customers. This can be at the bottom of
            your page in the footer or link section:
            <br></br>
            <br></br>
            You can also include a link to your policy as part of your website’s
            help section:
            <br></br>
            <br></br>
            It should also be included as a link in any materials that discuss
            shipping. These include email receipts, customer service tickets
            related to shipping and promotional emails. You can also include it
            in other contacts, such as an email signature included on external
            emails to all clients and customers.
          </p>
          <p className='sm:text-[16px] sm:leading-[24px] text-[32px] leading-[48px] font-[aulire] pb-6'>
            Should I Include An “I Agree To Terms” Checkbox?
          </p>
          <p className='text-[16px] leading-[24px] w-[1055px] sm:w-[300px] pb-20 sm:pb-6 sm:text-[11px] sm:leading-[16px]'>
            Generally, an “agree to terms” checkbox for your terms of service,
            which includes your shipping policy, should be enough. However, in
            situations where you will be dealing with complicated shipping
            issues, customs inspections, or other concerns, require customers to
            separately read and understand your shipping policy. It will limit
            confusion and set the groundwork to resolve disputes.
            <br></br>
            <br></br>
            Make sure that any policy you require agreement on covers any
            categories you’re concerned about in detail.
          </p>
          <p className='sm:text-[16px] sm:leading-[24px] text-[32px] leading-[48px] font-[aulire] pb-6'>
            Shipping Policies and Other Legal Agreements
          </p>
          <p className='text-[16px] leading-[24px] w-[1055px] sm:w-[300px] pb-20 sm:pb-6 sm:text-[11px] sm:leading-[16px]'>
            Make mention of your shipping policy in your other legal agreements
            such as your privacy policy and your terms of service.
            <br></br>
            <br></br>
            When you mention it, be sure to refer to it wherever relevant and
            include a link to the full shipping policy. For example, if your
            privacy policy needs to carve out an exception for international
            shipping, include a short paragraph explaining the issues and link
            to your shipping policy.
            <br></br>
            <br></br>
            When it comes to terms of service, clarify where shipping and
            service touch. For example, if a customer refuses their order, or
            has it shipped back requesting a refund, your terms of service
            should clarify who pays for the return shipping and whether a
            stocking fee applies.
            <br></br>
            <br></br>
            Remember to include proper translations of your shipping policy for
            the major languages of all your markets. This should be a carefully
            translated document that has been looked at by counsel to ensure the
            contract it represents is proper and abides by laws in both
            countries.
            <br></br>
            <br></br>
            Remember, the goal is clarity: Who is responsible for what, and what
            it will cost them. A good shipping policy gives the customers the
            tools they need to buy from you with confidence and ease.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Shipping;

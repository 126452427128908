import imageMain1 from '../assets/page1/g10.jpg';

const MainImg = () => {
  return (
    <div className=' w-[400px] h-[400px]'>
      <img src={imageMain1} alt='image1' />
    </div>
  );
};
export default MainImg;

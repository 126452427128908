import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  //   NavBar,
  //   Footer,
  SmartAgreement,
  CreditPageNav,
  CompanyLogo,
} from '../../../../../components';
import { useFeatureContext } from '../../../../../context/FeatureContext';

const Page3 = () => {
  const navigate = useNavigate();
  const { updatePageName, setIsLoading } = useFeatureContext();
  const ToPayment = () => {
    setIsLoading(true);
    updatePageName('page4');
    navigate('/smart-credit/payment');
    setIsLoading(false);
  };
  return (
    <div className=' grid place-items-center'>
      {/* <div>
        <NavBar pagename='credit-monitoring' />
      </div> */}
      <div className='box_shadow_universal grid place-items-start w-[1175px] sm:w-[auto] my-[100px] sm:my-4 shadow-ss_white'>
        {/* //! page 1 */}
        <div className='grid ml-[40px] sm:ml-0'>
          <div className=' sm:ml-4'>
            <CompanyLogo />
          </div>
          {/* //! process bar */}
          <CreditPageNav processState='package' />
          <div className='  sm:mx-4'>
            <h1 className='text-[23px] leading-[34px] text-ss_grey my-[18px] font-aulire mt-20'>
              Your Plan
            </h1>
            <p className=' text-ss_grey mb-[19px]'>
              You Have Selected The Following Plan
            </p>
            <p className=' text-ss_grey mb-[44px]'>
              Premium Membership - $29.95 per month
            </p>
          </div>
          <div className='  sm:mx-4'>
            <p className='mb-6'>
              <span className=' bg-ss_primary text-ss_white w-[24px] h-[24px] py-1 px-2 rounded-md mr-4'>
                ✔
              </span>
              ScoreTracker
            </p>
            <p className='mb-6'>
              <span className=' bg-ss_primary text-ss_white w-[24px] h-[24px] py-1 px-2 rounded-md mr-4'>
                ✔
              </span>
              ScoreBuilder®
            </p>
            <p className='mb-6'>
              <span className=' bg-ss_primary text-ss_white w-[24px] h-[24px] py-1 px-2 rounded-md mr-4'>
                ✔
              </span>
              ScoreBoost™
            </p>
            <p className='mb-6'>
              <span className=' bg-ss_primary text-ss_white w-[24px] h-[24px] py-1 px-2 rounded-md mr-4'>
                ✔
              </span>
              Money Manager
            </p>
            <p className='mb-6'>
              <span className=' bg-ss_primary text-ss_white w-[24px] h-[24px] py-1 px-2 rounded-md mr-4'>
                ✔
              </span>
              Smart Credit Report®
            </p>
            <p className='mb-6'>
              <span className=' bg-ss_primary text-ss_white w-[24px] h-[24px] py-1 px-2 rounded-md mr-4'>
                ✔
              </span>
              $1 Million Fraud Insurance
            </p>
            <p className='mb-6'>
              <span className=' bg-ss_primary text-ss_white w-[24px] h-[24px] py-1 px-2 rounded-md mr-4'>
                ✔
              </span>
              PrivacyMaster®
            </p>
          </div>
          <button
            onClick={ToPayment}
            className=' py-2 px-4 w-[113px] h-[51px] text-[23px] leading-[34px] bg-ss_primary rounded-[5px] text-ss_white mt-8 mb-4  sm:mb-10 sm:mx-4'
          >
            Submit
          </button>
          <SmartAgreement />
        </div>
        {/* 
      <div>
        <Footer />
      </div> */}
      </div>
    </div>
  );
};

export default Page3;

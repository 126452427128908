import React from 'react';
import { Navigate } from 'react-router-dom';
import { useFeatureContext } from '../context/FeatureContext';

const SmartAuth = ({ location, children }) => {
  const { pagename } = useFeatureContext();
  if (pagename !== location) {
    return <Navigate to='/smart-credit' />;
  }
  return children;
};

export default SmartAuth;

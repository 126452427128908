import React, { useState } from 'react';
import './homepage.css';
import { ImageOutput, NavBar, Footer } from '../../components';
import { Link } from 'react-router-dom';

//? logos
import logo1_rent from '../../assets/icons/rent.png';
import logo2_loan from '../../assets/icons/loan.png';
import logo3_cap from '../../assets/icons/cap.png';
import logo4_headhunting from '../../assets/icons/headhunting.png';
import logo5_buy from '../../assets/icons/buy.png';
import logo6_car from '../../assets/icons/car.png';
import logo_vec from '../../assets/icons/downVec.png';

//? big images

import image1 from '../../assets/hero_couple1.webp';
import image1_2 from '../../assets/hero_couple2.webp';
import image2 from '../../assets/enroll_image1.webp';
import image3 from '../../assets/enroll_image2.webp';
import image4 from '../../assets/our_process.webp';
import image5 from '../../assets/aboutUs_Laptop.webp';
import image6 from '../../assets/whyUs.webp';
import image7 from '../../assets/testimonial.webp';
import image7_1 from '../../assets/testimonial1.webp';
import image7_2 from '../../assets/testimonial1_2.webp';
import image8 from '../../assets/testimonial2.webp';
import image9 from '../../assets/results2.webp';
import image10 from '../../assets/results1.webp';
import image11 from '../../assets/results3.webp';
import image12 from '../../assets/faq.webp';

const HomePage = () => {
  const [faq_qus1, setfaq_qus1] = useState(false);
  const [faq_qus2, setfaq_qus2] = useState(false);
  const [faq_qus3, setfaq_qus3] = useState(false);
  const [faq_qus4, setfaq_qus4] = useState(false);

  const setfaqs1 = () => {
    setfaq_qus1(!faq_qus1);
    setfaq_qus2(false);
    setfaq_qus3(false);
    setfaq_qus4(false);
  };

  const setfaqs2 = () => {
    setfaq_qus2(!faq_qus2);
    setfaq_qus1(false);
    setfaq_qus3(false);
    setfaq_qus4(false);
  };
  const setfaqs3 = () => {
    setfaq_qus3(!faq_qus3);
    setfaq_qus2(false);
    setfaq_qus4(false);
    setfaq_qus1(false);
  };
  const setfaqs4 = () => {
    setfaq_qus4(!faq_qus4);
    setfaq_qus2(false);
    setfaq_qus3(false);
    setfaq_qus1(false);
  };

  return (
    <div className='grid place-items-center sm:place-items-start overflow-hidden '>
      <NavBar pagename='homepage' />

      {/* //! Hero image and blured Banner*/}

      <div className=' grid mt-20 sm:mt-[24px]'>
        <div className='grid grid-flow-col rounded-lg ml-40 sm:mr-0 sm:ml-0 sm:overflow-hidden sm:mt-[16px]'>
          <div className='mr-[15px] sm:mr-1 image101 sm:hidden'>
            {/* //?hero image fetch priority high */}
            <div className=' bg-ss_black rounded-[5px]  '>
              <img
                fetchpriority='high'
                width={`896px`}
                height={`598px`}
                style={{
                  width: `896px`,
                  height: `598px`,
                  borderRadius: '5px',
                }}
                className='opacity-50 object-cover'
                src={image1}
                alt='_image_'
              />
            </div>

            {/* <ImageOutput image_tag={image1} width='896' height='598' /> */}
          </div>
          <div className=' lg:hidden xl:hidden 2xl:hidden sm:mr-1 sm:ml-1 sm:w-[350px] image101__mobile '>
            <ImageOutput image_tag={image1} width='400' height='179' />
          </div>
          <div className='w-10 sm:w-[10px] sm:h-[179px] image100 sm:hidden'>
            {/* //?hero image fetch priority high */}
            <div className=' bg-ss_black rounded-[5px]  '>
              <img
                fetchpriority='high'
                width={`896px`}
                height={`598px`}
                style={{
                  width: `896px`,
                  height: `598px`,
                  borderRadius: '5px',
                }}
                className='opacity-50 object-cover'
                src={image1_2}
                alt='_image_'
              />
            </div>
            {/* <ImageOutput image_tag={image1_2} width='896' height='598' /> */}
          </div>
          <div className=' lg:hidden xl:hidden 2xl:hidden sm:mr-1 sm:w-[350px] sm:ml-1 image101__mobile '>
            <ImageOutput image_tag={image1_2} width='400' height='179' />
          </div>

          <div className='w-[675px] absolute mt-[103px] ml-[-170px] rounded-lg  backdrop__filter sm:w-[243px] sm:h-[226px] sm:mt-0 sm:ml-4 sm:p-4'>
            <p className='mt-16 mb-8 mx-8 font-aulire text-3xl text-left sm:m-0 sm:text-base sm:w-[219px]'>
              We Are The Credit Specialist For All Your Credit Score Problems
            </p>
            <p className='mx-8 sm:w-[185px] sm:mx-0 sm:text-xs'>
              Our Credit Specialist Help You To Remove Negative Scores From Your
              Credit Score. So That You Can Buy Your Dream Assests.
            </p>
            <Link to='/pricing'>
              <button className='hero_blur_div_button py-4 px-8 w-[254px] sm:w-[126px] h-[56px] text-start bg-ss_black mx-8 mt-8 mb-[70px] sm:mx-0 sm:my-2 sm:h-[33px] sm:px-4 sm:text-[8px] sm:py-2 sm:text-xs'>
                Get Started Today
              </button>
            </Link>
          </div>
        </div>
      </div>

      {/* //! Hero End Full  */}

      <div className='grid grid-flow-row place-items-center w-screen bg-ss_white mt-20 sm:mt-8 sm:ml-[-6px]'>
        <p className=' font-aulire my-8 text-2xl sm:text-[11px]'>
          Are your Credit Score Affecting Your
        </p>

        {/* //! logos repair my credit   */}

        <div className='grid grid-flow-col sm:w-screen sm:grid-cols-3 sm:grid-rows-2 sm:gap-1 place-content-center gap-[110px]'>
          <div className='grid grid-flow-row place-items-center text-center sm:mr-0'>
            <img
              className=' h-12 w-12 mb-4 sm:mb-0'
              src={logo1_rent}
              alt='logo'
            />
            <p className='sm:mt-[4px]'>Renting</p>
          </div>
          <div className='grid grid-flow-row place-items-center sm:mr-0'>
            <img
              className=' h-12 w-12 mb-4 sm:mb-0'
              src={logo2_loan}
              alt='logo'
            />
            <p className='sm:mt-[4px]'>Mortage</p>
          </div>
          <div className='grid grid-flow-row place-items-center  sm:mr-0'>
            <img
              className=' h-12 w-12 mb-4 sm:mb-0'
              src={logo3_cap}
              alt='logo'
            />
            <p className='sm:mt-[4px]'>Education</p>
          </div>
          <div className='grid grid-flow-row place-items-center  sm:mr-0'>
            <img
              className=' h-12 w-12 mb-4 sm:mb-0'
              src={logo4_headhunting}
              alt='logo'
            />
            <p className=' sm:mt-[4px]'>Employment</p>
          </div>
          <div className='grid grid-flow-row place-items-center  sm:mr-0'>
            <img
              className=' h-12 w-12 mb-4 sm:mb-0'
              src={logo5_buy}
              alt='logo'
            />
            <p className='sm:mt-[4px]'>Buying A House</p>
          </div>
          <div className='grid grid-flow-row place-items-center'>
            <img
              className=' h-12 w-12 mb-4 sm:mb-0'
              src={logo6_car}
              alt='logo'
            />
            <p className='sm:mt-[4px]'>Buying A Car</p>
          </div>
        </div>
        <Link to='/pricing'>
          <button className=' mt-12 mb-8 py-2 px-4 border-2 rounded-sm border-ss_black sm:my-4'>
            Repair My Credit Today !
          </button>
        </Link>
      </div>

      {/* //! enroll with us section  */}

      <div className='grid grid-flow-col grid-cols-2 my-20 font-aulire gap-24 xl:gap-[142px] place-items-center  sm:place-items-start sm:grid-flow-row sm:grid-cols-1 sm:gap-2 sm:my-0 overflow-hidden ml-4 sm:ml-0 lg:ml-[60px] '>
        <div className='grid grid-flow-row place-items-center sm:place-items-start ml-44 lg:ml-0 sm:ml-0 '>
          <p className=' text-[32px] sm:text-[16px] sm:leading-[24px] mb-16 ml-[-280px] sm:ml-4 sm:mt-9 sm:mb-6'>
            enroll with us
          </p>
          <p className=' text-ss_grey mb-12 w-[504px] sm:w-9/12 mx-4 sm:mb-6 sm:text-justify'>
            Many people face this BAD CREDIT issue. It's not a matter of shame
            to have a BAD CREDIT. But if you keep living with it, don't try to
            fix your credit Your Life will become miserable one day. Give us the
            opportunity to help you. To make your life Stress free.
          </p>
          <div className='mb-14  mx-4 sm:hidden'>
            <ImageOutput image_tag={image3} width='496' height='376' />
          </div>

          <div className='mb-14 mx-4 sm:mb-6 lg:hidden xl:hidden 2xl:hidden'>
            <ImageOutput image_tag={image3} width='300' height='140' />
          </div>

          <p className=' text-ss_grey w-[504px]  sm:w-9/12 mx-4 sm:mb-6 sm:text-justify '>
            Still, Having Doubts? Why not give us a call? let's arrange a
            meeting where we can personally Analyze your Report then
            “brainstorm” with you about the best way for your Credit
            Repair.There's no obligation, of course, and certainly no pressure,
            but, together, wejust may figure out the perfect the situation for
            you.
            <br></br>
            <br></br>
            So, What are you waiting for? Enroll with Us today.
          </p>
        </div>

        <div className='grid grid-flow-row gap-12 place-items-start mt-[-162px] mr-40 sm:mt-0 sm:mr-0 sm:gap-2'>
          <div className='mx-4 sm:mb-6 sm:hidden'>
            <ImageOutput image_tag={image2} width='496' height='376' />
          </div>
          <div className=' lg:hidden xl:hidden 2xl:hidden mx-4  sm:mb-6'>
            <ImageOutput image_tag={image2} width='300' height='140' />
          </div>
          <p className=' text-ss_grey w-[500px] sm:w-9/12 mx-4 sm:mb-6 sm:text-justify'>
            If you want a company that has a highly decorated office and a large
            number of employees who are well suited with seriousness in their
            faces, who has a high price services with a bunch of extra features
            for Credit Repair, our company is not the a place to come. But if
            you mostly want to repair your credit from an experienced,
            knowledgeable Credit Specialist with the Features that are needed
            and don't want to compromise with quality, you will like our Company
            just fine.
            <br></br>
            <br></br>
            Our Company is like having an Italian food from real Italian Mama.
          </p>
        </div>
      </div>

      {/* //!our process section   */}
      <div className=' grid place-items-start'>
        <p className=' text-[32px] ml-[60px] sm:ml-4 mb-16 sm:mb-6 font-aulire sm:text-base'>
          our process
        </p>
        <div className='grid place-items-center sm:place-items-start ml-[60px] sm:ml-0'>
          <div className='grid grid-flow-col sm:grid-flow-row place-items-center sm:place-items-start sm:ml-4'>
            <div className=' w-[768px] h-[496px] sm:w-[300px] sm:h-[140px] sm:overflow-hidden'>
              <ImageOutput image_tag={image4} width='768px' height='496px' />
            </div>
            <div className=' backdrop__filter grid  grid-cols-2 sm:grid-cols-1 place-items-center sm:place-items-start p-6 sm:p-1 w-[904px] sm:w-[100%] ml-[-500px] sm:ml-0 gap-[10px] sm:gap-1 sm:mt-4'>
              <div>
                <p
                  className=' font-aulire'
                  style={{
                    fontSize: '16px',
                    lineHeight: '34px',
                    marginBottom: '16px',
                  }}
                >
                  1. Client Onboarding
                </p>
                <p className=' w-[402px] sm:w-[80%] sm:text-justify sm:text-ss_grey'>
                  It will be the first step in your journey to achieve your
                  financial goals. We will walk you through how to use our 24/7
                  clients portal and gather all the important documents that we
                  will be needing.
                </p>
              </div>
              <div>
                <p
                  className=' font-aulire'
                  style={{
                    fontSize: '16px',
                    lineHeight: '34px',
                    marginBottom: '16px',
                  }}
                >
                  2. Credit Analysis
                </p>
                <p className='w-[404px] sm:w-[80%] sm:text-justify sm:text-ss_grey'>
                  We'll go over the specific details of your credit history and
                  determine which questionable negative items are wrongfully
                  hurting your scores. We will also analyze your positive credit
                  and explain how to optimize your report.
                </p>
              </div>
              <div>
                <p
                  className=' font-aulire'
                  style={{
                    fontSize: '16px',
                    lineHeight: '34px',
                    marginBottom: '16px',
                    marginTop: '32px',
                  }}
                >
                  3. Challenge and Dispute
                </p>
                <p className='w-[394px] sm:w-[80%] sm:text-justify sm:text-ss_grey'>
                  Using our expertise, We will work on your behalf and challenge
                  the bureaus and your creditors to verify these negative items
                  as accurate and fair. If they can't, they are required to
                  remove them.
                </p>
              </div>
              <div>
                <p
                  className=' font-aulire'
                  style={{
                    fontSize: '16px',
                    lineHeight: '34px',
                    marginBottom: '16px',
                    marginTop: '32px',
                  }}
                >
                  4. Manage and Monitor
                </p>
                <p className='w-[400px] sm:w-[80%] sm:text-justify sm:text-ss_grey'>
                  We will monitor your credit scores and be transparent with the
                  results, As you manage your credit wisely. We will also
                  continue to leverage the law for your credit addressing new
                  credit issues as they appear. Together, we can make you
                  achieve your financial goals in no time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //!about us section */}
      <div className='sm:w-screen grid mt-[80px] sm:mt-6 place-items-center sm:place-items-center sm:mx-4'>
        <p className=' font-aulire width-[493px] sm:w-10/12 text-[32px] sm:text-base mb-16 sm:mb-4 ml-[-970px] sm:ml-[-60px] '>
          about us
        </p>
        <div className='grid sm:grid-flow-row grid-cols-2 sm:grid-cols-1 gap-[170px] sm:gap-0 place-items-center sm:place-items-start text-ss_grey font-aulire'>
          <div className='grid place-items-center sm:place-items-start mr-[-25px] sm:mr-0'>
            <div className='sm:w-[288px] sm:h-[140px] sm:mb-3 lg:hidden xl:hidden 2xl:hidden'>
              <ImageOutput image_tag={image5} width='288' height='140' />
            </div>
            <p className=' w-[493px] sm:w-10/12 text-base sm:text-[11px] sm:text-ss_grey text-[11px] mb-12 sm:mb-3  sm:text-justify'>
              “We offer our services at a competitive rate. The extent of our
              services includes “Free Credit Monitoring and Analysis” and
              assistance with financial consulting and credit score
              improvement.”
            </p>
            <div className=' sm:hidden'>
              <ImageOutput image_tag={image5} width='496' height='376' />
            </div>
          </div>
          <div className='grid place-items-center sm:place-items-start ml-[-30px] sm:ml-0 mt-[-140px] sm:mt-0'>
            <div className='mb-12 sm:hidden'>
              <ImageOutput image_tag={image2} width='496' height='376' />
            </div>
            <div className=' lg:hidden xl:hidden 2xl:hidden  sm:mb-3'>
              <ImageOutput image_tag={image2} width='288' height='140' />
            </div>
            <p className=' w-[493px] sm:w-10/12 text-base text-[11px] mb-12 sm:mb-3 sm:text-[11px] sm:text-ss_grey sm:text-justify'>
              “SS Ultimate Solutions is one of the top credit restoration
              companies in Fort Lauderdale, Florida whose goal is to help
              customers enhance their buying capacity that is affecting their
              daily lives.”
            </p>
          </div>
        </div>
      </div>
      {/* //! why us section */}
      <div className='grid mt-[80px] sm:mt-6 place-items-center sm:place-items-start sm:mx-4 '>
        <div>
          <p className=' text-[32px] sm:text-base mb-16 sm:mb-3 font-aulire ml-[-560px] sm:ml-0'>
            why us
          </p>
        </div>
        <div className='grid grid-flow-col sm:grid-flow-row place-items-center sm:place-items-start'>
          <div className='sm:overflow-hidden sm:my-2 lg:hidden xl:hidden 2xl:hidden'>
            <ImageOutput image_tag={image6} width='288' height='140' />
          </div>
          <div className='backdrop__filter__2 grid grid-flow-col sm:grid-flow-row grid-cols-2 sm:grid-cols-1 p-6 sm:p-1 w-[904px] sm:w-screen gap-6 sm:gap-2 mr-[-490px] sm:mr-0'>
            <div style={{ fontSize: '14px' }}>
              <p className=' font-aulire mb-4 sm:mb-2'>
                1. Effective Credit Repair Program
              </p>
              <p className=' pb-6 sm:pb-2 sm:w-10/12 sm:text-justify'>
                We have the most effective credit repair program that other
                credit repair companies don't.
              </p>
              <p className=' mb-4 sm:mb-2 mt-8  sm:mt-0 font-aulire '>
                2. Transparency
              </p>
              <p className='sm:w-10/12 sm:text-justify'>
                You will monitor your credit scores and be transparent with the
                results. It is very important for us that you are knowledgeable
                about what we are doing on your behalf.
              </p>
            </div>
            <div>
              <p className='mb-4 sm:mb-2 font-aulire'>
                3. Credit Monitoring Account
              </p>
              <p className='sm:w-10/12 sm:text-justify'>
                Credit monitoring is not only vital for us to pull the credit
                reports but even after our service is complete this software is
                needed to ensure your credit stays clean.
              </p>
              <p className=' mt-8 sm:mt-2 mb-4 sm:mb-2 font-aulire'>
                4. Free Consultation
              </p>
              <p className='sm:w-10/12  sm:text-justify'>
                We will run a free credit analysis and go over the specific
                details of your credit history and determine which questionable
                negative items are wrongfully hurting your scores.
              </p>
            </div>
          </div>
          <div className='sm:w-10/12 sm:h-[140px] sm:overflow-hidden sm:mt-2 sm:hidden'>
            <ImageOutput image_tag={image6} width='768' height='496' />
          </div>
        </div>
      </div>

      {/* //!testimonials */}
      <div className='w-screen h-[300px] grid gap-44  bg-ss_white mt-20 sm:mt-6 place-items-center sm:place-items-start overflow-hidden sm:ml-[-4px]'>
        <div className='sm:w-[320px] sm:h-[300px] grid grid-cols-2 sm:grid-cols-1 place-items-center gap-8 sm:gap-4 testimonials__anim'>
          <div className='grid place-items-center w-[500px] sm:w-screen  ml-4'>
            <p className=' font-aulire text-[32px] sm:text-base  my-4 sm:my-1 ml-[-370px] sm:ml-0'>
              testimonials
            </p>
            <p className='font-aulire text-base sm:text-[11px] text-ss_grey ml-[-246px] sm:ml-0'>
              "It's really wonderful. The best on the net!"
            </p>
            <span className=' ml-[180px] font-aulire text-base sm:text-[11px] text-ss_grey'>
              - Veronica W. Carrol
            </span>
          </div>
          <div className='grid grid-flow-col sm:place-items-center'>
            <img
              className=' mt-[-100px] sm:mt-[-60px] sm:ml-[40px]'
              src={image7}
              alt='ss'
              width='409px'
              height='409px'
            />
            <img
              className=' ml-[-60px] sm:hidden'
              src={image8}
              alt='ss'
              width='191px'
              height='140px'
            />
          </div>
        </div>
        <div className='sm:w-[320px] sm:h-[300px] grid grid-cols-2 sm:grid-cols-1 place-items-center gap-8 sm:gap-4 ml-2 sm:ml-0 testimonials__anim1 sm:mt-[-20px] '>
          <div className='grid place-items-center w-[500px] sm:w-screen  ml-4 mt-[-90px]'>
            <p className=' font-aulire text-[32px] sm:text-base  my-4 sm:my-1 ml-[-405px] sm:ml-0'>
              testimonials
            </p>
            <p className='font-aulire text-base sm:text-[11px] text-ss_grey ml-[-322px] sm:ml-0'>
              "Definitely worth the investment. <br /> It fits our needs
              perfectly. I can't say <br /> enough about Credit Repair."
            </p>
            <span className=' ml-[180px] font-aulire text-base sm:text-[11px] text-ss_grey'>
              - Neilla Y.
            </span>
          </div>
          <div className='grid grid-flow-col sm:place-items-center'>
            <img
              className=' mt-[-100px] sm:mt-[-60px] sm:ml-[40px] sm:w-[200px] sm:h-[200px]'
              src={image7_1}
              alt='ss'
              width='333px'
              height='331px'
            />
            <img
              className=' ml-[40px] mt-[-60px] sm:hidden'
              src={image8}
              alt='ss'
              width='140px'
              height='140px'
            />
          </div>
        </div>
        <div className='sm:w-[320px] sm:h-[300px] grid grid-cols-2 sm:grid-cols-1 place-items-center gap-8 sm:gap-4 testimonials__anim2 sm:ml-0'>
          <div className='grid place-items-center w-[500px] sm:w-screen   mt-[-70px] sm:mt-[-210px] sm:ml-0'>
            <p className=' font-aulire text-[32px] sm:text-base sm:mt-[-80px]  my-4 sm:my-1 ml-[-260px] sm:ml-0'>
              testimonials
            </p>
            <p className='font-aulire text-base sm:text-[11px] sm:text-justify sm:mt-[-30px] text-ss_grey ml-[-141px] sm:ml-0'>
              "I wish I would have thought of it first. <br /> I couldn't have
              asked for more than this <br />. I have gotten at least 50 times
              the value <br /> from SS Ultimate Solution Company. <br /> Credit
              Repair has got everything I need."
            </p>
            <span className=' ml-[180px] font-aulire text-base sm:text-[11px] text-ss_grey'>
              - Yevette T.
            </span>
          </div>
          <div className='grid grid-flow-col sm:place-items-center'>
            <img
              className=' mt-[-100px] sm:mt-[-180px] sm:ml-[40px] sm:h-[200px] sm:w-[300px]'
              src={image7_2}
              alt='ss'
              width='502px'
              height='335px'
            />
            <img
              className=' ml-[-60px] mt-[-60px] sm:hidden'
              src={image8}
              alt='ss'
              width='191px'
              height='140px'
            />
          </div>
        </div>
      </div>

      {/* //! results */}

      <div className='sm:w-screen grid grid-flow-col sm:grid-flow-row grid-cols-2 sm:grid-cols-1 place-items-start mt-20 sm:mt-8 font-aulire gap-[170px] sm:gap-3 ml-[53px] sm:ml-[0] '>
        <div className=' grid place-items-start sm:place-items-center sm:w-10/12  '>
          <p className=' text-[32px] sm:text-base mb-16 sm:mb-6 sm:ml-[-235px]'>
            results
          </p>
          <div className='lg:hidden xl:hidden 2xl:hidden w-10/12 sm:mb-6'>
            <img src={image9} alt='result' width='300px' height='139px' />
          </div>
          <p className='text-ss_grey mb-16 sm:mb-6 max-w-lg sm:w-10/12 sm:text-justify'>
            Here are some results of happy clients who Removed their Negative
            Items with our help. We have helped them and helping others to
            Remove their Negative Items. We will help you too.
          </p>
          <div className='sm:hidden'>
            <img src={image9} alt='result' width='506px' height='244px' />
          </div>
        </div>
        <div className='grid place-items-start sm:place-items-center sm:w-10/12'>
          <img
            className='mb-12 sm:mb-6 sm:hidden'
            src={image10}
            alt='result'
            width='506px'
            height='244px'
          />
          <div className='lg:hidden xl:hidden 2xl:hidden w-10/12'>
            <img src={image9} alt='result' width='300px' height='139px' />
          </div>
          <p className=' ml-4 mb-12 sm:mb-4 sm:mt-4'>
            All you just need to do is Enroll with Us.
          </p>
          <Link to='/pricing'>
            <button className=' ml-4 py-2 px-4 text-xs bg-ss_primary rounded-sm text-ss_white mb-12 sm:mb-6'>
              Repair My Credit Today !
            </button>
          </Link>
          <div className=' sm:w-10/12'>
            <img src={image11} alt='result' width='300px' height='244px' />
          </div>
        </div>
      </div>

      {/* //! FaQ*/}

      <div className='grid mt-[80px] sm:mt-6 place-items-start mb-60 sm:mb-20 ml-[170px] sm:w-screen sm:mx-4'>
        <p className=' font-aulire text-[32px] sm:text-base mb-16 sm:mb-4'>
          faq
        </p>
        <div className='grid grid-flow-col sm:grid-flow-row'>
          <div className='grid place-items-center sm:place-items-start'>
            <div className='sm:hidden'>
              <ImageOutput image_tag={image12} width='768' height='496' />
            </div>

            <div className=' lg:hidden xl:hidden 2xl:hidden sm:mb-6'>
              <ImageOutput image_tag={image12} width='300' height='140' />
            </div>
          </div>
          <div className='max-h-[956px] w-[900px] sm:w-10/12 grid grid-flow-col sm:grid-flow-row backdrop__filter__2 ml-[-400px] sm:ml-0 '>
            <div className='grid gap-4 sm:gap-2 p-16 sm:p-2 '>
              <div className='grid gap-6 sm:gap-2 '>
                <div className=' grid grid-flow-col'>
                  <span className=' font-aulire text-[24px] sm:text-base mr-[300px] sm:mr-[188px]'>
                    How will I start?
                  </span>
                  <div
                    className='grid w-6 h-6 place-items-center z-10 cursor-pointer'
                    onClick={setfaqs1}
                  >
                    <img src={logo_vec} alt='s' width='12px' height='12px' />
                  </div>
                </div>
                <p className={`max-w-[457px]   ${faq_qus1 || 'hideDiv'}`}>
                  To start the process of credit repair, you need to know the
                  status of your credit. If you don't, you can get your free
                  credit report from annualcreditreport.com (it's one of the
                  three websites that the Federal Trade Commission requires to
                  provide free credit reports). If you have bad credit, then you
                  have choices. You can pay someone to help you repair your
                  credit, or you can do it yourself with some guidance.
                </p>
              </div>

              <div className='grid gap-6 sm:gap-2'>
                <div className=' grid grid-flow-col'>
                  <span className=' font-aulire text-[24px] sm:text-base mr-[45px]'>
                    How much it cost to repair my credit?
                  </span>
                  <div
                    className='grid w-6 h-6 place-items-center z-10 cursor-pointer
                    '
                    onClick={setfaqs2}
                  >
                    <img src={logo_vec} alt='s' width='12px' height='12px' />
                  </div>
                </div>
                <p className={`max-w-[457px] ${faq_qus2 || 'hideDiv'}`}>
                  Most people have heard of credit repair. If you have low
                  credit scores, you may be wondering how much it costs to
                  repair your credit. Repairing your credit is possible for free
                  if you are willing to put in the time and effort. If you hire
                  a professional to do it for you, the cost is much higher, but
                  could be worth it depending on the other options available.The
                  Cost of Repairing Your CreditCredit repair services typically
                  charge an upfront fee that ranges anywhere from $99 to $5,000.
                  This fee often must be paid before they begin work on your
                  credit reports. In addition to the initial fee, many companies
                  charge a monthly fee to continue getting their help. Some
                  services also require clients to sign a contract that locks
                  them into paying the company fees for a set amount of time,
                  regardless of whether they are satisfied with their services.
                </p>
              </div>

              <div className='grid gap-6 sm:gap-2 '>
                <div className=' grid grid-flow-col'>
                  <span className=' font-aulire text-[24px] mr-[160px] sm:text-base'>
                    Is it legal to repair my credit?
                  </span>
                  <div
                    className='grid w-6 h-6 place-items-center z-10 cursor-pointer'
                    onClick={setfaqs3}
                  >
                    <img src={logo_vec} alt='s' width='12px' height='12px' />
                  </div>
                </div>
                <p className={`max-w-[457px] ${faq_qus3 || 'hideDiv'}`}>
                  Federal law governs credit reporting, and credit repair is
                  legal under federal law. There is nothing illegal about a
                  company that charges a fee to repair or dispute the accuracy
                  of anyone's credit report. (This is often referred to as
                  "credit repair.")With the recent storm of negative media
                  reports about credit repair agencies, we thought it was time
                  to take a closer look at what is really going on under the
                  surface.You've likely seen these reports with titles such as
                  "Biggest Credit Repair Company Indicted!" and "Federal Court
                  Orders Credit Repair Companies To Pay $350 Million."The
                  reality is, there are many people who have had their lives
                  negatively impacted by illegal credit repair companies that
                  have done everything from opening fraudulent accounts in their
                  names to stealing their identities. So the federal government
                  has taken action.But don't misunderstand: Illegal credit
                  repair companies exist in every industry, including ours.
                  That's why GGT has put together this information on what's l
                  egal and what's not, and how you can protect yourself.
                </p>
              </div>

              <div className='grid gap-6 sm:gap-2 '>
                <div className=' grid grid-flow-col'>
                  <span className=' font-aulire text-[24px] sm:text-base mr-[130px]'>
                    What does credit company do?
                  </span>
                  <div
                    className='grid w-6 h-6 place-items-center z-10 cursor-pointer'
                    onClick={setfaqs4}
                  >
                    <img src={logo_vec} alt='s' width='12px' height='12px' />
                  </div>
                </div>
                <p className={`max-w-[457px] ${faq_qus4 || 'hideDiv'}`}>
                  Credit Repair companies do the following things: 1. Credit
                  repair companies help you dispute and remove information from
                  your credit report. 2. Credit repair companies help you obtain
                  a credit score. 3. Credit repair companies negotiate
                  settlements with your creditors. 4. Credit repair companies
                  negotiate a lower interest rate with new creditors. 5. Credit
                  repair companies assist in getting new credit cards and loans
                  for you. 6. Credit repair companies educate clients about the
                  importance of their credit reports, credit scores, and credit
                  in general.The key is to find out what bad information is on
                  your credit report, who has it and how much it's affecting
                  your credit score, and then take the steps necessary to remove
                  this information from your report or begin working to improve
                  your credit score by paying bills on time and continuing to
                  pay down outstanding balances until they are reduced to zero
                  and debts are paid off completely.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //! Footer */}
      <div className=' sm:w-screen'>
        <Footer />
      </div>
    </div>
  );
};

export default HomePage;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useFeatureContext } from '../../context/FeatureContext';
import { useAdminContext } from '../../context/AdminContext/admincontext';

const ViewEmails = (props) => {
  const { token } = useAdminContext();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const apiUrl = process.env.REACT_APP_API_ENVIRONMENT_URL;
  const { isSuperAdmin } = useFeatureContext();
  const [dataBaseData, setdataBaseData] = useState(null);
  const getdataBaseData = async () => {
    axios
      .get(`${apiUrl}/getEmails`, config)
      .then((res) => {
        setdataBaseData(res.data.data);
      })
      .catch(() => {
        // console.log(err.message);
      });
  };
  const [refresh, setrefresh] = useState(false);

  const deleteEmail = (id) => {
    axios
      .get(`${apiUrl}/deleteEmail/${id}`, config)
      .then(() => {
        setrefresh(!refresh);
      })
      .catch(() => {
        // console.log(err.message);
      });
  };
  const getId = (e) => {
    e.preventDefault();
    const val = e.target.value;
    deleteEmail(val);
  };

  useEffect(() => {
    if (isSuperAdmin && props.trigger) {
      getdataBaseData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, props.trigger]);

  return props.trigger ? (
    <div className='fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full  justify-center items-center flex sm:flex-col'>
      <div className='relative  w-[240px] mt-[200px] sm:mt-0 ml-[-235px] sm:ml-3  max-w-md p-4 md:h-auto'>
        <div className='relative bg-white rounded-lg shadow dark:bg-red-700'>
          <button
            onClick={() => props.setTrigger(false)}
            type='button'
            className='absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white'
          >
            <svg
              className='w-5 h-5'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                clipRule='evenodd'
              ></path>
            </svg>
          </button>
          <div className='px-6 py-6 lg:px-8'>
            <h3 className=' text-xl font-medium text-gray-900 dark:text-white'>
              Email List
            </h3>
          </div>
        </div>
      </div>
      <div className=' relative mt-[183px] sm:mt-8'>
        <table className=' text-sm sm:text-xs text-left text-gray-500 dark:text-gray-400'>
          <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
            <tr className=' sm:text-xs'>
              <th scope='col' className='px-6 py-3 sm:px-1  sm:py-1'>
                email_id
              </th>
              <th scope='col' className='px-6 py-3 sm:px-1  sm:py-1'>
                Name
              </th>
              <th scope='col' className='px-6 py-3sm:px-1  sm:py-1'>
                Email
              </th>
              <th scope='col' className='px-6 py-3sm:px-1  sm:py-1'>
                Date
              </th>
              <th scope='col' className='px-6 py-3sm:px-1  sm:py-1'>
                Delete
              </th>
            </tr>
          </thead>
          {dataBaseData?.map((value, index) => {
            return (
              <tbody key={index}>
                <tr className='bg-white border-b dark:bg-red-700 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600'>
                  <th
                    scope='row'
                    className='px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap'
                  >
                    {value.id}
                  </th>
                  <td className='px-6 py-4'>{value.name}</td>
                  <td className='px-6 py-4'>{value.email}</td>
                  <td className='px-6 py-4'>{value.created_at}</td>
                  <td className='px-6 py-4 text-center'>
                    <button
                      className='font-medium text-black dark:text-black hover:text-red-500 hover:underline'
                      onClick={getId}
                      value={value.id}
                    >
                      delete
                    </button>
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
    </div>
  ) : (
    ''
  );
};

export default ViewEmails;

import { useState } from 'react';
import logo1 from '../../src/assets/hero_logo.png';
import logo2 from '../../src/assets/icons/hamMenu.png';
import logo3 from '../../src/assets/icons/crossMenu.png';
import { Link } from 'react-router-dom';

const NavBar = ({ pagename }) => {
  const [menu, setmenu] = useState(false);

  const setAltMenu = () => {
    setmenu(!menu);
  };

  return (
    <nav className='grid grid-flow-col justify-between mt-6 sm:w-screen'>
      <Link to='/'>
        <div className='grid mr-16' href='/'>
          <img className=' w-24 h-16' src={logo1} alt='logo' />
        </div>
      </Link>
      <div
        onClick={setAltMenu}
        className='grid place-items-center sm:mr-8 lg:hidden md:hidden xl:hidden 2xl:hidden z-10'>
        <img src={menu ? logo3 : logo2} width='18px' height='12px' alt='img' />
      </div>
      <div
        className={`${
          menu || 'hidden'
        } sm:fixed sm:top-[100px] sm:left-2/4 flex flex-col justify-start gap-2 xl:hidden lg:hidden  2xl:hidden w-[181px] h-[290px] bg-ss_primary pl-4 text-[11px] pt-6 text-ss_white2 z-20 rounded-md elementToFadeInAndOut`}>
        <Link to='/'>Home</Link>
        <Link to='/pricing'>Pricing</Link>
        <Link to='/credit-monitoring'>Credit Monitoring</Link>
        <Link to='/resources'>Resources</Link>
        <Link to='/contact-us'>Contact Us</Link>

        <a className=' mt-1 py-1' type='number ' href='tel:8773521590'>
          (877) 352-1590
        </a>
        <Link to='/pricing'>
          <button className='w-[152px] h-[25px] py-1 px-2 bg-ss_black rounded-sm text-[11px] leading-4 text-ss_white'>
            Repair My Credit Today !
          </button>
        </Link>
      </div>

      <div className='grid grid-flow-col gap-8 mr-28 place-content-center sm:hidden '>
        <Link
          to='/'
          className={`${
            pagename === 'homepage'
              ? 'text-ss_primary'
              : 'text-ss_grey hover:text-ss_primary'
          }`}>
          Home
        </Link>
        <Link
          to='/pricing'
          className={`${
            pagename === 'pricing'
              ? 'text-ss_primary'
              : 'text-ss_grey hover:text-ss_primary'
          }`}>
          Pricing
        </Link>
        <Link
          to='/credit-monitoring'
          className={`${
            pagename === 'credit-monitoring'
              ? 'text-ss_primary'
              : 'text-ss_grey hover:text-ss_primary'
          }`}>
          Credit Monitoring
        </Link>
        <Link
          to='/resources'
          className={`${
            pagename === 'resources'
              ? 'text-ss_primary'
              : 'text-ss_grey hover:text-ss_primary'
          }`}>
          Resources
        </Link>
        <Link
          to='/contact-us'
          className={`${
            pagename === 'contact-us'
              ? 'text-ss_primary'
              : 'text-ss_grey hover:text-ss_primary'
          }`}>
          Contact Us
        </Link>
      </div>

      <div className='grid grid-flow-col gap-8 justify-end place-content-center sm:hidden'>
        <a className=' mt-1 py-1' type='number ' href='tel:8773521590'>
          (877) 352-1590
        </a>
        <div className=' bg-ss_primary w-1'></div>
        <Link to='/pricing'>
          <button className=' py-2 px-4 text-xs bg-ss_primary rounded-sm text-ss_white'>
            Repair My Credit Today !
          </button>
        </Link>
      </div>
    </nav>
  );
};

export default NavBar;

import React, { useState } from 'react';

import { NavBar } from '../../components';
import SubList from './Payment Pages/SubList';

const PaymentAdmin = () => {
  const [searchField, setsearchField] = useState('');
  const searchChange = (e) => {
    setsearchField(e.target.value);
  };

  return (
    <div className='flex flex-col items-center justify-center  '>
      <NavBar />
      <p className='font-aulire text-4xl sm:text-2xl text-ss_primary mt-8 '>
        Customer Transaction Control Panel
      </p>
      {/* <div className='flex flex-row justify-center gap-8 w-[100vw] flex-wrap mt-8'>
        <button className=' bg-gray-800 p-1 w-[175px] sm:w-auto rounded-md text-gray-200 text-[12px] sm:mt-4 mb-4'>
          Nihal
        </button>
        
      </div> */}
      <div className='p-4'>
        <label html-for='table-search' className='sr-only'>
          Search
        </label>
        <div className='relative mt-1'>
          <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
            <svg
              className='w-5 h-5 text-gray-500 dark:text-gray-400'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                clipRule='evenodd'
              ></path>
            </svg>
          </div>
          <input
            onChange={searchChange}
            type='text'
            id='table-search'
            className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
            placeholder='Search for Subscription'
          />
        </div>
      </div>
      <div className=' flex flex-col overflow-hidden'>
        <SubList search={searchField} />
      </div>
      <div className='flex flex-col justify-start items-center sm:mt-6'></div>
    </div>
  );
};

export default PaymentAdmin;

import React from 'react';
import { Footer, NavBar } from '../../components';

const Policy = () => {
  return (
    <div className=' flex flex-col justify-center items-center overflow-hidden'>
      <NavBar pagename='home' />

      <div className='flex flex-col sm:justify-start sm:items-start justify-center items-center my-[64px] sm:my-6'>
        <div>
          <p className=' text-[32px] leading-[48px] sm:text-[16px] sm:leading-[24pxpx] font-[aulire] pb-6 sm:pb-4'>
            INFORMATION WE COLLECT
          </p>
          <p className='text-[16px] sm:text-[11px] sm:leading-[16px] leading-[24px] w-[1070px] sm:w-[300px] pb-20 sm:pb-8'>
            The information that we collect is generally limited to personal
            information about you that you submit on the Website. The personally
            identifiable information that may be collected includes: your name;
            address; email address; telephone number; fax number; information
            about your interests in and use of various products, programs, and
            services; and education level and interests. Although we are not
            collecting credit card information at this time, we reserve the
            right to do so in the future. To the extent that customer credit
            card-specific information is collected by our company and any of our
            affiliates and/or subsidiaries, said information will be kept in
            confidence and will not be shared with any third parties (other than
            consumer credit agencies) without the customer’s prior informed
            consent. Notwithstanding the foregoing, we and our affiliates and
            subsidiaries reserve the right to share with third parties the fact
            that they have credit card information on file for specific
            customers but they will not share this credit card information with
            third parties without the customer’s prior informed consent. Like
            most standard website servers, we use log files. Log files track
            anonymous user information including, but not limited to, Internet
            Protocol (“IP”) addresses, browser type, Internet Service Provider
            (“ISP”), referring/exit pages, platform type, date/time stamp, and
            number of clicks. This information is tracked in order to analyze
            trends, administer the Website, track users’ movements in the
            aggregate and gather broad demographic information for aggregate
            use. The information tracked via log files is not linked to
            personally identifiable information.
          </p>
          <p className=' text-[32px] leading-[48px] sm:text-[16px] sm:leading-[24pxpx] font-[aulire] pb-6'>
            Cookies
          </p>
          <p className='text-[16px] leading-[24px] sm:text-[11px] sm:leading-[16px] w-[1070px] sm:w-[300px] pb-20 sm:pb-6'>
            The Website uses a “cookie” to assign a unique identifier to your
            computer. The cookies we use are persistent files that your web
            browser places on your computer’s hard drive. Cookies may be used to
            help save information on past activities and other preferences, so
            that you are not required to reenter information each time that you
            visit the Website, and to deliver content specific to your
            interests. No personally identifiable information is gathered by the
            cookie.
          </p>
          <p className=' sm:text-[16px] sm:leading-[24pxpx] text-[32px] leading-[48px] font-[aulire] pb-6'>
            HOW WE USE YOUR INFORMATION
          </p>
          <p className='text-[16px] leading-[24px] w-[1055px] sm:w-[300px] pb-20 sm:pb-6 sm:text-[11px] sm:leading-[16px]'>
            {' '}
            Any personal information that you supply to us remains your personal
            property. However, by submitting that information our company, you
            grant us the right to use your personal information for any legal
            purpose including, without limitation: a) providing promotional
            offers to you via email advertising and/or online banner
            advertising; b) transferring the personal information that you
            provided to a third party, when necessary, to receive a product,
            service, or information that you may have requested from such third
            party while using the Website; c) tracking compliance with our Terms
            and Conditions; and d) for validation, suppression, content
            improvement and feedback purposes. By submitting your information to
            the Website, you grant our company the right to use that information
            for marketing purposes, and agree to receive email marketing from
            the Website. We may maintain separate email lists for different
            purposes. In order to end your email subscription to a particular
            list, you must follow the instructions contained in any email
            correspondence that you receive from us. Unsubscribing from one list
            will not automatically unsubscribe you from all email lists. We only
            send email marketing to individuals that have agreed, whether by
            visiting this Website or through third party websites, to receive
            email marketing from us. We do not send unsolicited bulk email. As a
            result, certain statutes, requiring particular formatting for
            unsolicited bulk email may not be applicable to the bulk email that
            is sent by our company. Though we make every effort to preserve user
            privacy, we may be required to disclose personal Information in some
            instances, such as: 1) when required by law wherein we have a good
            faith belief that such action is necessary to comply with a judicial
            proceeding, court order, and/or subpoena; 2) in the event that our
            company is sold or acquired; or 3) in the event that we believe that
            the Website is being, or has been, used in violation of our terms
            and conditions or to commit unlawful acts. Moreover, you hereby
            consent to the disclosure of any record or communication to any
            third party when our company, in its sole discretion, determines the
            disclosure to be appropriate including, without limitation, sharing
            your email address with other third parties for suppression purposes
            in compliance with the provisions of the CAN-SPAM Act of 2003, as
            amended from time-to-time.
          </p>
          <p className='sm:text-[16px] sm:leading-[24px] text-[32px] leading-[48px] font-[aulire] pb-6'>
            SHARING OF INFORMATION
          </p>
          <p className='text-[16px] leading-[24px] w-[1055px] sm:w-[300px] pb-20 sm:pb-6 sm:text-[11px] sm:leading-[16px]'>
            Our company provides you with the opportunity to receive special
            offers, products and/or services from other third-party companies.
            To receive such offers, products and/or services your personally
            identifiable information supplied to us during the registration
            process will be shared with such third-party companies so that they
            may provide the product or service to you that you have ordered from
            them without requiring that you first complete another registration
            form. By submitting your email address at the Website you agree to
            receive email marketing from our company. We may share our user
            information and/or join together with other businesses to bring
            selected opportunities to our users. We are able to offer third
            party services to you, in part, based on your willingness to be
            reached by our third-party advertisers. In addition, you agree that
            such act constitutes a purchase, an inquiry and/or an application
            for purposes of the Amended Telemarketing Sales Rule, 16 C.F.R.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Policy;

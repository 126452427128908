const NavBar = ({ active }) => {
  return (
    <div className='px-20 sm:px-0 pt-8 sm:pt-0 pb-6 sm:pb-0 flex  justify-between sm:place-self-center sm:justify-start sm:gap-1 bg-ss_white2 text-[16px] sm:text-[11px] leading-[19px] sm:leading-4 sm:flex-wrap text-ss_old_grey sm:p-8 sm:mt-4'>
      <p>
        <span
          className={`${
            active === '1' ? 'bg-ss_primary' : 'bg-ss_grey'
          } py-0 px-[7px] sm:px-1 sm:rounded-sm rounded-[4px] mr-1`}>
          1
        </span>
        <span className='p-[2px] sm:p-0 '>Contact Information</span>
      </p>
      <p>
        <span
          className={` ${
            active === '2' ? 'bg-ss_primary' : 'bg-ss_grey'
          } py-0 px-[7px] rounded-[4px] sm:px-1 sm:rounded-sm mr-1`}>
          2
        </span>
        <span className='p-[2px] sm:p-0'>Select Your Plan</span>
      </p>
      <p>
        <span
          className={` ${
            active === '3' ? 'bg-ss_primary' : 'bg-ss_grey'
          } py-0 px-[7px] sm:px-1 sm:rounded-sm rounded-[4px] mr-1 `}>
          3
        </span>
        <span className='p-[2px] sm:p-0'>Agreement</span>
      </p>
      <p className='sm:mt-4'>
        <span
          className={` ${
            active === '4' ? 'bg-ss_primary' : 'bg-ss_grey'
          } py-0 px-[7px] rounded-[4px] sm:px-1 sm:rounded-sm mr-1`}>
          4
        </span>
        <span className='p-[2px] sm:p-0'>Payment</span>
      </p>
      <p className='sm:mt-4'>
        <span
          className={` ${
            active === '5' ? 'bg-ss_primary' : 'bg-ss_grey'
          } py-0 px-[7px] rounded-[4px] sm:px-1 sm:rounded-sm mr-1`}>
          5
        </span>
        <span className='p-[2px] sm:p-0'>Smart-Credit</span>
      </p>
    </div>
  );
};

export default NavBar;

import React from 'react';
import { useFeatureContext } from '../context/FeatureContext';

const Alert = () => {
  const { alertText, alertType } = useFeatureContext();
  return (
    <div className='fixed__div__centered top-8 z-50 grid place-items-center'>
      <div
        className={`flex p-2 text-lg sm:text-[11px] sm:leading-4 ${
          alertType ? 'bg-green-700 text-green-100' : 'text-red-100 bg-red-700'
        }   rounded-lg sm:rounded`}
      >
        <span className=' font-aulire font-medium py-1 px-2 sm:px-0 sm:py-0'>
          {alertText}
        </span>
      </div>
    </div>
  );
};

export default Alert;

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Auth from './Auth/Auth';
import SSAuth from './Auth/SSAuth';
import AdminAuth from './Auth/AdminAuth';

import AdminDashboard from './pages/admin/AdminDashboard';

import {
  HomePage,
  Pricing,
  CreditMonitoring,
  ContactUs,
  Resources,
  AdminPannel,
} from './pages';
import Policy from './pages/policy/Policy';

import {
  PageA,
  PageB,
  PageC,
  PageD,
  PageE,
} from './pages/credit-monitoring/pagesSmartCredit';

import ContactInformation from '../src/pages/credit-monitoring/pageAfterPricing/pages/page1/ContactInformation';
import YourPlan from '../src/pages/credit-monitoring/pageAfterPricing/pages/page2/YourPlan';
import Agreement from '../src/pages/credit-monitoring/pageAfterPricing/pages/page3/Agreement';
import Payment from '../src/pages/credit-monitoring/pageAfterPricing/pages/page4/Payment';

import Page1 from '../src/pages/credit-monitoring/pageAfterPricing/pages/smart-credit/Page1';
import Page2 from './pages/credit-monitoring/pageAfterPricing/pages/smart-credit/Page2';
import Page3 from './pages/credit-monitoring/pageAfterPricing/pages/smart-credit/Page3';
import Page4 from './pages/credit-monitoring/pageAfterPricing/pages/smart-credit/page4';
import Page5 from './pages/credit-monitoring/pageAfterPricing/pages/smart-credit/page5';

import { useFeatureContext } from './context/FeatureContext';
import Refund from './pages/policy/Refund';
import Shipping from './pages/policy/Shipping';
import Terms from './pages/policy/Terms';
import SmartAuth from './Auth/SmartAuth';
import PaymentAdmin from './pages/admin/PaymentAdmin';
import Customer from './pages/admin/customerAdminstration/Customer';

const App = () => {
  const { isloading } = useFeatureContext();
  return (
    <>
      {isloading && (
        <div className=' z-[999] spinner opacity-75 fixed'>
          <div className='loader'></div>
          <p className=' font-aulire text-lg mt-10'>
            Loading... Please Wait...
          </p>
        </div>
      )}
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/pricing' element={<Pricing />} />
        <Route path='/credit-monitoring' element={<CreditMonitoring />}>
          <Route index element={<PageA />} />

          <Route
            path='verify'
            element={
              <Auth location='verify'>
                <PageB />
              </Auth>
            }
          />

          <Route
            path='package'
            element={
              <Auth location='package'>
                <PageC />
              </Auth>
            }
          />

          <Route
            path='payment'
            element={
              <Auth location='payment'>
                <PageD />
              </Auth>
            }
          />

          <Route
            path='complete'
            element={
              <Auth location='complete'>
                <PageE />
              </Auth>
            }
          />
        </Route>
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/resources' element={<Resources />} />
        <Route path='/resources' element={<Resources />} />
        <Route path='/policy' element={<Policy />} />
        <Route path='/refund_cancellation' element={<Refund />} />
        <Route path='/shipping_policy' element={<Shipping />} />
        <Route path='/termsandcondition' element={<Terms />} />
        <Route path='/admin' element={<AdminPannel />} />
        <Route
          path='/adminDashboard'
          element={
            <AdminAuth location='admin'>
              <AdminDashboard />
            </AdminAuth>
          }
        />
        <Route
          path='/adminPay'
          element={
            <AdminAuth location='admin'>
              <PaymentAdmin />
            </AdminAuth>
          }
        />
        <Route
          path='/customerProfile'
          element={
            <AdminAuth location='customer'>
              <Customer />
            </AdminAuth>
          }
        />
        <Route path='contactInfo' element={<ContactInformation />} />
        <Route
          path='plans'
          element={
            <SSAuth location='plans'>
              <YourPlan />
            </SSAuth>
          }
        />
        <Route
          path='agreement'
          element={
            <SSAuth location='agreement'>
              <Agreement />
            </SSAuth>
          }
        />
        <Route
          path='payment'
          element={
            <SSAuth location='payment'>
              <Payment />
            </SSAuth>
          }
        />
        <Route path='smart-credit' element={<Page1 />} />
        <Route
          path='smart-credit/verify'
          element={
            <SmartAuth location='page2'>
              <Page2 />
            </SmartAuth>
          }
        />
        <Route
          path='smart-credit/package'
          element={
            <SmartAuth location='page3'>
              <Page3 />
            </SmartAuth>
          }
        />
        <Route
          path='smart-credit/payment'
          element={
            <SmartAuth location='page4'>
              <Page4 />
            </SmartAuth>
          }
        />
        <Route
          path='smart-credit/complete'
          element={
            <SmartAuth location='page5'>
              <Page5 />
            </SmartAuth>
          }
        />
        <Route path='*' element={<HomePage />} />
      </Routes>
    </>
  );
};

export default App;

import Header from '../../components/Header';
import Steps from '../../components/Steps';
import NavBar from '../../components/NavBar';
import CompanyLogo from '../../../../../components/CompanyLogo';
import MainImg from '../../components/MainImg';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useAppContext } from '../../../../../context/appContext';
import { useFeatureContext } from '../../../../../context/FeatureContext';
import { Alert } from '../../../../../components';

import axios from 'axios';

const pageData = {
  FirstName: '',
  LastName: '',
  Email: '',
  streetAdress: '',
  zipCode: '',
  city: '',
  state_country: '',
  cardNumber: '',
  ccc: '',
  expDate: '',
};

const Payment = () => {
  const apiUrl = process.env.REACT_APP_API_ENVIRONMENT_URL;

  let navigate = useNavigate();
  const [Values, setValues] = useState(pageData);

  const {
    firstName,
    lastName,
    birthDate,
    ssnShort,
    streetAdress,
    city,
    state_country,
    zipCode,
    phoneNumber,
    email,
    premiumPackage,
  } = useAppContext();
  const {
    displayAlert,
    showAlert,
    updatePageName,
    signatureData,
    setIsLoading,
  } = useFeatureContext();

  const memoValue = premiumPackage ? 'Premium' : 'Basic';

  const [Bankrup, setBankrup] = useState(false);

  const basicAmount = '149.95';
  const basicSignUp = '249.00';
  const premiumSignUp = '2599.00';
  const premiumAmmount = '529.95';
  const bankruptcyAmmount = '1999.99';
  const totalBasic = '398.95';
  const totalPremium = '3128.95';
  const totalBasicWithBank = '2398.94';
  const totalPremiumWithBank = '5128.94';

  const ammountCalc = {
    amount1: premiumPackage && Bankrup ? totalPremiumWithBank : totalPremium,
    ammount2: !premiumPackage && Bankrup ? totalBasicWithBank : totalBasic,
  };

  const handleBankrupt = () => {
    setBankrup(!Bankrup);
  };

  const handleChange = (e) => {
    setValues({ ...Values, [e.target.name]: e.target.value });
  };

  //! invoice Number

  const invoiceNumber = () => {
    return Math.random().toString(36).slice(2, 9);
  };

  //!create pdf of agreement form

  const createAndDownloadPdf = async () => {
    const formData = {
      customer_name: firstName + ' ' + lastName,
      customer_email: email,
      customer_phone: phoneNumber,
      customer_address:
        streetAdress + ' ,' + city + ' ,' + state_country + ' ,' + zipCode,
      package: memoValue,
      signature: signatureData,
    };

    await axios
      .post(`${apiUrl}/createCustomer`, formData)
      .then((res) => {
        console.log(res.data.message);
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  //! crc info insert

  const apiKey = process.env.REACT_APP_API_KEY_CRC;
  const secretkey = process.env.REACT_APP_SECRET_KEY_CRC;

  const crcBirthDate =
    birthDate.slice(5, 7) +
    '/' +
    birthDate.slice(8, 10) +
    '/' +
    birthDate.slice(0, 4);

  const record101 =
    '<?xml version="1.0" encoding="utf-8"?>' +
    '<crcloud>' +
    '<lead>' +
    `<id>${apiKey}</id>` +
    '<type>Client</type>' +
    `<firstname>${firstName}</firstname>` +
    `<lastname>${lastName}</lastname>` +
    `<email>${email}</email>` +
    `<phone_work>${phoneNumber}</phone_work>` +
    `<street_address>${streetAdress}</street_address>` +
    `<city>${city}</city>` +
    `<state>${state_country}</state>` +
    `<zip>${zipCode}</zip>` +
    `<ssno>${ssnShort}</ssno>` +
    `<memo>${memoValue}</memo>` +
    `<client_portal_access>off</client_portal_access>` +
    `<birth_date>${crcBirthDate}</birth_date>` +
    '</lead>' +
    '  </crcloud>';
  const insertRecordCRC = () => {
    fetch(
      `https://app.creditrepaircloud.com/api/lead/insertRecord?apiauthkey=${apiKey}&secretkey=${secretkey}&xmlData=${record101}&type=Client&firstname=${firstName}&lastname=${lastName}`
    ).then((response) => {
      response
        .text()
        .then((xml) => {
          const parser = new DOMParser();
          const result = parser.parseFromString(xml, 'application/xml');
          const resultnode = result.querySelectorAll('success');
          if (resultnode[0].childNodes[0].data === 'True') {
            console.log('successfully inserted data');
          } else throw Error('Error Occured! Try Again');
        })
        .catch(() => {
          console.log('error');
        });
    });
  };

  // !  authorized.net

  let todayDate = new Date().toISOString().slice(0, 10);
  let todayDateC = todayDate.replace(/\s+/g, '');

  const authorizedEnv = process.env.REACT_APP_ENVIRONMENT_AUTHORIZED_NET;
  const authorizedName = process.env.REACT_APP_CLINT_KEY_AUTHORIZED_NET;
  const authorizedTranscation =
    process.env.REACT_APP_TRANSACTION_KEY_AUTHORIZED_NET;

  //! subscription plan

  const authorizeddotnetSubscription = async () => {
    const response = await fetch(authorizedEnv, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ARBCreateSubscriptionRequest: {
          merchantAuthentication: {
            name: authorizedName,
            transactionKey: authorizedTranscation,
          },
          subscription: {
            name: 'SS Ultimate Solutions Subscription',
            paymentSchedule: {
              interval: {
                length: '1',
                unit: 'months',
              },
              startDate: todayDateC,
              totalOccurrences: '9999',
            },
            amount: Number(premiumPackage ? premiumAmmount : basicAmount),
            payment: {
              creditCard: {
                cardNumber: Values.cardNumber,
                expirationDate: Values.expDate,
                cardCode: Values.ccc,
              },
            },
            customer: {
              email: email,
              phoneNumber: phoneNumber,
            },
            billTo: {
              firstName: firstName,
              lastName: lastName,
              address: streetAdress,
              city: Values.city,
              state: Values.state_country,
              zip: zipCode,
            },
          },
        },
      }),
    });
    const data = await response.json();
    if (data.messages.resultCode === 'Error') {
      // console.log(data);
      // console.log(data.messages.message[0].text);
      throw Error(data.messages.message[0].text);
    } else {
      console.log('successfully created subscription plan!');
    }
  };

  //! charging credit card

  const authorizeddotnetChargingCard = async () => {
    const response = await fetch(authorizedEnv, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        createTransactionRequest: {
          merchantAuthentication: {
            name: authorizedName,
            transactionKey: authorizedTranscation,
          },
          transactionRequest: {
            transactionType: 'authCaptureTransaction',
            amount: Number(
              premiumPackage ? ammountCalc.amount1 : ammountCalc.ammount2
            ),
            currencyCode: 'USD',
            payment: {
              creditCard: {
                cardNumber: Values.cardNumber,
                expirationDate: Values.expDate,
                cardCode: Values.ccc,
              },
            },
            order: {
              invoiceNumber: invoiceNumber(),
              description: 'Credit Repair You Can Trust',
            },
            lineItems: {
              lineItem: {
                itemId: '1',
                name: premiumPackage ? 'VIP Monthly Plan' : 'Base Monthly Plan',
                description: Bankrup
                  ? 'With Bankruptcy Removal Option'
                  : 'Without Bankruptcy Removal options',
                quantity: '1',
                unitPrice: premiumPackage
                  ? ammountCalc.amount1
                  : ammountCalc.ammount2,
              },
              // lineItem: {
              //   itemId: '2',
              //   name: 'Bankruptcy',
              //   description: Bankrup
              //     ? 'With Bankruptcy Removal Option'
              //     : 'Without Bankruptcy Removal options',
              //   quantity: '1',
              //   unitPrice: premiumPackage
              //     ? ammountCalc.amount1
              //     : ammountCalc.ammount2,
              // },
            },
            customer: {
              type: 'individual',
              email: email,
            },
            billTo: {
              firstName: firstName,
              lastName: lastName,
              address: streetAdress,
              city: city,
              state: state_country,
              zip: zipCode,
              country: 'US',
              phoneNumber: phoneNumber,
            },
            authorizationIndicatorType: {
              authorizationIndicator: 'final',
            },
          },
        },
      }),
    });
    const data = await response.json();
    if (data.messages.resultCode === 'Error') {
      // console.log(data.messages.message[0].text);
      throw Error(data.messages.message[0].text);
    } else {
      console.log('success!');
    }
  };
  const apiCalls = async () => {
    try {
      if (!Values.cardNumber || !Values.ccc) {
        setIsLoading(false);
        throw Error('Please Provide a credit card');
      } else {
        await authorizeddotnetSubscription();
        await authorizeddotnetChargingCard();
        await createAndDownloadPdf();
        insertRecordCRC();
        updatePageName('page1');
        navigate('/smart-credit');
        setIsLoading(false);
      }
    } catch (error) {
      displayAlert(error.message);
      setIsLoading(false);
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await apiCalls();
  };

  return (
    <div className='flex justify-center items-center'>
      <div className='flex flex-col box_shadow_universal w-[1170px] sm:w-[auto] mx-[200px] sm:mx-6 mt-[100px] sm:mt-6 mb-[200px] sm:mb-4'>
        <CompanyLogo company={true} />
        <NavBar active='4' />
        <div className='sm:hidden'>
          <Steps stepNo='4' />
        </div>
        <div className='sm:hidden ml-10'>
          <Header head='Payment' />
        </div>
        <div className='flex flex-row sm:flex-col sm:mt-8'>
          <div className=' p-[58px] sm:hidden'>
            <MainImg />
          </div>

          <div className='flex flex-col p-[80px] sm:p-0  sm:text-[11px] sm:leading-4'>
            <div className='flex flex-row sm:flex-col sm:gap-2'>
              <div className='sm:flex sm:flex-col p-[12px] sm:p-0 gap-2'>
                <label>First Name</label>
                <input
                  name='FirstName'
                  value={firstName}
                  disabled
                  className='input__style'
                />
                <label>Last Name</label>
                <input
                  name='LastName'
                  value={lastName}
                  disabled
                  className='input__style'
                />
                <label className='label__fonts'>Address</label>
                <input
                  name='streetAdress'
                  value={streetAdress}
                  disabled
                  className='input__style'
                />
              </div>

              <div className='sm:flex sm:flex-col p-[12px] sm:p-0 gap-2'>
                <label>City</label>
                <input
                  name='city'
                  value={city}
                  disabled
                  className='input__style'
                />
                <label>State</label>
                <input
                  name='state_country'
                  value={state_country}
                  disabled
                  className='input__style'
                />
                <label> Zip Code</label>
                <input
                  name='zipCode'
                  value={zipCode}
                  disabled
                  className='input__style'
                />
              </div>
            </div>
            <div className='sm:flex sm:flex-col ml-1 sm:ml-0 p-[10px] sm:p-0'>
              <label className=' sm:mt-2'>Email Address</label>
              <br />
              <input
                name='Email'
                value={email}
                disabled
                className=' input__style sm:mt-[-4px] '
              />
            </div>
          </div>
        </div>

        <div className=' ml-[70px] sm:ml-0'>
          <p className='my-12 sm:my-6 font-semibold text-[16px] sm:text-xs leading-[19px] sm:leading-4 text-ss_old_grey'>
            Your Order
          </p>
          <div className=' sm:flex sm:justify-between'>
            <span className='mr-[460px] sm:mr-0 my-12 sm:my-4 font-semibold text-[16px]  leading-[19px] text-ss_old_grey'>
              Product
            </span>
            <span className='my-12 sm:my-4 font-semibold text-[16px]  leading-[19px]  text-ss_old_grey'>
              Subtotal
            </span>
          </div>
          <div className=' sm:flex sm:justify-between  my-4'>
            <span className=' mr-[300px] sm:mr-0 mb-[17px] font-semibold text-[16px] sm:text-xs leading-[19px] sm:leading-4'>
              <span className='YourPlanInfoClass'>✔</span>
              <span>
                &nbsp;
                {premiumPackage
                  ? ' VIP Monthly Package'
                  : ' Base Monthly Package'}
              </span>
            </span>
            <span
              className={`sm:text-[11px] sm:leading-4 sm:text-right ${
                premiumPackage ? 'ml-5 sm:ml-0' : 'ml-[5px] sm:ml-0'
              }`}
            >
              {premiumPackage
                ? `$${premiumAmmount} / Month + $${premiumSignUp} sign-up fee`
                : `$${basicAmount} / Month + $${basicSignUp} sign-up fee`}
            </span>
          </div>
          {Bankrup && (
            <>
              <span className=' mr-[400px] sm:mt-[-100px] sm:mr-[200px] mb-[17px] font-semibold text-[16px] sm:text-xs leading-[19px] sm:leading-4 '>
                <span className='YourPlanInfoClass'>✔</span> Bankruptcy
              </span>
              <span className=' sm:text-xs'>${bankruptcyAmmount}</span> <br />
              <br></br>
            </>
          )}
          <span className='my-12 sm:my-0 sm:mt-[-40px] mr-[485px] sm:mr-[240px] font-semibold text-[16px] leading-[19px]  text-ss_old_grey'>
            Total
          </span>
          <span className={`${premiumPackage ? ' ml-[1px]' : 'hidden'}`}>
            {Bankrup ? '$' + totalPremiumWithBank : '$' + totalPremium}
          </span>
          <span className={`${premiumPackage ? 'hidden' : ' ml-0'}`}>
            {Bankrup ? '$' + totalBasicWithBank : '$' + totalBasic}
          </span>
          <p className='my-12 sm:my-4 font-semibold text-[16px] sm:text-xs leading-[19px] sm:leading-4 text-ss_old_grey'>
            BANKRUPTCY REMOVAL
          </p>
          <p className=' text-left mr- sm:mr-0 sm:w-[300px] sm:text-xs sm:leading-4 sm:mb-1'>
            If you have a bankruptcy and want it removed click the box belkow.
            We know how bankuptcy can hold you back from getting approvals on
            new credits. Start the process today and within 6-12 months it can
            be removed.
          </p>
          <input type='checkbox' onChange={handleBankrupt} />
          <span> &nbsp;Yes, Please remove my Bankruptcy</span>
          <p className='my-12 sm:my-4 font-semibold text-[16px] sm:text-xs leading-[19px] sm:leading-4 text-ss_old_grey'>
            Credit Card{' '}
          </p>
          <div className='flex flex-col sm:gap-[1px] sm:text-[11px] sm:leading-4'>
            <div className=' mb-4 sm:mb-2 sm:flex sm:flex-col sm:gap-[1px]'>
              {' '}
              <label> Card Number</label>
              <br />
              <input
                onChange={handleChange}
                name='cardNumber'
                type='tel'
                inputMode='numeric'
                pattern='[0-9\s]{13,19}'
                minLength={15}
                maxLength={16}
                placeholder='xxxx-xxxx-xxxx-xxxx'
                value={Values.cardNumber}
                required
                className='input__style '
              />
            </div>
          </div>
          <div className='flex flex-row sm:flex-col sm:gap-2 sm:text-[11px] sm:leading-4'>
            <div className='mr-[26px] sm:mr-0 sm:flex sm:flex-col sm:gap-[1px]'>
              <label> Expiration (MM/YY)</label>
              <br />
              <input
                onChange={handleChange}
                type='month'
                name='expDate'
                value={Values.expDate}
                required
                className='input__style'
              />
            </div>
            <div className='sm:flex sm:flex-col sm:gap-[1px]'>
              <label>Card Security Code</label> <br />
              <input
                onChange={handleChange}
                name='ccc'
                minLength={3}
                maxLength={4}
                value={Values.ccc}
                required
                className='input__style'
              />
            </div>
          </div>
        </div>
        <div className=' ml-[650px] mt-5 sm:mt-8 sm:ml-5'>
          {showAlert && <Alert />}
        </div>

        <div className='w-[200px] sm:w-[80px] ml-[900px] sm:ml-[240px] mt-[40px] sm:mt-8 mb-[48px] sm:mb-6 justify-end'>
          <button
            onClick={onSubmit}
            className=' w-[130px] sm:w-[80px] h-[48px] sm:h-[33px] bg-ss_primary rounded-md sm:rounded-sm font-semibold text-ss_white2 cursor-pointer z-10 sm:text-xs sm:leading-4'
          >
            Place Order
          </button>
        </div>
      </div>
    </div>
  );
};

export default Payment;

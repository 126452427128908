import logo1 from '../assets/smartCredit.png';
import logo2 from '../assets/hero_logo.png';

const CompanyLogo = ({ company }) => {
  return (
    // <div className=' my-[60px] sm:my-4 sm:h-[20px]'>
    //   <img src={logo1} alt='SS' className=' h-[40px] w-[350px] sm:w-[300px]' />
    // </div>
    <div className='flex items-center justify-center sm:justify-start my-10 sm:my-4'>
      {company && (
        <img
          className=' w-24 h-16 mb-7 sm:mb-0 sm:ml-[-14px]'
          src={logo2}
          alt='SS Ultimate Solutions'
        />
      )}
      {!company && (
        <img
          src={logo1}
          alt='SS Ultimate Solutions Partnered with Smart Credit '
          className=' w-[350px] sm:w-[300px]'
        />
      )}
    </div>
  );
};

export default CompanyLogo;

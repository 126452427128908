import React from 'react';
import { Link } from 'react-router-dom';
import smartCredit__logo from '../../../assets/smartCredit.png';
import { NavBar, Footer, CreditPageNav } from '../../../components';

const PageE = () => {
  return (
    <div className=' grid place-items-center'>
      <div>
        <NavBar pagename='credit-monitoring' />
      </div>

      {/* //! page 1 */}
      <div className='grid sm:ml-0'>
        <div className=' grid justify-items-start  my-[60px] sm:my-4 ml-[-24px] sm:ml-[-16px] sm:h-[20px]'>
          <img
            className=' w-[350px] sm:w-[280px]'
            src={smartCredit__logo}
            alt='SS'
          />
        </div>
        {/* //! process bar */}
        <CreditPageNav processState='complete' />
        <div className=' place-self-center text-center my-20 sm:my-6 mx-4'>
          <p className='text-[45px] sm:text-[23px] leading-[68px] sm:leading-[34px]'>
            Thank You
          </p>
          <p className='text-[23px] sm:text-base leading-[34px] sm:leading-6'>
            Your Order Has Been Confirmed
          </p>
        </div>
        <div className=' place-self-center'>
          <Link to='/'>
            <button className=' py-2 px-4 w-[113px] sm:w-20 h-[51px] sm:h-10 text-[23px] sm:text-base leading-[34px] sm:leading-6 bg-ss_primary rounded-[5px] text-ss_white mt-4 mb-[200px] sm:mb-12'>
              Next
            </button>
          </Link>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default PageE;

import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

const PopupPay = ({ id, close }) => {
  const authorizedEnv = process.env.REACT_APP_ENVIRONMENT_AUTHORIZED_NET;
  const authorizedName = process.env.REACT_APP_CLINT_KEY_AUTHORIZED_NET;
  const authorizedTranscation =
    process.env.REACT_APP_TRANSACTION_KEY_AUTHORIZED_NET;

  const [customer, setCustomer] = useState();
  const fullInfo = async () => {
    if (id) {
      await axios
        .post(authorizedEnv, {
          ARBGetSubscriptionRequest: {
            merchantAuthentication: {
              name: authorizedName,
              transactionKey: authorizedTranscation,
            },
            refId: '123456',
            subscriptionId: id,
            includeTransactions: true,
          },
        })
        .then((response) => {
          console.log(response);
          setCustomer(response.data.subscription);
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    fullInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='fixed__div__centered top-[30%] z-50'>
      <div className=' bg-orange-400 flex justify-center items-center flex-col'>
        <div className='flex w-full justify-end items-end mt-2'>
          <button onClick={() => close(false)} className=' px-3 mr-2 border-2'>
            X
          </button>
        </div>
        {customer ? (
          <div className=' p-4 z-[999]'>
            <h1>Sub ID: {id}</h1>
            <h2>Name :{customer.name}</h2>
            <h2>Amount :{customer.amount}</h2>
            <h2>Start Date:{customer.paymentSchedule.startDate}</h2>
            <h2>Profile Id:{customer.profile.customerProfileId}</h2>
            <h2>Email:{customer.profile.email}</h2>
            <h2>
              Address:
              {customer.profile.paymentProfile.billTo.address +
                ', ' +
                customer.profile.paymentProfile.billTo.zip}
            </h2>
            <h2>
              Customer Type:{customer.profile.paymentProfile.customerType}
            </h2>
            <h2>
              Card Number:
              {customer.profile.paymentProfile.payment.creditCard.cardNumber}
            </h2>
          </div>
        ) : (
          ' No info Available'
        )}
      </div>
    </div>
  );
};

export default PopupPay;

import { React, useState } from 'react';
import Steps from '../../components/Steps';
import Header from '../../components/Header';
import MainImg from '../../components/MainImg';
import NavBar from '../../components/NavBar';
import CompanyLogo from '../../../../../components/CompanyLogo';
import SignatureCanvas from 'react-signature-canvas';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { useAppContext } from '../../../../../context/appContext';
import { useEffect } from 'react';
import { useFeatureContext } from '../../../../../context/FeatureContext';
import { Alert } from '../../../../../components';

const pageData = {
  FirstName: '',
  LastName: '',
  dateOfBirth: '',
  ssnShort: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
  PhoneNumber: '',
  Email: '',
  name: '',
  image: null,
};

const Agreement = () => {
  const navigate = useNavigate();
  let today = new Date().toString();
  const [values, setvalues] = useState(pageData);
  let sigPad = useRef({});
  let padData = '';

  const {
    firstName,
    lastName,
    birthDate,
    ssnShort,
    streetAdress,
    city,
    state_country,
    zipCode,
    phoneNumber,
    email,
    stateUpdateAgrement,
  } = useAppContext();
  const {
    updatePageName,
    displayAlert,
    showAlert,
    setIsLoading,
    setSignatureImage,
  } = useFeatureContext();

  const [isImage, setIsImage] = useState(false);
  const clearPad = () => {
    sigPad.current.clear();
    setIsImage(false);
  };
  const savePad = () => {
    padData = sigPad.current.toDataURL();
    setvalues({ ...values, image: padData });
    setIsImage(true);
  };

  const handleChange = (e) => {
    setvalues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (
      !values.image ||
      !values.FirstName ||
      !values.LastName ||
      !values.dateOfBirth ||
      !values.ssnShort ||
      !values.address ||
      !values.city ||
      !values.state ||
      !values.zipCode ||
      !values.PhoneNumber ||
      !values.Email ||
      !values.image
    ) {
      displayAlert('Please Provide All Information');
      setIsLoading(false);
    } else {
      setSignatureImage(values.image);
      stateUpdateAgrement(
        values.FirstName,
        values.LastName,
        values.dateOfBirth,
        values.ssnShort,
        values.address,
        values.city,
        values.state,
        values.zipCode,
        values.PhoneNumber,
        values.Email
      );
      updatePageName('payment');
      navigate('/payment');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setvalues({
      ...values,
      FirstName: firstName,
      LastName: lastName,
      dateOfBirth: birthDate,
      ssnShort: ssnShort,
      address: streetAdress,
      city: city,
      state: state_country,
      zipCode: zipCode,
      PhoneNumber: phoneNumber,
      Email: email,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='flex justify-center items-center'>
      <div className='flex flex-col box_shadow_universal w-[1170px] sm:w-[auto] mx-[200px] sm:mx-6 mt-[100px] sm:mt-6 mb-[200px] sm:mb-4'>
        <CompanyLogo company={true} />
        <NavBar active='3' />

        <div className='sm:hidden'>
          <Steps stepNo={3} />
        </div>
        <div className=' ml-16 sm:hidden'>
          <Header head='Agreement' />
        </div>
        <div className='flex flex-row sm:flex-col'>
          <div className=' p-[42px] sm:hidden'>
            <MainImg />
          </div>

          <div className='p-[48px] sm:mt-8 sm:p-0 sm:flex sm:flex-col sm:gap-2 sm:text-[11px] sm:leading-4'>
            <label>First Name</label>
            <input
              value={values.FirstName}
              onChange={handleChange}
              name='FirstName'
              required
              className='input__style'
            />
            <label>Last Name</label>
            <input
              value={values.LastName}
              onChange={handleChange}
              name='LastName'
              required
              className='input__style'
            />
            <label>Date of Birth</label>
            <input
              onChange={handleChange}
              value={values.dateOfBirth}
              type='date'
              min='1970-01-01'
              max='2002-01-01'
              name='dateOfBirth'
              required
              className='input__style'
            />
            <label>Last 4 of SSN</label>
            <input
              onChange={handleChange}
              value={values.ssnShort}
              type='text'
              maxLength='4'
              minLength='4'
              name='ssnShort'
              required
              className='input__style'
            />
            <label>Address</label>
            <input
              onChange={handleChange}
              maxLength={60}
              value={values.address}
              required
              name='address'
              className='input__style'
            />
          </div>

          <div className='p-[48px] sm:p-0 sm:flex sm:flex-col sm:gap-2 sm:text-[11px] sm:leading-4'>
            <label>State</label>
            <input
              onChange={handleChange}
              maxLength={2}
              minLength={2}
              value={values.state}
              required
              name='state'
              className='input__style'
            />

            <label>City</label>
            <input
              onChange={handleChange}
              name='city'
              value={values.city}
              maxLength={100}
              required
              className='input__style'
            />
            <label> Zip Code</label>
            <input
              onChange={handleChange}
              name='zipCode'
              minLength={5}
              maxLength={5}
              type='tel'
              pattern='^\d{5}$'
              value={values.zipCode}
              className='input__style'
              required
            />
            <label>Phone Number</label>
            <input
              onChange={handleChange}
              name='PhoneNumber'
              value={values.PhoneNumber}
              required
              type='tel'
              minLength={10}
              maxLength={10}
              className='input__style'
            />
            <label>Email Address</label>
            <input
              onChange={handleChange}
              name='Email'
              autoComplete='email'
              pattern="[-a-zA-Z0-9~!$%^&amp;*_=+}{'?]+(\.[-a-zA-Z0-9~!$%^&amp;*_=+}{'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(\.[-a-zA-Z0-9_]+)*\.([cC][oO][mM]))(:[0-9]{1,5})?"
              value={values.Email}
              type='email'
              required
              className='input__style'
            />
          </div>
        </div>

        <div className='my-[40px] sm:my-4 ml-[56px] sm:ml-0 mr-[40px] sm:mr-0 sm:w-[300px] sm:text-[11px] leading-4'>
          <p className=' my-12 sm:my-4 font-semibold text-[16px] sm:text-xs leading-[19px] sm:leading-4 text-ss_old_grey'>
            The Following Pages Contain:
          </p>
          <p>1. Credit Repair Service Agreement</p>
          <p>2. Authorization for Credit Repair Action</p>
          <p>3. Consumer Credit File Rights ( CORA Disclosure)</p>
          <p>4. Right Of Cancellation Notice</p>
          <p>5. State Specific Disclosure ( add if applicable )</p>

          <p className='my-12 sm:my-4 font-semibold text-[16px] sm:text-xs leading-[19px] sm:leading-4 text-ss_old_grey'>
            Credit Repair Service Agreement For
          </p>

          <p>
            I,
            <input
              value={values.FirstName + ' ' + values.LastName}
              disabled
              className='Custom__Input__Box'
            />{' '}
            , hereby enter into the following agreement with SS Ultimate
            Solutions.{' '}
          </p>
          <p className='my-12 sm:my-4 font-semibold text-[16px] sm:text-xs leading-[19px] sm:leading-4 text-ss_old_grey'>
            {' '}
            SS ULtimate Solutions hereby agrees to the following:
          </p>
          <p>
            a. To evaluate Customer's current credit reports as listed with
            applicable credit reporting agencies and to identify inaccurate,
            erroneous, false, or obsolete information. To advise Customer as to
            the necessary steps to be taken on the part of the Customer in
            conjunction with Our Company, to dispute any inaccurate , erroneous,
            false or obsolete information contained in the customer's credit
            reports.
          </p>

          <p>
            b. To prepare all necessary correspondence in dispute of inaccurate,
            erroneous, false , or obsolete information in customer's credit
            reports.
          </p>
          <p>
            c. To review credit profile status from the credit reporting
            agencies , such as : Experian , Equifax and Transfusion. Consulting,
            coaching, and monitoring services are conducted by personal meeting
            , webinars, video conferencing, telephone, email, or by any other
            form of communication during normal business hours.
          </p>

          <p className='mt-12 sm:mt-4'>
            In exchange, I,{' '}
            <input
              value={values.FirstName + ' ' + values.LastName}
              disabled
              className='Custom__Input__Box'
            />{' '}
            , agree to pay the following fees as outlined in the following fee
            schedule:{' '}
          </p>

          <p>
            {' '}
            $249 at sign up for document processing. This fee is not for credit
            repair services . This fee is to conduct an audit and for the
            processing of your documents to enroll you a new client. This fee is
            Non-Refundable.
          </p>
          <p>
            2. $149.95 every 30 days from the document processing. This is for
            the previous 30 days of services. All fees are paid after work has
            been completed.
          </p>

          <p className='mt-12 sm:mt-4'>
            I,{' '}
            <input
              value={values.FirstName + ' ' + values.LastName}
              disabled
              className='Custom__Input__Box'
            />
            , authorize SS Ultimate Solutions to change my card or account
            payment of the service in this agreement.
          </p>

          <p className='my-12 sm:my-4 font-semibold text-[16px] sm:text-xs leading-[19px] sm:leading-4 text-ss_old_grey'>
            Authorization for Credit Repair Action
          </p>
          <p>
            {' '}
            I,
            <input
              value={values.FirstName + ' ' + values.LastName}
              disabled
              className='Custom__Input__Box'
            />{' '}
            hereafter known as “client” hereby authorize, SS Ultimate Solution,
            515 E Las Olas Boulevard Suite 120, Fort Lauderdale, FL 33301, to
            make, receive, sign, endorse, execute, acknowledge, deliver, and
            possess such application, correspondence, contracts, or agreements,
            as necessary to improve my credit. Such Instruments in writing of
            whatever and nature shall only be effective for any or all of the
            theres credit reporting agencies which are TransUnion, Experian,
            Equifax, and any other reporting agencies or creditor's listed, as
            may be necessary or proper in the exercise of the rights and powers
            herein granted.
          </p>
          <p>
            2.This authorization may be revoked by the undersigned at any time
            by giving written notice to the party authorized herein. Any
            activity made prior to revocation in reliance upon this
            authorization shall not constitute a breach of rights to the client,
            If not earlier revoked, this authorization will automatically expire
            twelve months from the date of signature.
          </p>
          <p>
            3.The Party named above to receive the information is not authorized
            to make any further release or disclosure of the information
            received. This authorization does not authorize the release or
            disclosure of any information expect as provided herein.{' '}
          </p>
          <p>
            4.I grant to SS Ultimate Solution , 515 E Las Olas Boulevard Suite
            120, Fort Lauderdale, FL 33301, authority to do, take, and perform,
            all acts and things whatsoever requisite, proper, or necessary to be
            done, in the exercise of repairing my credit with the three credit
            reporting agencies, which are TransUnion, Experian, Equifax and any
            other reporting agencies or creditor's listed, as fully for all
            intents and purposes as I might or could do if personally present.
          </p>
          <p>
            5.I hereby release SS Ultimate Solution , 515 E Olas Boulevard Suite
            120 , Fort Lauderdale, FL 33301, from all and all matters of action
            causes of action, suits, proceedings , debts, dues, contracts,
            judgement, damages, claims, and demands whatsoever in law or equity,
            for or by reason of any matter, cause, or thing whatsoever as based
            on the circumstances of this contract.
          </p>

          <p className='my-12 sm:my-4 font-semibold text-[16px] sm:text-xs leading-[19px] sm:leading-4 text-ss_old_grey'>
            {' '}
            Consumer Credit File Rights Under State and Federal Law
          </p>
          <p>
            You have a right to dispute inaccurate information in your credit
            report by contacting the credit bureau directly. However, neither
            you nor a credit repair company or credit repair organization has
            the right to have accurate, current and verifiable information
            removed from your credit report. The credit bureau must remove
            accurate, negative information from your credit report only if it is
            over 7 years old. Bankruptcy information can be reported up to 10
            years.
            <br />
            <br></br>
            <br></br>
            You have a right to obtain a copy of your credit report from a
            credit bureau. You may be changed a reasonable fee. There is no fee,
            however, if you have been turned down for credit, employment,
            insurance, or a rental dwelling because of information in your
            credit report within the preceding 60 days. The credit bureau must
            provide someone to help you interpret the information in your credit
            file. You are entitled to receive a free copy of your credit report
            if you are unemployed and intend to apply for employment in the next
            60 days. If you are a recipient of public welfare assistance, or if
            you have reason to believe that there is inaccurate information in
            your credit report due to fraud. <br />
            <br></br>
            <br></br>
            You have a right to sue a credit repair organization that violated
            the Credit Repair Organization Act. This law prohibits deceptive
            practices by credit repair organizations, You have a right to cancel
            your contract with any credit repair organization for any reason
            within 3 business days from the date you signed it.
            <br />
            <br></br>
            <br></br>
            Credit bureaus are required to follow reasonable procedures to
            ensure that the information they report is accurate. However,
            mistakes may occur.
            <br />
            <br></br>
            <br></br>
            You may, on your own, notify a credit bureau in writing that you
            dispute that accuracy of information in your credit file. The credit
            bureau must then reinvestigate and modify or remove inaccurate or
            incomplete information. The credit bureau may not change any fee for
            this service. Any pertinent information and copies of all documents
            you have concerning an error should be given to the credit bureau.
            <br />
            <br></br>
            <br></br>
            If the credit bureau's reinvestigation does not resolve the dispute
            to your satisfaction, you may send a brief statement to the credit
            bureau to be kept in your file, explaining why you think the record
            is inaccurate. The credit bureau must include a summary of your
            statement about disputed information with any report it issues about
            you.
            <br />
            <br></br>
            <br></br>
            The Federal Trade Commission regulates credit bureaus and credit
            repair organizations. For more information contact. The Public
            Reference Branch Federal Trade Commission Washington, D.C, 20580.
          </p>

          <p className='my-12 sm:my-4 font-semibold text-[16px] sm:text-xs leading-[19px] sm:leading-4 text-ss_old_grey'>
            Notice of Right to Cancle{' '}
          </p>

          <p>
            “You may cancel this contract, without any penalty or obligation, at
            any time before midnight of the 3rd day which begins after after the
            date the contract is signed by you.” <br />
            “To cancel this contract, e-mail (cs@ssultimatesolutions.com), mail
            or deliver a signed, dated copy of this cancellation notice, this
            cancellation notice, or any other written notice to SS Ultimate
            Solutions, 515 E Las Olas Boulevard Suit 120, Fort Lauderdale, FL
            33301, before midnight on the 3rd day which begins after the date
            you have signed this contract stating “I hereby cancel this
            transaction (date)( purchaser's signature).”
            <br />
            <br />
            <br />
            Please acknowledge your receipt of this notice by electronically
            signing the form indicated below.
          </p>
          <p className='my-12 sm:my-4 font-semibold text-[16px] sm:text-xs leading-[19px] sm:leading-4 text-ss_old_grey'>
            Acknowledgment of Receipt of Notice
          </p>
          <p>
            I,{' '}
            <input
              value={values.FirstName + ' ' + values.LastName}
              disabled
              className='Custom__Input__Box'
            />{' '}
            hereby acknowledge with my digital signature, receipt of the Notice
            of Right to Cancel. I confirm the fact that I agree and understand
            what I am signing, and acknowledge that I have received a copy of my
            Consumer Credit File Rights.<br></br>
            <br></br>
            <br></br>
            “Digital Signature : In 2000, the U.S Electric Signature in Global
            and National Commerce (ESIGN) Act established electronic records and
            signatures as legally binding, having the same legal effects as
            traditional paper documents and handwritten signatures. Read more at
            the FTC web site: http:/www.ftc.gov/2001/06/esign7.html
          </p>
        </div>
        <div className='flex justify-between my-10 sm:my-4'>
          <p className='ml-[60px] sm:ml-0 font-semibold text-[16px] sm:text-xs leading-[19px] sm:leading-4'>
            Draw Your Signature Here ...
          </p>
          <p className=' mr-[100px] sm:mr-0 font-semibold text-[16px] sm:text-xs leading-[19px] sm:leading-4'>
            Date:
          </p>
        </div>
        <div className='flex justify-between mx-20 sm:mx-0'>
          <div className='p-[20px] sm:p-0 ml-[-24px] sm:ml-0 h-[200px] sm:w-[200px] rounded-[5px] border-[0.2px] border-black'>
            <SignatureCanvas
              penColor='green'
              ref={sigPad}
              canvasProps={{
                width: 200,
                height: 200,
                className: 'sigCanvas',
                elocityfilterweight: 2.9,
                throttle: 24,
              }}
            />
            <button className='__signature__button1 z-50' onClick={clearPad}>
              ❌
            </button>
            <button className='__signature__button2 z-50' onClick={savePad}>
              ✔
            </button>
          </div>

          <div className=' sm:hidden'>
            <input
              value={today.slice(0, 21)}
              type='date-time'
              className=' mr-4  mt-4 h-5'
              disabled
            />
          </div>
          <div className='lg:hidden xl:hidden 2xl:hidden sm:w-20px'>
            {today.slice(0, 15)}
          </div>
        </div>
        <div className=' ml-[740px] sm:ml-[150px] sm:mt-14'>
          {showAlert && <Alert />}
        </div>
        <div className=' w-[200px] sm:w-[80px] ml-[900px] sm:ml-[240px] mt-[40px] sm:mt-8  mb-[48px] sm:mb-4 justify-end'>
          {isImage === false ? (
            <button
              disabled
              className='w-[130px] sm:w-[80px] h-[48px] sm:h-[33px] bg-ss_primary rounded-md opacity-10 sm:rounded-sm font-semibold text-ss_white2 cursor-pointer z-10 sm:text-xs sm:leading-4 '
            >
              Next Step
            </button>
          ) : (
            <button
              onClick={onSubmit}
              className='w-[130px] sm:w-[80px] h-[48px] sm:h-[33px] bg-ss_primary rounded-md sm:rounded-sm font-semibold text-ss_white2 cursor-pointer z-10 sm:text-xs sm:leading-4 '
            >
              Next Step
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Agreement;

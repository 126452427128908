import React from 'react';
import { Navigate } from 'react-router-dom';
import { useFeatureContext } from '../context/FeatureContext';

const Auth = ({ location, children }) => {
  const { pagename } = useFeatureContext();
  if (pagename !== location) {
    return <Navigate to='/contactInfo' />;
  }
  return children;
};

export default Auth;

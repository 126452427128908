import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, NavBar } from '../../../components';
import { useAdminContext } from '../../../context/AdminContext/admincontext';
import { useFeatureContext } from '../../../context/FeatureContext';

const Customer = () => {
  const { customerSubId, customer } = useAdminContext();
  const navigate = useNavigate();

  const { displayAlert, showAlert } = useFeatureContext();
  const authorizedEnv = process.env.REACT_APP_ENVIRONMENT_AUTHORIZED_NET;
  const authorizedName = process.env.REACT_APP_CLINT_KEY_AUTHORIZED_NET;
  const authorizedTranscation =
    process.env.REACT_APP_TRANSACTION_KEY_AUTHORIZED_NET;

  const [updatePopup, setupdatePopup] = useState(false);

  const [card, setCard] = useState('');
  const [expire, setExpire] = useState('');

  const updateSubscription = async () => {
    axios
      .post(authorizedEnv, {
        ARBUpdateSubscriptionRequest: {
          merchantAuthentication: {
            name: authorizedName,
            transactionKey: authorizedTranscation,
          },
          subscriptionId: customerSubId,
          subscription: {
            payment: {
              creditCard: {
                cardNumber: card,
                expirationDate: expire,
              },
            },
          },
        },
      })
      .then((res) => {
        if (res.data.messages.resultCode === 'Error') {
          displayAlert(res.data.messages.message[0].text);
        } else {
          displayAlert(
            res.data.messages.message[0].text + ' Please Sign In Again',
            true
          );
          navigate('/admin');
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setupdatePopup(false);
      });
  };

  const handleUpdate = async () => {
    if (!expire || !card) {
      displayAlert('Please input the card number and expire date properly !');
    } else {
      await updateSubscription();
    }
  };

  const cancelSubs = async () => {
    await axios
      .post(authorizedEnv, {
        ARBCancelSubscriptionRequest: {
          merchantAuthentication: {
            name: authorizedName,
            transactionKey: authorizedTranscation,
          },
          subscriptionId: `${customerSubId}`,
        },
      })
      .then((res) => {
        displayAlert(res.data.messages.message[0].text);
        navigate('/admin');
      })
      .catch((err) => displayAlert(err.message));
  };

  return (
    <div className='flex flex-col items-center justify-center relative  '>
      {showAlert && <Alert />}
      {updatePopup && (
        <div className='fixed__div__centered top-[20%] z-50 bg-yellow-600 text-ss_white p-8'>
          <div
            onClick={() => setupdatePopup(!updatePopup)}
            className=' text-2xl bg-red-500 px-4 cursor-pointer absolute right-0 top-0'
          >
            X
          </div>
          <div className=' flex flex-col justify-center items-center gap-4'>
            <p className=' text-3xl mt-2 mb-4'>Update your Subscription :</p>
            <input
              onChange={(e) => setCard(e.target.value)}
              required
              placeholder='Enter Card Number !'
              type='number'
              className='w-[350px] h-[57px] sm:w-[200px] sm:h-[33px]  text-ss_black text-xl sm:text-lg
               rounded-[5px] p-4'
            />
            <input
              onChange={(e) => setExpire(e.target.value)}
              className='w-[250px] h-[57px] sm:w-[200px] sm:h-[33px] text-ss_black text-xl sm:text-lg
              rounded-[5px] p-4'
              type='month'
            />
            <button
              onClick={handleUpdate}
              className='py-2 px-4 w-[113px] h-[51px] sm:h-auto text-[23px] leading-[34px] bg-ss_primary rounded-[5px] text-ss_white sm:mt-2 z-50'
            >
              Submit
            </button>
          </div>
        </div>
      )}
      <NavBar />
      <p className='font-aulire text-4xl sm:text-2xl text-ss_primary mt-8 '>
        Customer Profile
      </p>
      <div className=' flex flex-row justify-around gap-10 mt-10'>
        {customer ? (
          <div className=' text-xl mt-6 p-8 flex-col justify-center items-center gap-6 bg-slate-300'>
            <p>Profile Id: {customer.profile.customerProfileId}</p>
            <p>
              Name:{' '}
              {customer.profile.paymentProfile.billTo.firstName +
                ' ' +
                customer.profile.paymentProfile.billTo.lastName}
            </p>
            <p>
              Address:{' '}
              {customer.profile.paymentProfile.billTo.address +
                ', ' +
                customer.profile.paymentProfile.billTo.zip}
            </p>
            <p>Subscription Ammount: {customer.amount}</p>
            <p>Started At: {customer.paymentSchedule.startDate}</p>
            <p>Subscription Name: {customer.name}</p>
            <p>Status: {customer.status}</p>
            <p className=' text-blue-400 mt-5'>Transaction history :</p>
            <div>
              {customer.arbTransactions?.map((trans, index) => {
                return (
                  <div key={index}>
                    <p>Trans Id :{trans.transId}</p>
                    <p>Submit time:{trans.submitTimeUTC} </p>
                    <p>Pay Num:{trans.payNum} </p>
                    <p>Attempt Num:{trans.attemptNum} </p>
                    <p>Message: {trans.response}</p>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <p>No Information Available</p>
        )}
        <div className='flex flex-col justify-start gap-4 mt-5'>
          <button
            onClick={() => setupdatePopup(!updatePopup)}
            className='py-2 px-4 w-[113px] h-[51px] sm:h-auto text-[23px] leading-[34px] bg-yellow-600 rounded-[5px] text-ss_white sm:mt-2 z-50'
          >
            Update
          </button>
          <button
            onClick={cancelSubs}
            className='py-2 px-4 w-[113px] h-[51px] sm:h-auto text-[23px] leading-[34px] bg-red-500 rounded-[5px] text-ss_white sm:mt-2 z-50'
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default Customer;

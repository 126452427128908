import { AUTH_SUCCESS, CUSTOMER_LOGIN } from './actions';

const reducer = (state, action) => {
  if (action.type === AUTH_SUCCESS) {
    return {
      ...state,
      token: action.payload.token,
      admin: action.payload.admin,
    };
  } else if (action.type === CUSTOMER_LOGIN) {
    return {
      ...state,
      customerSubId: action.payload.id,
      customer: action.payload.customer,
    };
  }

  throw new Error(`no such action ${action.type}`);
};

export default reducer;

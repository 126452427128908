import Header from '../../components/Header';
import Steps from '../../components/Steps';
import MainImg from '../../components/MainImg';
import NavBar from '../../components/NavBar';
import CompanyLogo from '../../../../../components/CompanyLogo';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../../../context/appContext';
import { useFeatureContext } from '../../../../../context/FeatureContext';
const YourPlan = () => {
  const { premiumPackage } = useAppContext();
  const { updatePageName, setIsLoading } = useFeatureContext();
  let navigate = useNavigate();
  const onSubmit = () => {
    setIsLoading(true);
    updatePageName('agreement');
    navigate('/agreement');
    setIsLoading(false);
  };
  return (
    <div className='flex justify-center items-center sm:overflow-hidden'>
      <div className='flex flex-col box_shadow_universal w-[1170px] sm:w-[auto] mx-[200px] sm:mx-6 mt-[100px] sm:mt-6 mb-[200px] sm:mb-4'>
        <CompanyLogo company={true} />
        <NavBar active='2' />

        <div className='sm:hidden'>
          <Steps stepNo='2' />
        </div>
        <div className='sm:hidden ml-12'>
          <Header head='Your Plan' />
        </div>
        <div className='flex flex-row sm:flex-col'>
          <div className='p-[42px] sm:hidden'>
            <MainImg />
          </div>
          <div className='p-[42px] sm:p-0 text-ss_old_grey sm:mt-6'>
            <p className='  text-[16px] sm:text-xs leading-[19px] sm:leading-4 mb-6 sm:mb-4'>
              You Have Selected The Following Plan
            </p>
            <p className=' mb-6 sm:mb4 ml-[15px] sm:ml-0   sm:text-xs leading-[19px] sm:leading-4'>
              {premiumPackage ? 'VIP Monthly Fee' : 'Base Monthly Fee'}
            </p>

            <p className='mb-[17px] sm:mb-2 ml-[15px] sm:ml-0 text-[16px] sm:text-xs leading-[19px] sm:leading-4 '>
              <span className='YourPlanInfoClass'>✔</span>
              &nbsp;
              {premiumPackage ? '$2599 Enrollment Fee' : '$249 Enrollment Fee '}
            </p>
            <p className='mb-[17px] sm:mb-2 ml-[15px] sm:ml-0 text-[16px] sm:text-xs leading-[19px] sm:leading-4'>
              <span className='YourPlanInfoClass'>✔</span> &nbsp;
              {premiumPackage ? '$529.95 Monthly Fee' : '$149.95 Monthly Fee'}
            </p>
            <p className='mb-[17px] sm:mb-2 ml-[15px] sm:ml-0 text-[16px] sm:text-xs leading-[19px] sm:leading-4'>
              <span className='YourPlanInfoClass'>✔</span> &nbsp;Credit
              Education
            </p>
            <p className='mb-[17px] sm:mb-2 ml-[15px] sm:ml-0 text-[16px] sm:text-xs leading-[19px] sm:leading-4'>
              <span className='YourPlanInfoClass'>✔</span> &nbsp;3 Bureau Audit
              - Analysis
            </p>
            <p className='mb-[17px] sm:mb-2 ml-[15px] sm:ml-0 text-[16px] sm:text-xs leading-[19px] sm:leading-4'>
              <span className='YourPlanInfoClass'>✔</span> &nbsp;24/7 Client
              Portal Access
            </p>
            <p className='mb-[17px] sm:mb-2 ml-[15px] sm:ml-0 text-[16px] sm:text-xs leading-[19px] sm:leading-4'>
              <span className='YourPlanInfoClass'>✔</span> &nbsp;Customer
              Service Support
            </p>
            <p className='mb-[17px] sm:mb-2 ml-[15px] sm:ml-0 text-[16px] sm:text-xs leading-[19px] sm:leading-4'>
              <span className='YourPlanInfoClass'>✔</span> &nbsp;Monthly Updates
            </p>
            <p className='mb-[17px] sm:mb-2 ml-[15px] sm:ml-0 text-[16px] sm:text-xs leading-[19px] sm:leading-4'>
              <span className='YourPlanInfoClass'>✔</span> &nbsp;Activie Credit
              Monitoring Software
            </p>
            <p className='mb-[17px] sm:mb-2 ml-[15px] sm:ml-0 text-[16px] sm:text-xs leading-[19px] sm:leading-4'>
              <span className='YourPlanInfoClass'>✔</span> &nbsp;No Contract
            </p>
            <p className='mb-[17px] sm:mb-2 ml-[15px] sm:ml-0 text-[16px] sm:text-xs leading-[19px] sm:leading-4'>
              <span className='YourPlanInfoClass'>✔</span> &nbsp;Money Back
              Guarantee If No Deletions iin (0 Days)
            </p>
            <p className='mb-[17px] sm:mb-2 ml-[15px] sm:ml-0 text-[16px] sm:text-xs leading-[19px] sm:leading-4'>
              <span className='YourPlanInfoClass'>✔</span>&nbsp;Ultimited
              Disputes
            </p>
            <p
              className={`mb-[17px] sm:mb-2 ml-[15px] sm:ml-0 text-[16px] sm:text-xs leading-[19px] sm:leading-4 ${
                premiumPackage ? 'block' : 'hidden'
              }`}
            >
              <span className='YourPlanInfoClass'>✔</span>&nbsp;White Glove
              Service
            </p>
            <p
              className={`mb-[17px] sm:mb-2 ml-[15px] sm:ml-0 text-[16px] sm:text-xs leading-[19px] sm:leading-4 ${
                premiumPackage ? 'block' : 'hidden'
              }`}
            >
              <span className='YourPlanInfoClass'>✔</span>&nbsp;The Opportunity
              to “Skip The Line”
            </p>
            <p
              className={`mb-[17px] sm:mb-2 ml-[15px] sm:ml-0 text-[16px] sm:text-xs leading-[19px] sm:leading-4 ${
                premiumPackage ? 'block' : 'hidden'
              }`}
            >
              <span className='YourPlanInfoClass'>✔</span>&nbsp;Same Letters,
              Same Results, But You'll Work Directly With Ceo Limited
              Availability
            </p>
          </div>
        </div>
        <div className='w-[200px] sm:w-[80px] ml-[900px] sm:ml-[228px] sm:mt-6 mb-[48px] sm:mb-4 justify-end'>
          <button
            onClick={onSubmit}
            className='w-[130px] sm:w-[80px] h-[48px] sm:h-[33px] bg-ss_primary rounded-md sm:rounded-sm  text-ss_white2 cursor-pointer z-10 sm:text-xs sm:leading-4'
          >
            Next Step
          </button>
        </div>
      </div>
    </div>
  );
};

export default YourPlan;

import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Alert } from '../../../components';
import { useFeatureContext } from '../../../context/FeatureContext';
import PopupPay from './PopupPay';

const SubList = ({ search }) => {
  const [popup, setpopup] = useState(false);
  const [subId, setsubId] = useState('');
  const { displayAlert, showAlert } = useFeatureContext();
  const authorizedEnv = process.env.REACT_APP_ENVIRONMENT_AUTHORIZED_NET;
  const authorizedName = process.env.REACT_APP_CLINT_KEY_AUTHORIZED_NET;
  const authorizedTranscation =
    process.env.REACT_APP_TRANSACTION_KEY_AUTHORIZED_NET;

  const [subs, setsubs] = useState([]);
  const [total, setTotal] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const cancel = async (id) => {
    await axios
      .post(authorizedEnv, {
        ARBCancelSubscriptionRequest: {
          merchantAuthentication: {
            name: authorizedName,
            transactionKey: authorizedTranscation,
          },
          subscriptionId: `${id}`,
        },
      })
      .then((res) => {
        setRefresh((refresh) => !refresh);
        displayAlert(res.data.messages.message[0].text);
      })
      .catch((err) => displayAlert(err.message));
  };
  const handleAllSub = async () => {
    await axios
      .post(authorizedEnv, {
        ARBGetSubscriptionListRequest: {
          merchantAuthentication: {
            name: authorizedName,
            transactionKey: authorizedTranscation,
          },
          searchType: 'subscriptionActive',
          sorting: {
            orderBy: 'id',
            orderDescending: 'false',
          },
          paging: {
            limit: '1000',
            offset: '1',
          },
        },
      })
      .then((response) => {
        // console.log(response);
        if (search === '') {
          setsubs([...response.data.subscriptionDetails]);
          setTotal(response.data.totalNumInResultSet);
        } else {
          setsubs(
            response.data.subscriptionDetails.filter((data) => {
              const name = data.firstName + ' ' + data.lastName;
              return name.toLowerCase().includes(search.toLowerCase());
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDivClick = (id) => {
    setsubId(id);
    setpopup(!popup);
  };

  useEffect(() => {
    handleAllSub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, refresh]);

  return (
    <div className='flex justify-center flex-col items-center overflow-hidden w-[100%] '>
      {showAlert && <Alert />}
      {popup && <PopupPay id={subId} close={setpopup} />}
      <h1 className=' text-2xl mt-6'>Total Subscription : {total} </h1>
      <div className=' flex gap-6 flex-wrap mt-6 justify-center overflow-hidden'>
        {subs.length !== 0
          ? subs.map((e, index) => {
              return (
                <div
                  key={index}
                  className='flex flex-col flex-wrap p-4 w-[400px] bg-slate-300 cursor-pointer hover:bg-slate-200'
                >
                  <p>Id: {e.id}</p>
                  <p> Name : {e.firstName + ' ' + e.lastName}</p>
                  <p>Started at : {e.createTimeStampUTC}</p>
                  <p>Amount: {e.amount}</p>
                  <p>Paid : {e.pastOccurrences} times</p>
                  <p>Status : {e.status}</p>
                  <div className=' relative flex gap-2'>
                    <button
                      onClick={() => handleDivClick(e.id)}
                      className=' px-2 mt-2 bg-green-600 text-white'
                    >
                      View Full Information
                    </button>
                    <button
                      onClick={() => cancel(e.id)}
                      className=' px-2 mt-2 bg-red-600 text-white'
                    >
                      Cancel Subscription
                    </button>
                  </div>
                </div>
              );
            })
          : ' No Subscribers Yet'}
      </div>
    </div>
  );
};

export default SubList;

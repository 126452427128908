import { useState } from 'react';
import { Link } from 'react-router-dom';
import logo1 from '../assets/hero_logo.png';
import logo_fbook from '../assets/icons/facebook.png';
import logo_insta from '../assets/icons/insta.png';
import visa from '../assets/visa.png';
import { Affiliate } from './Affiliate';

const Footer = () => {
  const [popup, setPopup] = useState(false);
  return (
    <footer className='w-screen grid grid-cols-1 place-items-center bg-ss_white sm:mx-[-2px]'>
      <Affiliate trigger={popup} setTrigger={setPopup} />
      <div className='grid grid-flow-col sm:grid-flow-row grid-cols-2 sm:grid-cols-1 place-items-center sm:place-items-start mb-4 gap-[140px] sm:gap-4 p-8 sm:p-4'>
        <div className='sm:w-screen mt-[-80px] sm:mt-0 mr-[-36px] sm:mr-0'>
          <Link to='/'>
            <img
              className=' w-24 h-16 mb-7 sm:ml-[-14px]'
              src={logo1}
              alt='logo'
            />
          </Link>
          <p className=' max-w-[460px] sm:w-10/12 mb-6 sm:mb-2'>
            SS Ultimate Solutions is one of the top credit restoration companies
            in Fort Lauderdale, Florida whose goal is to help customers enhance
            their buying capacity that is affecting their daily lives.
            <br></br>
            <br></br>
            We offer our services at a competitive rate. The extent of our
            services includes Free Credit Monitoring and Analysis.
          </p>
          <a
            type='number '
            href='tel:8773521590'
            className=' max-w-[370px] sm:max-w-fit'
          >
            Phone: (877) 352-1590 <br />
            Address: 515 E Las Olas Boulevard, Suite 120, <br />
            Fort Lauderdale, FL 33301
          </a>
        </div>
        <div className=' sm:w-screen grid grid-flow-col grid-cols-2 place-items-start  gap-[340px] sm:gap-4 mt-10 sm:mt-0'>
          <div className='grid grid-flow-row gap-8 sm:gap-1 mr-[-20px] sm:mr-0'>
            <p className=' font-bold'>Services</p>
            <Link className=' hover:text-ss_primary' to='/pricing'>
              Pricing
            </Link>
            <Link className=' hover:text-ss_primary' to='/credit-monitoring'>
              Credit Monitoring
            </Link>
            <Link className=' hover:text-ss_primary' to='/resources'>
              Resources
            </Link>
            <Link className=' hover:text-ss_primary' to='/contact-us'>
              Contact Us
            </Link>

            <button
              onClick={() => setPopup(!popup)}
              className='text-left hover:text-ss_primary'
            >
              Affiliate Registration
            </button>
            <a
              target='_blank'
              rel='noopener noreferrer'
              className=' hover:text-ss_primary'
              href='https://www.secureclientaccess.com/'
            >
              Affiliate Portal
            </a>
          </div>
          <div className='grid gap-8 sm:gap-1 ml-[-155px] sm:ml-0'>
            <p className=' font-bold'>Policy</p>
            <Link to='/policy' className=' hover:text-ss_primary '>
              Privacy Policy
            </Link>
            <Link to='/refund_cancellation' className=' hover:text-ss_primary '>
              Refund/Cancellation
            </Link>
            <Link to='/shipping_policy' className=' hover:text-ss_primary '>
              Shipping Policy
            </Link>
            <Link to='/termsandcondition' className=' hover:text-ss_primary '>
              Terms and Conditions
            </Link>
            {/* mt-28 */}
            <div className='flex flex-col space-x-6 sm:mt-4'>
              <div className='flex justify-start space-x-6 sm:mt-2 mt-5'>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://www.facebook.com/Ssultimatesolutions/'
                >
                  <img src={logo_fbook} alt='img' width='12px' height='24px' />{' '}
                </a>

                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://instagram.com/ssultimatesolutions?igshid=YmMyMTA2M2Y='
                >
                  <img src={logo_insta} alt='img' width='24px' height='24px' />
                </a>
                <Link to='admin'>
                  <div className=' grid place-items-center cursor-pointer'>
                    <img
                      width='24px'
                      height='24px'
                      className=' bg-ss_black'
                      alt='admin'
                      src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAAB8ElEQVRYhe2WP0tcQRTFrxZp1DTRBf0CgqQJiClNIriFhYkI8RtsJwr+wc5oF/It3IBdgrbpgl3YqCukMEXyCTbZBFEk+7PwPBzX9/Zd19ki4IFhHjNn7jkzc999z+we9/jfABSATeAr8FetAmwAA50WnwXqZOM3MNNJ8YaEPgDjQI/aM+Cj5v4Br2KLF4KdL7fgrYrzC+iPaWAz2bmDuyPum5gG9hV03MF9Lm4lpoE/Ctrr4PaJW/fE7nZ6OHfyzMy6Ei8xDRyrH3VwE873mAa21S84uIvqd52x8wE8BH7qbldb8NbEqQGPohlQ8KdBMu4o23vVXgC7QSGajioemHjCZe3PQq2T4lPAcYpoHfgCrKcde0K6i3AX8DYQPADmgRGgJ4U/BxSyDHBZ1uduY+CdYpwCJSDzzZF4A6gCReB9YLyssao4+SaAl4G4pwwXJJCHanhKWcEeAD+0oJTr9mpdMTC9BAyqLWsMYNIT6HVw596CZcGxL6XMrWhuyxNoW+R5p3AzBjW+B3zW81AzqdXOxtR/8hhogYY5P0zXAJzIZN8t15W17safE1d/TPlX0C6AySAJV3TsQxI/cydhm+IDwGFKPjTjW5Ij7ux2YsLMHpvZkZkVzawczJU1dmRmw2aWW1faArFLcQRD1ww04wLyQT1CiAlNqQAAAABJRU5ErkJggg=='
                    />
                  </div>
                </Link>
              </div>
            </div>
            <div className=' bg-transparent sm:w-36 sm:h-16 sm:my-2 ml-[-5px]'>
              <img width='200px' height='100px' src={visa} alt='visa' />
            </div>
          </div>
        </div>
      </div>
      <p> © 2022 SS Ultimate Solutions. All rights reserved.</p>
    </footer>
  );
};

export default Footer;

import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import PopUp from '../../components/PopUp';
const CreditMonitoring = () => {
  //! popup
  const [popup, setPopup] = useState(true);
  return (
    <div className=' overflow-hidden'>
      <Outlet />
      <PopUp trigger={popup} setTrigger={setPopup} />
    </div>
  );
};

export default CreditMonitoring;

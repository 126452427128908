import { Footer, NavBar } from '../../components';

const Terms = () => {
  return (
    <div className=' flex flex-col justify-center items-center overflow-hidden'>
      <NavBar pagename='home' />

      <div className='flex flex-col sm:justify-start sm:items-start justify-center items-center my-[64px] sm:my-6'>
        <div>
          <p className=' text-[32px] leading-[48px] sm:text-[16px] sm:leading-[24pxpx] font-[aulire] pb-6 sm:pb-4'>
            Terms and Conditions
          </p>

          <p className='text-[16px] sm:text-[11px] sm:leading-[16px] leading-[24px] w-[1070px] sm:w-[300px] pb-20 sm:pb-8'>
            <strong>1. BINDING EFFECT.</strong>&nbsp;This is a binding agreement
            between you and ‘https://www.ssultimatecreditrepair.com/’ (“us”,
            “we”, “Company”). By using the Internet site located at ‘. .’ (the
            “Site”), you agree to abide by these Terms of Use. If at any time
            you find these Terms of Use unacceptable, you must immediately leave
            the Site and cease all use of it.
            <br></br>
            <br></br>
            <strong>2. PRIVACY POLICY.</strong>&nbsp;We respect your privacy and
            permit you to control the treatment of your personal information. A
            complete statement of our current privacy policy can be found
            by&nbsp;
            <a
              className=' text-ss_primary'
              href='https://www.ssultimatecreditrepair.com/policy/'
              rel='noreferrer'
              target='_blank'>
              clicking here
            </a>
            . Our privacy policy is expressly incorporated into this Agreement
            by this reference.
            <br></br>
            <br></br>
            <strong>3. GOVERNING LAW.</strong>&nbsp;These Terms of Use shall be
            construed in accordance with and governed by the laws of California
            and the United States, without reference to rules regarding
            conflicts of law. This Site is intended for use by individuals based
            in the United States of America.
            <br></br>
            <br></br>
            <strong>4. MINIMUM AGE.</strong>&nbsp;You must be at least 18 years
            old to access and participate on this site. You guarantee and
            warrant you are at least 18 years old and are able to enter into
            this Agreement from a legal perspective.
            <br></br>
            <br></br>
            <strong>5. EBOOK SIGNUPS AND MAILINGS.</strong>&nbsp;You have the
            option, but not obligation, to sign up and receive a free eBook from
            us. Should you do so, you are agreeing to receive further emailings
            from us of a commercial nature.
            <br></br>
            <br></br>
            <strong>6. EMAIL COMMUNICATIONS.</strong>&nbsp;When you contact us,
            you expressly consent and agree to receive email responses from us.
            These email communications may be commercial or non-commercial in
            nature. Non-commercial emails may include, but are not limited to,
            administrative issues and announcements of changes to these Terms,
            the Privacy Policy or other site documentation.
            <br></br>
            <br></br>
            <strong>7. USE OF SOFTWARE.</strong>&nbsp;Company may make certain
            software available to you from the Site. If you download software
            from the Site, the software, including all files and images
            contained in or generated by the software, and accompanying data
            (collectively, “Software”) are deemed to be licensed to you by
            Company, for your personal, noncommercial, home use only. Company
            does not transfer either the title or the intellectual property
            rights to the Software, and Company retains full and complete title
            to the Software as well as all intellectual property rights therein.
            You may not sell, redistribute, or reproduce the Software, nor may
            you decompile, reverse-engineer, disassemble, or otherwise convert
            the Software to a human-perceivable form. All trademarks and logos
            are owned by Company or its licensors and you may not copy or use
            them in any manner.
            <br></br>
            <br></br>
            <strong>8. USER CONTENT.</strong>&nbsp;By posting, downloading,
            displaying, performing, transmitting, or otherwise distributing
            information or other content (“User Content”) to the site, you are
            granting Company, its affiliates, officers, directors, employees,
            consultants, agents, and representatives a permanent, non-exclusive
            license to use User Content in connection with the operation of the
            Internet businesses of Company, its affiliates, officers, directors,
            employees, consultants, agents, and representatives, including
            without limitation, a right to copy, distribute, transmit, publicly
            display, publicly perform, reproduce, edit, translate, and reformat
            User Content. You will not be compensated for any User Content. You
            agree that Company may publish or otherwise disclose your name in
            connection with your User Content. By posting User Content on the
            site, you warrant and represent that you own the rights to the User
            Content or are otherwise authorized to post, distribute, display,
            perform, transmit, or otherwise distribute User Content.
            <br></br>
            <br></br>
            <strong>9. COMPLIANCE WITH INTELLECTUAL PROPERTY LAWS.</strong>
            &nbsp;When accessing the site, you agree to respect the intellectual
            property rights of others. Your use of the site is at all times
            governed by and subject to laws regarding copyright ownership and
            use of intellectual property. You agree not to upload, download,
            display, perform, transmit, or otherwise distribute any information
            or content (collectively, “Content”) in violation of any third
            party’s copyrights, trademarks, or other intellectual property or
            proprietary rights. You agree to abide by laws regarding copyright
            ownership and use of intellectual property, and you shall be solely
            responsible for any violations of any relevant laws and for any
            infringements of third party rights caused by any Content you
            provide or transmit, or that is provided or transmitted using your
            User ID. The burden of proving that any Content does not violate any
            laws or third party rights rests solely with you. All Digital
            Millennium Copyright Act matters are processed pursuant to our DMCA
            Policy, which you may access via the DMCA link at the bottom of the
            page.
            <br></br>
            <br></br>
            <strong>10. INAPPROPRIATE CONTENT.</strong>&nbsp;You agree not to
            upload, download, display, perform, transmit, or otherwise
            distribute any Content that (a) is libelous, defamatory, obscene,
            pornographic, abusive, or threatening; (b) advocates or encourages
            conduct that could constitute a criminal offense, give rise to civil
            liability, or otherwise violate any applicable local, state,
            national, or foreign law or regulation; (c) advertises or otherwise
            solicits funds or is a solicitation for goods or services; or (d)
            provides medical advice to other users. Company reserves the right
            to terminate your receipt, transmission, or other distribution of
            any such material using the site, and, if applicable, to delete any
            such material from its servers. Company intends to cooperate fully
            with any law enforcement officials or agencies in the investigation
            of any violation of these Terms or of any applicable laws.
            <br></br>
            <br></br>
            <strong>11. COMPLIANCE WITH INTELLECTUAL PROPERTY LAWS.</strong>
            &nbsp;When accessing the Site, you agree to obey the law and to
            respect the intellectual property rights of others. Your use of the
            Site is at all times governed by and subject to laws regarding
            copyright ownership and use of intellectual property. You agree not
            to upload, download, display, perform, transmit, or otherwise
            distribute any information or content (collectively, “Content”) in
            violation of any third party’s copyrights, trademarks, or other
            intellectual property or proprietary rights. You agree to abide by
            laws regarding copyright ownership and use of intellectual property,
            and you shall be solely responsible for any violations of any
            relevant laws and for any infringements of third party rights caused
            by any Content you provide or transmit, or that is provided or
            transmitted using your account. The burden of proving that any
            Content does not violate any laws or third party rights rests solely
            with you.
            <br></br>
            <br></br>
            <strong>12. NO WARRANTIES.</strong>&nbsp;WE HEREBY DISCLAIM ALL
            WARRANTIES. WE ARE MAKING THE SITE AVAILABLE “AS IS” WITHOUT
            WARRANTY OF ANY KIND. YOU ASSUME THE RISK OF ANY AND ALL DAMAGE OR
            LOSS FROM USE OF, OR INABILITY TO USE, THE SITE OR THE SERVICE. TO
            THE MAXIMUM EXTENT PERMITTED BY LAW, WE EXPRESSLY DISCLAIM ANY AND
            ALL WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE SITE, INCLUDING,
            BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. WE DO NOT
            WARRANT THAT THE SITE OR THE SERVICE WILL MEET YOUR REQUIREMENTS OR
            THAT THE OPERATION OF THE SITE OR THE SERVICE WILL BE UNINTERRUPTED
            OR ERROR-FREE.
            <br></br>
            <br></br>
            <strong>13. LIMITED LIABILITY.</strong>&nbsp;OUR LIABILITY TO YOU IS
            LIMITED. TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL
            WE BE LIABLE FOR DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO,
            SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, LOST PROFITS, OR LOST
            DATA, REGARDLESS OF THE FORESEEABILITY OF THOSE DAMAGES) ARISING OUT
            OF OR IN CONNECTION WITH YOUR USE OF THE SITE OR ANY OTHER MATERIALS
            OR SERVICES PROVIDED TO YOU BY US. This limitation shall apply
            regardless of whether the damages arise out of breach of contract,
            tort, or any other legal theory or form of action.
            <br></br>
            <br></br>
            <strong>14. AFFILIATED SITES.</strong>&nbsp;We have no control over
            and no liability for any third party websites or materials. We work
            with a number of partners whose Internet sites may be linked with
            the Site. Because we have no control over the content and
            performance of these partner and affiliate sites, we make no
            guarantees about the accuracy, currency, content, or quality of the
            information provided by such sites, and we assume no responsibility
            for unintended, objectionable, inaccurate, misleading, or unlawful
            content that may reside on those sites. Similarly, from time to time
            in connection with your use of the Site, you may have access to
            content items (including, but not limited to, websites) that are
            owned by third parties. You acknowledge and agree that we make no
            guarantees about, and assumes no responsibility for, the accuracy,
            currency, content, or quality of this third party content, and that,
            unless expressly provided otherwise, these Terms of Use shall govern
            your use of any and all third party content.
            <br></br>
            <strong>15. PROHIBITED USES.</strong>&nbsp;We impose certain
            restrictions on your permissible use of the Site. You are prohibited
            from violating or attempting to violate any security features of the
            Site, including, without limitation, (a) accessing content or data
            not intended for you, or logging onto a server or account that you
            are not authorized to access; (b) attempting to probe, scan, or test
            the vulnerability of the Site, or any associated system or network,
            or to breach security or authentication measures without proper
            authorization; (c) interfering or attempting to interfere with
            service to any user, host, or network, including, without
            limitation, by means of submitting a virus to the Site, overloading,
            “flooding,” “spamming,” “mail bombing,” “crashing” or instituting a
            “DDOS” attack on the Site; (d) using the Site to send unsolicited
            e-mail, including, without limitation, promotions, or advertisements
            for products or services; (e) forging any TCP/IP packet header or
            any part of the header information in any e-mail or in any posting
            using the Site; or (f) attempting to modify, reverse-engineer,
            decompile, disassemble, or otherwise reduce or attempt to reduce to
            a human-perceivable form any of the source code used by us in
            providing the Site. Any violation of system or network security may
            subject you to civil and/or criminal liability.
            <br></br>
            <br></br>
            <strong>16. INDEMNITY.</strong>&nbsp;You agree to indemnify us for
            certain of your acts and omissions. You agree to indemnify, defend,
            and hold harmless Company, its affiliates, officers, directors,
            employees, consultants, agents, and representatives from any and all
            third party claims, losses, liability, damages, and/or costs
            (including reasonable attorney fees and costs) arising from your
            access to or use of the Site, your violation of these Terms of Use,
            or your infringement, or infringement by any other user of your
            account, of any intellectual property or other right of any person
            or entity. We will notify you promptly of any such claim, loss,
            liability, or demand, and will provide you with reasonable
            assistance, at your expense, in defending any such claim, loss,
            liability, damage, or cost.
            <br></br>
            <br></br>
            <strong>17. COPYRIGHT.</strong>&nbsp;All contents of Site or Service
            are: Copyright © 2021, SS Ultimate Solutions. All rights reserved.
            <br></br>
            <strong>18. SEVERABILITY; WAIVER.</strong>&nbsp;If, for whatever
            reason, a court of competent jurisdiction finds any term or
            condition in these Terms of Use to be unenforceable, all other terms
            and conditions will remain unaffected and in full force and effect.
            No waiver of any breach of any provision of these Terms of Use shall
            constitute a waiver of any prior, concurrent, or subsequent breach
            of the same or any other provisions hereof, and no waiver shall be
            effective unless made in writing and signed by an authorized
            representative of the waiving party.
            <br></br>
            <br></br>
            <strong>19. NO LICENSE.</strong>&nbsp;Nothing contained on the Site
            should be understood as granting you a license to use any of the
            trademarks, service marks, or logos owned by us or by any third
            party.
            <br></br>
            <br></br>
            <strong>20. UNITED STATES USE ONLY.</strong>&nbsp;The Site is
            controlled and operated by Company from its offices in the State of
            California. The domain of the website is registered in the United
            States and the Site is hosted in the United States. The intended
            audience for this site consists of individuals in the United States
            only. Company makes no representation that any of the materials or
            the services to which you have been given access are available or
            appropriate for use in other locations. Your use of or access to the
            Site should not be construed as Company’s purposefully availing
            itself of the benefits or privilege of doing business in any state
            or jurisdiction other than California and the United States.
            <br></br>
            <br></br>
            <strong>21. AMENDMENTS.</strong>&nbsp;Company reserves the right to
            amend these Terms. Should Company seek to make such an amendment,
            which we determine is material in our sole discretion, we shall:
            <br></br>
            <br></br>
            (a) Provide you notice by email of said change 15 days prior to the
            change going into force, and<br></br>(b) Publish on the home page
            the fact an amendment will be made.
            <br></br>
            <br></br>
            Should a court of competent jurisdiction rule this Amendment
            provision invalid, then this Amendment clause shall be terminated as
            part of this agreement. All amendments to the Terms shall be forward
            looking.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Terms;

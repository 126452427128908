import { Link } from 'react-router-dom';
import { Footer, NavBar } from '../../components';

const Refund = () => {
  return (
    <div className=' flex flex-col justify-center items-center overflow-hidden'>
      <NavBar pagename='home' />

      <div className='flex flex-col sm:justify-start sm:items-start justify-center items-center my-[64px] sm:my-6'>
        <div>
          <p className=' text-[32px] leading-[48px] sm:text-[16px] sm:leading-[24pxpx] font-[aulire] pb-6 sm:pb-4'>
            Refund/Cancellation Policy
          </p>
          <p className='text-[16px] sm:text-[11px] sm:leading-[16px] leading-[24px] w-[1070px] sm:w-[300px] pb-20 sm:pb-8'>
            <b>You are entitled to a 100% refund on all monthly payments if:</b>
            <br />
            <br />– We cannot remove any negative account in 90 days.
            <br></br>– You have had six months from the time you retain our
            services.
            <br></br>– You have at least four negatives on the credit report at
            the time of sign-up.
            <br></br>– You have not used a credit-consulting agency nor
            attempted to repair your credit two years previous to signing up for
            our services.
            <br></br>– You agree to send updated reports from the three credit
            bureaus to us within 5 days of receipt. (Clients should receive
            updated credit reports every 30-45 days. It is the client’s
            responsibility to make us aware if updated reports have not been
            received).
            <br></br>
            -You can no longer cancel once we start working on your account.
            <br></br>
            -If you decided to cancel, It should be after receiving the result.
            <br></br>
            <br></br>
            -You can no longer cancel once we start working on your account.
            <br></br>
            -If you decided to cancel, It should be after receiving the result.
          </p>
          <p className=' sm:text-[16px] sm:leading-[24pxpx] text-[32px] leading-[48px] font-[aulire] pb-6'>
            Contacting us
          </p>
          <p className='text-[16px] leading-[24px] w-[1055px] sm:w-[300px] pb-20 sm:pb-6 sm:text-[11px] sm:leading-[16px]'>
            If you would like to contact us concerning any matter relating to
            this Refund Policy, you may do so via the&nbsp;
            <Link
              className=' text-ss_primary'
              to='/contact-us'
              target='_blank'
              rel='nofollow noopener'>
              contact form
            </Link>{' '}
            , send an email to&nbsp;
            <a
              className=' text-ss_primary'
              href='mailto:sylvester@ssultimatesolutionsllc.com'>
              sylvester@ssultimatesolutionsllc.com
            </a>
            &nbsp;or write a letter to 515 E Las Olas Boulevard, Suite 120, Fort
            Lauderdale, FL 33301
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Refund;

import React, { useState, useEffect } from 'react';
import { NavBar } from '../../components';
import axios from 'axios';
import Register from './Register';
import ViewAdmin from './ViewAdmin';
import AddEmail from './AddEmail';
import ViewEmails from './ViewEmails';
import { useFeatureContext } from '../../context/FeatureContext';
import { useAdminContext } from '../../context/AdminContext/admincontext';
import { Link } from 'react-router-dom';

const AdminDashboard = () => {
  const apiUrl = process.env.REACT_APP_API_ENVIRONMENT_URL;

  const { setIsLoading, updatePageName } = useFeatureContext();
  const { token, admin, AdminLogout } = useAdminContext();
  const [searchField, setsearchField] = useState(null);
  const searchChange = (e) => {
    setsearchField(e.target.value);
  };

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [dbData, setdbData] = useState();
  const getDbData = () => {
    setIsLoading(true);

    axios
      .get(`${apiUrl}/getCustomers`, config)
      .then((res) => {
        if (searchField === null) {
          setdbData(res.data.data);
          setIsLoading(false);
        } else {
          setdbData(
            res.data.data.filter((data) => {
              return data.customer_name
                .toLowerCase()
                .includes(searchField.toLowerCase());
            })
          );
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const getAgreement = async (id) => {
    setIsLoading(true);
    await axios
      .get(`${apiUrl}/getAgreement/${id}`, config)
      .catch(() => setIsLoading(false))
      .finally(() => setIsLoading(false));
    setIsLoading(false);
  };

  const [refresh, setrefresh] = useState(false);

  const deleteCustomers = (id) => {
    axios
      .get(`${apiUrl}/deleteCustomers/${id}`, config)
      .then(() => {
        setrefresh(!refresh);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //! fetching customer info

  useEffect(() => {
    getDbData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchField, refresh]);

  const getPrimary = (e) => {
    const val = e.target.value;
    getAgreement(val);
  };
  const getPrimaryDelete = (e) => {
    const val = e.target.value;
    deleteCustomers(val);
  };
  const [popup, setPopup] = useState(false);
  const [popupView, setPopupView] = useState(false);
  const [popup1, setPopup1] = useState(false);
  const [popupView1, setPopupView1] = useState(false);

  const onLogOut = async () => {
    await AdminLogout();
    updatePageName('home');
    window.location.replace('/admin');
  };

  return (
    <div className='grid place-items-center '>
      <NavBar />
      <div className='grid place-items-center'>
        <h1 className=' text-4xl sm:text-xl leading-10 font-aulire mt-10 sm:mt-4 text-ss_primary '>
          SS Ultimate Solutions
        </h1>
        <h1 className=' text-4xl sm:text-xl leading-10 font-aulire mb-10 sm:mb-4 mt-4  text-ss_primary '>
          Admin Dashboard
        </h1>
        {admin.adminType === 'super' ? (
          <div className='grid place-items-center'>
            <button
              onClick={() => setPopup(!popup)}
              className='bg-gray-800 p-1 w-[175px] sm:w-auto rounded-md text-gray-200 ml-[935px] sm:ml-0 text-[12px] mt-[-180px] sm:mt-0 mb-4'
            >
              Create New Admin
            </button>

            <button
              onClick={() => setPopupView(!popupView)}
              className='bg-gray-800 p-1 w-[175px] sm:w-auto rounded-md text-gray-200 ml-[935px] sm:ml-0 text-[12px] mt-[-100px] sm:mt-[0px]'
            >
              View Admins
            </button>

            <button
              onClick={() => setPopup1(!popup1)}
              className='bg-gray-800 p-1 w-[175px] sm:w-auto rounded-md text-gray-200 ml-[935px] sm:ml-0 text-[12px] mt-[-20px] sm:mt-4 mb-4'
            >
              Add an Email
            </button>

            <button
              onClick={() => setPopupView1(!popupView1)}
              className='bg-gray-800 p-1 w-[175px] sm:w-auto rounded-md text-gray-200 ml-[935px] sm:ml-0 text-[12px] sm:mt-[0] mb-4'
            >
              View Emails
            </button>
            <Link
              to='/adminPay'
              className='bg-gray-800 p-1 w-[175px] sm:w-auto rounded-md text-gray-200 ml-[935px] sm:ml-0 text-[12px] sm:mt-[0] mb-4 flex justify-center'
            >
              View Transactions
            </Link>
          </div>
        ) : (
          ''
        )}

        <button
          onClick={onLogOut}
          className='bg-gray-800 p-1 w-[175px] sm:w-auto rounded-md text-gray-200 ml-[935px] sm:ml-0 text-[12px] sm:mt-[0]'
        >
          Log out
        </button>

        {admin.adminType === 'super' ? (
          <div>
            <Register trigger={popup} setTrigger={setPopup} />
            <ViewAdmin trigger={popupView} setTrigger={setPopupView} />
            <AddEmail trigger={popup1} setTrigger={setPopup1} />
            <ViewEmails trigger={popupView1} setTrigger={setPopupView1} />
          </div>
        ) : (
          ''
        )}

        <div className='sm:w-[350px] sm:place-items-center relative overflow-x-auto p-6 sm:rounded-lg'>
          <div className='p-4'>
            <label html-for='table-search' className='sr-only'>
              Search
            </label>
            <div className='relative mt-1'>
              <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                <svg
                  className='w-5 h-5 text-gray-500 dark:text-gray-400'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                    clipRule='evenodd'
                  ></path>
                </svg>
              </div>
              <input
                onChange={searchChange}
                type='text'
                id='table-search'
                className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                placeholder='Search for customers'
              />
            </div>
          </div>
          <table className='w-full sm:w-[300px] text-sm sm:text-xs text-left text-gray-500 dark:text-gray-400'>
            <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
              <tr className=' sm:text-xs'>
                <th scope='col' className='px-6 py-3 sm:px-1  sm:py-1'>
                  Customer Id
                </th>
                <th scope='col' className='px-6 py-3 sm:px-1  sm:py-1'>
                  Customer Name
                </th>
                <th scope='col' className='px-6 py-3sm:px-1  sm:py-1 '>
                  Email
                </th>
                <th scope='col' className='px-6 py-3sm:px-1  sm:py-1 '>
                  Phone
                </th>
                <th scope='col' className='px-6 py-3sm:px-1  sm:py-1 '>
                  Address
                </th>
                <th scope='col' className='px-6 py-3sm:px-1  sm:py-1 '>
                  Package
                </th>

                <th scope='col' className='px-6 py-3sm:px-1  sm:py-1'>
                  Sign Up Date
                </th>
                <th scope='col' className='px-6 py-3sm:px-1  sm:py-1'>
                  Agreement Form
                </th>
                {admin.adminType === 'super' ? (
                  <th scope='col' className='px-6 py-3sm:px-1  sm:py-1'>
                    Delete
                  </th>
                ) : (
                  ''
                )}
              </tr>
            </thead>
            {dbData?.map((value, index) => {
              return (
                <tbody key={index}>
                  <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600'>
                    <th
                      scope='row'
                      className='px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap'
                    >
                      {value.id}
                    </th>
                    <td className='px-6 py-4'>{value.customer_name}</td>
                    <td className='px-6 py-4 '>{value.customer_email}</td>
                    <td className='px-6 py-4 '>{value.customer_phone}</td>
                    <td className='px-6 py-4 '>{value.customer_address}</td>
                    <td className='px-6 py-4 '>{value.package}</td>
                    <td className='px-6 py-4'>
                      {value.created_at.slice(0, 10)}
                    </td>
                    <td className='px-6 py-4 text-center'>
                      <button
                        value={value.id}
                        onClick={getPrimary}
                        className='font-medium text-blue-600 dark:text-blue-500 hover:underline'
                      >
                        View
                      </button>
                    </td>
                    {admin.adminType === 'super' ? (
                      <td className='px-6 py-4 text-center'>
                        <button
                          value={value.id}
                          onClick={getPrimaryDelete}
                          className='font-medium text-red-600 dark:text-red-500 hover:underline'
                        >
                          delete
                        </button>
                      </td>
                    ) : (
                      ''
                    )}
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
        {/* <div className=' my-4'>
          <button
            href='#'
            className='inline-flex items-center py-2 px-4 mr-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
          >
            <svg
              className='mr-2 w-5 h-5'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                d='M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z'
                clipRule='evenodd'
              ></path>
            </svg>
            Previous
          </button>
          <button
            href='#'
            className='inline-flex items-center py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
          >
            Next
            <svg
              className='ml-2 w-5 h-5'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                d='M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z'
                clipRule='evenodd'
              ></path>
            </svg>
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default AdminDashboard;

import React, { useState } from 'react';
import axios from 'axios';
import { useAdminContext } from '../../context/AdminContext/admincontext';
import { useFeatureContext } from '../../context/FeatureContext';
import { Alert } from '../../components';

const data = {
  name: '',
  email: '',
};
const AddEmail = (props) => {
  const apiUrl = process.env.REACT_APP_API_ENVIRONMENT_URL;
  const { token } = useAdminContext();
  const { showAlert, displayAlert } = useFeatureContext();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [values, setValues] = useState(data);
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const createAdmin = (e) => {
    e.preventDefault();
    axios
      .post(`${apiUrl}/addEmails`, values, config)
      .then((res) => {
        displayAlert(res.data.message, true);
        setTimeout(() => {
          props.setTrigger(false);
        }, 2000);
      })
      .catch((err) => {
        displayAlert(err.response.data.message, false);
      });
  };
  return props.trigger ? (
    <div className='fixed top-[20%] left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full justify-center items-center flex'>
      {showAlert && <Alert />}
      <div className='relative w-full h-full max-w-md p-4 md:h-auto'>
        <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
          <button
            onClick={() => props.setTrigger(false)}
            type='button'
            className='absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white'
          >
            <svg
              className='w-5 h-5'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                clipRule='evenodd'
              ></path>
            </svg>
          </button>
          <div className='px-6 py-6 lg:px-8'>
            <h3 className='mb-4 text-xl font-medium text-gray-900 dark:text-white'>
              Add New Email
            </h3>
            <form onSubmit={createAdmin} className='space-y-6'>
              <div>
                <label
                  htmlFor='name'
                  className='block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                >
                  Name :
                </label>
                <input
                  type='text'
                  name='name'
                  onChange={handleChange}
                  className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                  required
                />
              </div>
              <div>
                <label
                  htmlFor='password'
                  className='block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                >
                  Email :
                </label>
                <input
                  type='email'
                  name='email'
                  onChange={handleChange}
                  className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                  required
                />
              </div>
              <div className='flex justify-center'>
                <div className='flex items-center h-5'>
                  <button
                    type='submit'
                    className='w-full mt-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ''
  );
};

export default AddEmail;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import image7 from '../../assets/pricing.webp';

import { NavBar, Footer } from '../../components';
import { useAppContext } from '../../context/appContext';

const Pricing = () => {
  const navigate = useNavigate();
  const { premiumPackageUpdate } = useAppContext();

  const packageUpdateFalse = () => {
    premiumPackageUpdate(false);
    navigate('/contactInfo');
  };
  const packageUpdateTrue = () => {
    premiumPackageUpdate(true);
    navigate('/contactInfo');
  };
  return (
    <div className='grid place-items-center overflow-hidden'>
      <NavBar pagename='pricing' />

      {/*//! headline */}
      <div className='w-screen grid place-items-center bg-ss_white h-[492px] sm:h-[146px] mt-[88px] sm:mt-[30px]'>
        <div className='sm:w-[320px] h-[492px] sm:h-[auto] grid grid-cols-2 bg-ss_white  place-items-center gap-8 '>
          <div className='grid place-items-center sm:w-screen ml-[-160px] sm:ml-0'>
            <p className=' font-aulire text-[45px] leading-[68px] sm:leading-6 sm:text-base  my-4 sm:my-1'>
              <span>Pick the plan</span> <br />
              <span>That is right for you</span>
            </p>
            <p className='font-aulire w-[521px] sm:w-[155px] text-[23px] sm:text-[11px] leading-[34px] sm:leading-[16px] text-ss_grey mt-8 sm:mt-4 ml-[75px] sm:ml-0'>
              SS Ultimate Solution Offers Resonable Price, So That Everyone can
              Afford Us.
            </p>
          </div>
          <div className='grid grid-flow-col mt-[-28px] sm:mt-[5px] mr-[-200px] sm:mr-0'>
            <img src={image7} alt='ss' width='535px' height='500px' />
          </div>
        </div>
      </div>

      {/* //! cards */}
      <div className='grid place-items-start mt-[80px] sm:mt-6 mb-20 sm:mb-8 '>
        <p className=' text-[32px] leading-[48px] mb-[153px] sm:mb-4 sm:text-base font-aulire'>
          our packages
        </p>
        <div className='sm:w-[273px] grid grid-flow-col sm:grid-flow-row grid-cols-1 place-items-center gap-[106px] sm:gap-6'>
          <div className='flex flex-col justify-center items-center py-8 px-8 sm:py-4 gap-12 sm:gap-[10px] rounded-[10px] bg-ss_white w-[530px] h-[961px] sm:w-[273px] sm:h-[550px]'>
            <div className='flex flex-col justify-center items-center py-8 sm:py-4 sm:px-8 px-24 w-[441px] sm:w-[206px] h-[242px] sm:h-[115px] bg-ss_white2 gap-[10px] sm:gap-[10px] text-ss_grey mt-6 sm:mt-[12px]'>
              <p className='text-[23px] sm:text-[11px] leading-[34px] sm:leading-[16px]'>
                Basic
              </p>
              <p className='text-[64px] leading-[96px] sm:text-[23px] sm:leading-[34px]'>
                $149.95
              </p>
              <p className='text-[16px] leading-[24px] sm:text-[11px] sm:leading-[16px]'>
                Per Member, Per Month
              </p>
            </div>
            <div className='flex flex-col gap-[10px] sm:mt-4 text-[23px] sm:text-[11px] leading-[34px] sm:leading-[16px] text-ss_grey'>
              <p className='mb-3'>Base Monthly Fee</p>
              <p>
                <span className=' w-[24px] h-[24px] bg-ss_primary text-ss_white rounded px-1 mr-4'>
                  ✔
                </span>
                $249 Enrollment Fee
              </p>
              <p>
                <span className=' w-[24px] h-[24px] bg-ss_primary text-ss_white rounded px-1 mr-4'>
                  ✔
                </span>
                $149.95 Monthly Fee
              </p>
              <p>
                <span className=' w-[24px] h-[24px] bg-ss_primary text-ss_white rounded px-1 mr-4'>
                  ✔
                </span>
                Credit Education
              </p>
              <p>
                <span className=' w-[24px] h-[24px] bg-ss_primary text-ss_white rounded px-1 mr-4'>
                  ✔
                </span>
                3 Bureau Audit - Analysis
              </p>
              <p>
                <span className=' w-[24px] h-[24px] bg-ss_primary text-ss_white rounded px-1 mr-4'>
                  ✔
                </span>
                24/7 Client Portal Access
              </p>
              <p>
                <span className=' w-[24px] h-[24px] bg-ss_primary text-ss_white rounded px-1 mr-4'>
                  ✔
                </span>
                Customer Service Support
              </p>
              <p>
                <span className=' w-[24px] h-[24px] bg-ss_primary text-ss_white rounded px-1 mr-4'>
                  ✔
                </span>
                Monthly Updates
              </p>
              <p>
                <span className=' w-[24px] h-[24px] bg-ss_primary text-ss_white rounded px-1 mr-4'>
                  ✔
                </span>
                Activie Credit Monitoring Software
              </p>
              <p>
                <span className=' w-[24px] h-[24px] bg-ss_primary text-ss_white rounded px-1 mr-4'>
                  ✔
                </span>
                No Contract
              </p>
              <p>
                <span className=' w-[24px] h-[24px] bg-ss_primary text-ss_white rounded px-1 mr-4'>
                  ✔
                </span>
                Ultimited Disputes
              </p>
              <div className='flex justify-center items-center mt-12 sm:mt-4'>
                <button
                  onClick={packageUpdateFalse}
                  className='w-[350px] h-[47px] sm:w-[168px] sm:h-[33px] py-4 px-8 sm:py-2 sm:px-4 text-[23px] sm:text-[11px] leading-[16px]  bg-ss_primary rounded-sm text-ss_white'
                >
                  Repair My Credit Today !
                </button>
              </div>
            </div>
          </div>

          {/* //2nd card starts here */}

          <div className='flex flex-col justify-center items-center py-8 px-8 sm:py-4 gap-12 sm:gap-[10px] rounded-[10px] bg-ss_white w-[530px] h-[1165px] sm:w-[273px] sm:h-[658px] mt-[-200px] sm:mt-6'>
            <div className='flex flex-col justify-center items-center py-8 sm:py-4 sm:px-8 px-24 w-[441px] sm:w-[206px] h-[242px] sm:h-[115px] bg-ss_white2 gap-[10px] sm:gap-[10px] text-ss_grey mt-6 sm:mt-[12px]'>
              <p className='text-[23px] sm:text-[11px] leading-[34px] sm:leading-[16px]'>
                VIP Platinum
              </p>
              <p className='text-[64px] leading-[96px] sm:text-[23px] sm:leading-[34px]'>
                $529.95
              </p>
              <p className='text-[16px] leading-[24px] sm:text-[11px] sm:leading-[16px]'>
                Per Member, Per Month
              </p>
            </div>
            <div className='flex flex-col gap-[10px] sm:mt-4 text-[23px] sm:text-[11px] leading-[34px] sm:leading-[16px] text-ss_grey'>
              <p className='mb-3'>VIP Monthly Fee</p>
              <p>
                <span className=' w-[24px] h-[24px] bg-ss_primary text-ss_white rounded px-1 mr-4'>
                  ✔
                </span>
                $2599 Enrollment Fee
              </p>
              <p>
                <span className=' w-[24px] h-[24px] bg-ss_primary text-ss_white rounded px-1 mr-4'>
                  ✔
                </span>
                $529.95 Monthly Fee
              </p>
              <p>
                <span className=' w-[24px] h-[24px] bg-ss_primary text-ss_white rounded px-1 mr-4'>
                  ✔
                </span>
                Credit Education
              </p>
              <p>
                <span className=' w-[24px] h-[24px] bg-ss_primary text-ss_white rounded px-1 mr-4'>
                  ✔
                </span>
                3 Bureau Audit - Analysis
              </p>
              <p>
                <span className=' w-[24px] h-[24px] bg-ss_primary text-ss_white rounded px-1 mr-4'>
                  ✔
                </span>
                24/7 Client Portal Access
              </p>
              <p>
                <span className=' w-[24px] h-[24px] bg-ss_primary text-ss_white rounded px-1 mr-4'>
                  ✔
                </span>
                Customer Service Support
              </p>
              <p>
                <span className=' w-[24px] h-[24px] bg-ss_primary text-ss_white rounded px-1 mr-4'>
                  ✔
                </span>
                Monthly Updates
              </p>
              <p>
                <span className=' w-[24px] h-[24px] bg-ss_primary text-ss_white rounded px-1 mr-4'>
                  ✔
                </span>
                Activie Credit Monitoring Software
              </p>
              <p>
                <span className=' w-[24px] h-[24px] bg-ss_primary text-ss_white rounded px-1 mr-4'>
                  ✔
                </span>
                No Contract
              </p>
              <p>
                <span className=' w-[24px] h-[24px] bg-ss_primary text-ss_white rounded px-1 mr-4'>
                  ✔
                </span>
                Ultimited Disputes
              </p>
              <p>
                <span className=' w-[24px] h-[24px] bg-ss_primary text-ss_white rounded px-1 mr-4'>
                  ✔
                </span>
                White Glove Service
              </p>
              <p>
                <span className=' w-[24px] h-[24px] bg-ss_primary text-ss_white rounded px-1 mr-4'>
                  ✔
                </span>
                The Opportunity to “Skip The Line”
              </p>
              <p>
                <span className=' w-[24px] h-[24px] bg-ss_primary text-ss_white rounded px-1 mr-4'>
                  ✔
                </span>
                Same Letters, Same Results, But You'll Work Directly with the
                CEO LIMITED AVAILABILITY
              </p>
              <div className='flex justify-center items-center mt-12 sm:mt-4'>
                <button
                  onClick={packageUpdateTrue}
                  className=' w-[350px] h-[47px] sm:w-[168px] sm:h-[33px] sm:py-2 px-8 sm:px-4 text-[23px] sm:text-[11px] sm:leading-[16px] bg-ss_primary rounded-sm text-ss_white'
                >
                  Repair My Credit Today !
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Pricing;

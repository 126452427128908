import React from 'react';
import smartCredit__logo from '../../../assets/smartCredit.png';
import { useNavigate } from 'react-router-dom';
import {
  NavBar,
  Footer,
  SmartAgreement,
  CreditPageNav,
} from '../../../components';
import { useFeatureContext } from '../../../context/FeatureContext';

const PageC = () => {
  const today = new Date().toLocaleDateString();
  const navigate = useNavigate();
  const { updatePageName } = useFeatureContext();
  const ToPayment = () => {
    updatePageName('payment');
    navigate('/credit-monitoring/payment');
  };
  return (
    <div className=' grid place-items-center'>
      <div>
        <NavBar pagename='credit-monitoring' />
      </div>

      {/* //! page 1 */}
      <div className='grid sm:ml-0'>
        <div className=' my-[60px] sm:my-4 ml-[-24px] sm:ml-0  sm:h-[20px]'>
          <img
            className=' w-[350px] sm:w-[280px]'
            src={smartCredit__logo}
            alt='SS'
          />
        </div>
        {/* //! process bar */}
        <CreditPageNav processState='package' />
        <div className='sm:mx-4'>
          <h1 className='text-[23px] leading-[34px] text-ss_grey my-[18px] font-aulire mt-20 sm:mt-10'>
            Your Plan
          </h1>
          <p className=' text-ss_grey mb-[19px]'>
            You Have Selected The Following Plan
          </p>
          <p className=' text-ss_grey mb-[44px]'>
            Premium Membership - $29.95 per month
          </p>
        </div>
        <div className=' sm:mx-4'>
          <p className='mb-6'>
            <span className=' bg-ss_primary text-ss_white w-[24px] h-[24px] py-1 px-2 rounded-md mr-4'>
              ✔
            </span>
            Smart Credit Report® & Scores - Unlimited
          </p>
          <p className='mb-6'>
            <span className=' bg-ss_primary text-ss_white w-[24px] h-[24px] py-1 px-2 rounded-md mr-4'>
              ✔
            </span>
            ScoreTracker - Unlimited
          </p>
          <p className='mb-6'>
            <span className=' bg-ss_primary text-ss_white w-[24px] h-[24px] py-1 px-2 rounded-md mr-4'>
              ✔
            </span>
            ScoreBuilder® - Unlimited
          </p>
          <p className='mb-6'>
            <span className=' bg-ss_primary text-ss_white w-[24px] h-[24px] py-1 px-2 rounded-md mr-4'>
              ✔
            </span>
            ScoreBoost™ - Unlimited
          </p>
          <p className='mb-6'>
            <span className=' bg-ss_primary text-ss_white w-[24px] h-[24px] py-1 px-2 rounded-md mr-4'>
              ✔
            </span>
            Actions - Unlimited
          </p>
          <p className='mb-6'>
            <span className=' bg-ss_primary text-ss_white w-[24px] h-[24px] py-1 px-2 rounded-md mr-4'>
              ✔
            </span>
            Credit Monitoring - Unlimited
          </p>
          <p className='mb-6'>
            <span className=' bg-ss_primary text-ss_white w-[24px] h-[24px] py-1 px-2 rounded-md mr-4'>
              ✔
            </span>
            Money Manager - Unlimited
          </p>
          <p className='mb-6'>
            <span className=' bg-ss_primary text-ss_white w-[24px] h-[24px] py-1 px-2 rounded-md mr-4'>
              ✔
            </span>
            3B Report & Scores - 1 per month
          </p>
          <p className='mb-6'>
            <span className=' bg-ss_primary text-ss_white w-[24px] h-[24px] py-1 px-2 rounded-md mr-4'>
              ✔
            </span>
            Subscription Start Date - {today}
          </p>
          <p className='mb-6'>
            <span className=' bg-ss_primary text-ss_white w-[24px] h-[24px] py-1 px-2 rounded-md mr-4'>
              ✔
            </span>
            Monthly fee starting on {today} - $29.95
          </p>
        </div>
        <button
          onClick={ToPayment}
          className=' py-2 px-4 w-[113px] h-[51px] text-[23px] leading-[34px] bg-ss_primary rounded-[5px] text-ss_white mt-8 mb-20  sm:mb-10 sm:mx-4'
        >
          Submit
        </button>
        <SmartAgreement />
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default PageC;

import React from 'react';
import { Link } from 'react-router-dom';
import { Footer, NavBar } from '../../components';

const Resources = () => {
  return (
    <div className=' flex flex-col justify-center items-center overflow-hidden'>
      <NavBar pagename='resources' />

      <div className='w-screen grid place-items-center bg-ss_white h-[392px] sm:h-[146px] mt-[88px] sm:mt-[30px]'>
        <div className=' sm:w-[320px] h-[391px] sm:h-[auto] grid grid-cols-1 bg-ss_white  place-items-center gap-8 ml-[-90px] sm:ml-0 sm:place-self-center'>
          <div className='grid place-items-start sm:w-screen sm:ml-8'>
            <p className=' font-aulire text-[45px] leading-[68px] sm:leading-6 sm:text-base my-4 sm:my-1'>
              <span>Know About Credit Score</span>
            </p>
            <p className='font-aulire sm:w-[155px] text-[23px] sm:text-[11px] leading-[34px] sm:leading-[16px] text-ss_grey mt-8 sm:mt-4'>
              Let's see what credit score is and how it impacts our lives and
              why should you improve it.
            </p>
          </div>
        </div>
      </div>

      <div className='flex flex-col justify-center items-center my-[64px] sm:my-6'>
        <div className=' ml-[-80px] sm:ml-7'>
          <p className=' text-[32px] leading-[48px] sm:text-[16px] sm:leading-[24pxpx] font-[aulire] pb-6 sm:pb-4'>
            What is a credit score?
          </p>
          <p className='text-[16px] sm:text-[11px] sm:leading-[16px] leading-[24px] w-[1070px] sm:w-[300px] pb-20 sm:pb-8'>
            A credit score is a number generated by a mathematical formula that
            is meant to predict creditworthiness. Credit scores have become a
            valuable tool in the lending industry and are u sed by lenders to
            determine whether to issue you a loan, how much interest to charge
            you and whether it's worth their while to decide to lend you money.
            In fact, a good credit score can help you get loans at lower
            interest rates and gives you more loan options.
          </p>
          <p className=' text-[32px] leading-[48px] sm:text-[16px] sm:leading-[24pxpx] font-[aulire] pb-6'>
            What is a 'good' score?
          </p>
          <p className='text-[16px] leading-[24px] sm:text-[11px] sm:leading-[16px] w-[1070px] sm:w-[300px] pb-20 sm:pb-6'>
            In order to understand what a good credit score is, it's important
            to understand what a bad credit score is. There are two types of
            credit scores that are used by lenders: FICO Scores and
            VantageScores.There are several different FICO score models, but
            most lenders use the FICO 08 model, which has a range of 300 to 850.
            Generally, a FICO Score above 670 is considered a good credit score
            on these models, and a score above 800 is usually perceived to be
            exceptional.A good VantageScore is also considered to be above 700,
            but the exact criteria vary by model.If you aim for the higher end
            of these ranges, you're well on your way to having good credit. If
            you're interested in learning more about how your credit score
            compares to others in your area, it may make sense to check out a
            few of the many free online resources that offer this information --
            CreditKarma, Credit Sesame, and Quizzle are three popular options.
          </p>
          <p className=' sm:text-[16px] sm:leading-[24pxpx] text-[32px] leading-[48px] font-[aulire] pb-6'>
            So why should you have good credit?
          </p>
          <p className='text-[16px] leading-[24px] w-[1055px] sm:w-[300px] pb-20 sm:pb-6 sm:text-[11px] sm:leading-[16px]'>
            What isn't obvious is that the perceived value of your good credit
            affects more than just your life.
            <br />
            <br />
            <br />A good credit score is generally considered to be in the high
            600s to low 700s. A good credit score can help you get a good
            interest rate on loans, qualify for a variety of mortgages, secure
            apartment rentals, open utility services, and more.
          </p>
          <p className='sm:text-[16px] sm:leading-[24px] text-[32px] leading-[48px] font-[aulire] pb-6'>
            So what are you waiting for? Let's fix your credit
          </p>
          <Link to='/pricing'>
            <button className=' w-[309px] sm:w-[180px] h-[51px] sm:h-[29px] py-2 px-4 text-xs bg-ss_primary rounded-sm text-ss_white mb-20 sm:mb-8'>
              Repair My Credit Today !
            </button>
          </Link>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Resources;

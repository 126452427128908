import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  // NavBar,
  // Footer,
  SmartAgreement,
  Alert,
  CreditPageNav,
  CompanyLogo,
} from '../../../../../components';
import { useAppContext } from '../../../../../context/appContext';
import { useFeatureContext } from '../../../../../context/FeatureContext';

const Page1Data = {
  FirstName: '',
  LastName: '',
  PhoneNumber: '',
  Email: '',
  Password: '',
  SponsorCode: '',
};
const Page1 = () => {
  const navigate = useNavigate();
  const [Values, setValues] = useState(Page1Data);
  const { stateUpdateA } = useAppContext();
  const { showAlert, displayAlert, updatePageName, setIsLoading } =
    useFeatureContext();
  const handleChange = (e) => {
    setValues({ ...Values, [e.target.name]: e.target.value });
  };

  const [trackingToken, settrackingToken] = useState('');

  const environment = process.env.REACT_APP_ENVIRONMENT_SMART_CREDIT;

  const clientKey = process.env.REACT_APP_CLINT_KEY_SMART_CREDIT;
  const PID = process.env.REACT_APP_ENVIRONMENT_SMART_CREDIT_PID;

  // ! starting api

  const smartCreditStart = async () => {
    const response = await fetch(
      `https://${environment}/api/signup/start?clientKey=${clientKey}&PID=${PID}`
    );
    const data = await response.json();

    //? saved trcToken
    settrackingToken(data.trackingToken);
  };

  //!campaign
  const smartCreditCampaign = async () => {
    const response = await fetch(
      `https://${environment}/api/signup/campaign?clientKey=${clientKey}&PID=${PID}`
    );
    if (response) {
      return;
    }
  };

  // !validate email

  const validateEmail = async () => {
    const response = await fetch(
      `https://${environment}/api/signup/validate/email?clientKey=${clientKey}&trackingToken=${trackingToken}&email=${Values.Email}`
    );
    if (response.ok === true) {
      return;
    } else {
      throw Error('Invalid Email !');
    }
  };

  //! vlidate sponsor code
  const validateSponsorCode = async () => {
    const response = await fetch(
      `https://${environment}/api/signup/validate/sponsor-code?clientKey=${clientKey}&trackingToken=${trackingToken}&sponsorCode=${Values.SponsorCode}`
    );
    if (response.ok === true) {
      return;
    } else {
      throw Error('Invalid Sponsor Code !');
    }
  };

  const apiCalls = async () => {
    try {
      await validateEmail();
      await validateSponsorCode();
      updatePageName('page2');
      navigate('/smart-credit/verify');
      setIsLoading(false);
    } catch (err) {
      displayAlert(err.message);
      setIsLoading(false);
    }
  };

  const apiStartUseEffect = async () => {
    await smartCreditStart();
    await smartCreditCampaign();
  };

  useEffect(() => {
    apiStartUseEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //!email vlidation
  const isEmail = (emailAdress) => {
    const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (emailAdress.match(regex)) return true;
    else return false;
  };

  const OnSubmission = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (isEmail(Values.Email)) {
      await apiCalls();
      stateUpdateA(
        Values.FirstName,
        Values.LastName,
        Values.PhoneNumber,
        Values.Email,
        Values.SponsorCode,
        Values.Password,
        trackingToken
      );
    } else {
      setIsLoading(false);
      displayAlert('Invalid Email');
    }
  };

  return (
    <div className='grid place-items-center'>
      <div className='box_shadow_universal grid place-items-start w-[1230px] sm:w-[auto] my-[100px] sm:my-4 shadow-ss_white'>
        {/* //! page 1 */}
        <div className='grid ml-[40px] sm:ml-1'>
          <div>
            <CompanyLogo />
          </div>
          {/* //! process bar */}
          <div className='ml-4'>
            <CreditPageNav processState='register' />
          </div>
          <h1 className='text-[23px] sm:text-base leading-[34px] sm:leading-6 text-ss_grey my-16 sm:my-4 font-aulire ml-4'>
            Let's get started
          </h1>

          {/* //! form */}

          <form
            onSubmit={OnSubmission}
            className='grid sm:grid-flow-row grid-cols-2 sm:grid-cols-1 ml-4 text-[23px] leading-[34px] sm:text-xs sm:leading-4'
          >
            <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
              <label className='text-ss_grey'>First Name</label>
              <input
                onChange={handleChange}
                name='FirstName'
                value={Values.FirstName}
                required
                className='w-[544px] h-[67px] sm:w-[92%] sm:h-[33px] bg-ss_white text-lg rounded-[5px] p-4'
              />
            </div>
            <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
              <label className=' text-ss_grey'>Last Name</label>
              <input
                onChange={handleChange}
                name='LastName'
                value={Values.LastName}
                required
                className='w-[544px] h-[67px] sm:w-[92%] sm:h-[33px] bg-ss_white text-lg rounded-[5px] p-4'
              />
            </div>
            <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
              <label className='text-ss_grey'>Phone Number</label>
              <input
                onChange={handleChange}
                name='PhoneNumber'
                value={Values.PhoneNumber}
                required
                type='tel'
                minLength={10}
                maxLength={10}
                className='w-[544px] h-[67px] sm:w-[92%] sm:h-[33px] bg-ss_white text-lg rounded-[5px] p-4'
              />
            </div>
            <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
              <label className=' text-ss_grey'>Email Address</label>
              <input
                onChange={handleChange}
                name='Email'
                autoComplete='email'
                value={Values.Email}
                required
                type='email'
                className='w-[544px] h-[67px] sm:w-[92%] sm:h-[33px] bg-ss_white text-lg rounded-[5px] p-4'
              />
            </div>
            <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
              <label className=' text-ss_grey'>Password</label>
              <input
                onChange={handleChange}
                name='Password'
                minLength={8}
                value={Values.Password}
                required
                type='password'
                className='w-[544px] h-[67px] sm:w-[92%] sm:h-[33px] bg-ss_white text-lg rounded-[5px] p-4'
              />
            </div>
            <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
              <label className=' text-ss_grey'>Sponsor Code</label>
              <input
                onChange={handleChange}
                name='SponsorCode'
                maxLength={36}
                value={Values.SponsorCode}
                className='w-[544px] h-[67px] sm:w-[92%] sm:h-[33px] bg-ss_white text-lg rounded-[5px] p-4'
              />
            </div>

            <div className=' grid grid-flow-col'>
              <button className='py-2 px-4 w-[113px] sm:w-[71px] h-[51px] sm:h-[33px] text-[23px] sm:text-[11px] leading-[34px] sm:leading-4 bg-ss_primary rounded-[5px] text-ss_white mt-8 mb-6 sm:mb-2'>
                Submit
              </button>
              <div className=' place-items-center ml-[-230px] mt-[35px] sm:mt-8 sm:ml-[-250px] sm:h-[33px]'>
                {showAlert && <Alert />}
              </div>
            </div>
          </form>
          <SmartAgreement />
        </div>
      </div>
    </div>
  );
};

export default Page1;

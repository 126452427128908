/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  //   NavBar,
  //   Footer,
  SmartAgreement,
  CreditPageNav,
  Alert,
  CompanyLogo,
} from '../../../../../components';
import { useAppContext } from '../../../../../context/appContext';
import { useFeatureContext } from '../../../../../context/FeatureContext';

const PageADataD = {
  FirstName: '',
  LastName: '',
  PhoneNumber: '',
  Email: '',
  streetAdress: '',
  zipCode: '',
  city: '',
  state_country: '',
  cardNumber: '',
  ccc: '',
  expDate: '',
};
const Page4 = () => {
  const navigate = useNavigate();
  const [Values, setValues] = useState(PageADataD);
  const {
    firstName,
    lastName,
    zipCode,
    email,
    phoneNumber,
    streetAdress,
    city,
    state_country,
    stateUpdateD,
    trackingToken,
    customerToken,
  } = useAppContext();
  const { showAlert, displayAlert, updatePageName, setIsLoading } =
    useFeatureContext();

  const handleChange = (e) => {
    setValues({ ...Values, [e.target.name]: e.target.value });
  };

  // !api smart credit

  const environment = process.env.REACT_APP_ENVIRONMENT_SMART_CREDIT;
  const clientKey = process.env.REACT_APP_CLINT_KEY_SMART_CREDIT;

  const encodedclientKey = encodeURIComponent(clientKey);
  const encodedtrackingToken = encodeURIComponent(trackingToken);
  const encodedcustomerToken = encodeURIComponent(customerToken);
  const encodedfirstName = encodeURIComponent(firstName);
  const encodedlastName = encodeURIComponent(lastName);
  const encodedtruth = encodeURIComponent('true');
  const encodedstreetAdress = encodeURIComponent(streetAdress);
  const encodedcity = encodeURIComponent(city);
  const encodedstate = encodeURIComponent(state_country);
  const encodedzip = encodeURIComponent(zipCode);

  // !--------------------------------------------------------------

  //! credid card validation
  const [creditCardToken, setcreditCardToken] = useState('');

  //! credid card update
  const encodedcreditCardToken = encodeURIComponent(creditCardToken);
  const encodedcreditCardCvv = encodeURIComponent(Values.ccc);
  const encodedcreditCardExpirationMonth = encodeURIComponent(
    Values.expDate.slice(5)
  );
  const encodedcreditCardExpirationYear = encodeURIComponent(
    Values.expDate.slice(0, 4)
  );

  // const encodedcreditCardFullName = encodeURIComponent(trackingToken);

  const creditCardUpdateData = `clientKey=${encodedclientKey}&trackingToken=${encodedtrackingToken}&customerToken=${encodedcustomerToken}&creditCard.token=${encodedcreditCardToken}&creditCard.cvv=${encodedcreditCardCvv}&creditCard.expirationMonth=${encodedcreditCardExpirationMonth}&creditCard.expirationYear=${encodedcreditCardExpirationYear}&creditCard.name=${
    encodedfirstName + '' + encodedlastName
  }&creditCard.address=${encodedstreetAdress}&creditCard.city=${encodedcity}&creditCard.state=${encodedstate}&creditCard.zip=${encodedzip}&isConfirmedTerms=${encodedtruth}&isBrowserConnection=${encodedtruth}`;

  const creditCardUpdatePost = async () => {
    const response = await fetch(
      `https://${environment}/api/signup/customer/update/credit-card`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: creditCardUpdateData,
      }
    );
    if (response.ok === true) {
      // const data = await response.json();
      // console.log(data);
      return;
    } else {
      throw Error('Credit Card Error !');
    }
  };

  // !-------------------------------------------------------------
  //!complete

  let accountInfo2 = {
    clientKey: clientKey,
    trackingToken: trackingToken,
    customerToken: customerToken,
  };

  let formBody2 = [];
  for (let property in accountInfo2) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(accountInfo2[property]);
    formBody2.push(encodedKey + '=' + encodedValue);
  }
  formBody2 = formBody2.join('&');

  const completeAccountCreation = async () => {
    const response = await fetch(`https://${environment}/api/signup/complete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: formBody2,
    });
    if (response.ok === true) {
      // const data = await response.json();
      // console.log('successfully enrolled client !!');
      return;
    } else {
      throw Error('Error Occured!');
    }
  };

  useEffect(() => {
    // creditCardValidation();
    fetch(
      `https://${environment}/api/signup/validate/credit-card-number?clientKey=${clientKey}&trackingToken=${trackingToken}&number=${Values.cardNumber}`
    )
      .then((response) => response.json())
      .then((data) => setcreditCardToken(data.creditCardToken));
    // setcreditCardToken(newcredittoken);
    // console.log(creditCardToken);
  }, [Values.cardNumber]);

  // !smartcredit api ends here

  const apiCalls = async () => {
    try {
      await creditCardUpdatePost();
      await completeAccountCreation();
      updatePageName('page5');
      navigate('/smart-credit/complete');
      setIsLoading(false);
    } catch (error) {
      displayAlert(error.message);
      setIsLoading(false);
    }
  };

  const OnSubmission = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    stateUpdateD(Values.city, Values.state_country);
    await apiCalls();
  };

  return (
    <div className=' grid place-items-center sm:place-items-start'>
      {/* <div>
        <NavBar pagename='credit-monitoring' />
      </div> */}
      <div className=' box_shadow_universal grid place-items-start w-[1200px] sm:w-[auto] my-[100px] sm:my-4 shadow-ss_white'>
        {/* //! page 1 */}
        <form
          onSubmit={OnSubmission}
          className='grid ml-[40px] sm:ml-0 text-[23px] leading-[34px] sm:text-xs sm:leading-4'
        >
          <div className=' sm:ml-4'>
            <CompanyLogo />
          </div>
          {/* //! process bar */}
          <CreditPageNav processState='payment' />
          <h1 className='text-[23px] sm:text-base leading-[34px] sm:leading-6 text-ss_grey my-16 sm:my-6 sm:mx-4 font-aulire'>
            Billing Details
          </h1>
          {/* //! form */}
          <div className='grid sm:grid-flow-row grid-cols-2 sm:grid-cols-1 sm:mx-4'>
            <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
              <label className=' text-ss_grey'>First Name</label>
              <input
                type='text'
                disabled
                name='firstName'
                defaultValue={firstName}
                required
                className='w-[544px] h-[67px] sm:w-[92%] sm:h-[33px] bg-ss_white text-lg rounded-[5px] p-4'
              />
            </div>
            <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
              <label className=' text-ss_grey'>Last Name</label>
              <input
                type='text'
                name='lastName'
                disabled
                defaultValue={lastName}
                required
                className='w-[544px] h-[67px] sm:w-[92%] sm:h-[33px] bg-ss_white text-lg rounded-[5px] p-4'
              />
            </div>
            <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
              <label className=' text-ss_grey'>Phone Number</label>
              <input
                disabled
                defaultValue={phoneNumber}
                name='phoneNumber'
                required
                type='number'
                className='w-[544px] h-[67px] sm:w-[92%] sm:h-[33px] bg-ss_white text-lg rounded-[5px] p-4'
              />
            </div>
            <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
              <label className=' text-ss_grey'>Email Address</label>
              <input
                disabled
                defaultValue={email}
                name='email'
                required
                type='email'
                className='w-[544px] h-[67px] sm:w-[92%] sm:h-[33px] bg-ss_white text-lg rounded-[5px] p-4'
              />
            </div>
            <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
              <label className=' text-ss_grey'>Street Address</label>
              <input
                disabled
                defaultValue={streetAdress}
                name='streetAdress'
                required
                className='w-[544px] h-[67px] sm:w-[92%] sm:h-[33px] bg-ss_white text-lg rounded-[5px] p-4'
              />
            </div>
            <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
              <label className=' text-ss_grey'>Zip Code</label>
              <input
                disabled
                defaultValue={zipCode}
                name='zipCode'
                required
                className='w-[544px] h-[67px] sm:w-[92%] sm:h-[33px] bg-ss_white text-lg rounded-[5px] p-4'
              />
            </div>
            <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
              <label className=' text-ss_grey'>City</label>
              <input
                disabled
                name='city'
                defaultValue={city}
                maxLength={100}
                required
                className='w-[544px] h-[67px] sm:w-[92%] sm:h-[33px] bg-ss_white text-lg rounded-[5px] p-4'
              />
            </div>
            <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
              <label className=' text-ss_grey'>State</label>
              <input
                disabled
                name='state_country'
                maxLength={2}
                minLength={2}
                defaultValue={state_country}
                required
                className='w-[544px] h-[67px] sm:w-[92%] sm:h-[33px] bg-ss_white text-lg rounded-[5px] p-4'
              />
            </div>
          </div>
          <h1 className='text-[23px] sm:text-base leading-[34px] sm:leading-6 text-ss_grey my-16 sm:my-6 font-aulire sm:mx-4'>
            Credit Card
          </h1>
          <div className=' grid sm:grid-flow-row grid-cols-2 sm:grid-cols-1 sm:mx-4'>
            <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
              <label className=' text-ss_grey'>Card Number</label>
              <input
                onChange={handleChange}
                name='cardNumber'
                type='tel'
                inputMode='numeric'
                pattern='[0-9\s]{13,19}'
                minLength={15}
                maxLength={16}
                placeholder='xxxx-xxxx-xxxx-xxxx'
                value={Values.cardNumber}
                required
                className='w-[544px] h-[67px] sm:w-[92%] sm:h-[33px] bg-ss_white text-lg rounded-[5px] p-4'
              />
            </div>
            <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
              <label className=' text-ss_grey'>Card Security Code</label>
              <input
                onChange={handleChange}
                name='ccc'
                minLength={3}
                maxLength={4}
                value={Values.ccc}
                required
                className='w-[544px] h-[67px] sm:w-[92%] sm:h-[33px] bg-ss_white text-lg rounded-[5px] p-4'
              />
            </div>
            <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
              <label className=' text-ss_grey'>Expiration (MM/YY)</label>
              <input
                onChange={handleChange}
                type='month'
                name='expDate'
                value={Values.expDate}
                required
                className='w-[544px] h-[67px] sm:w-[92%] sm:h-[33px] bg-ss_white text-lg rounded-[5px] p-4'
              />
            </div>
          </div>

          <button className=' py-2 px-4 w-[200px] sm:w-[110px] h-[51px] sm:h-[33px] text-[23px] sm:text-[11px] leading-[34px] sm:leading-4 bg-ss_primary rounded-[5px] text-ss_white mt-16 mb-4 sm:mb-10 sm:mx-4 cursor-pointer z-50 '>
            Place Order
          </button>

          <div className='  ml-[-390px] sm:ml-12 mt-[-72px]'>
            {showAlert && <Alert />}
          </div>
          <SmartAgreement />
        </form>

        {/* <div>
        <Footer />
      </div> */}
      </div>
    </div>
  );
};

export default Page4;

import React from 'react';
import { useState } from 'react';
import { useFeatureContext } from '../context/FeatureContext';
import Alert from './Alert';

const PageAData = {
  FirstName: '',
  LastName: '',
  PhoneNumber: '',
  Email: '',
  ext: '',
  company: '',
  website: '',
  alternate_phone: '',
  fax: '',
};

export const Affiliate = (props) => {
  const [Values, setValues] = useState(PageAData);

  const { showAlert, displayAlert, setIsLoading } = useFeatureContext();

  const handleChange = (e) => {
    setValues({ ...Values, [e.target.name]: e.target.value });
  };

  //! email vlidation

  const isEmail = (emailAdress) => {
    const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (emailAdress.match(regex)) return true;
    else return false;
  };
  //!CRC
  const apiKey = process.env.REACT_APP_API_KEY_CRC;
  const secretkey = process.env.REACT_APP_SECRET_KEY_CRC;

  const record101 =
    '<?xml version="1.0" encoding="utf-8"?>' +
    '<crcloud>' +
    '<affiliate>' +
    '<type>Active</type>' +
    `<firstname>${Values.FirstName}</firstname>` +
    `<lastname>${Values.LastName}</lastname>` +
    `<email>${Values.Email}</email>` +
    `<gender>Male</gender>` +
    `<phone>${Values.PhoneNumber}</phone>` +
    `<fax>${Values.fax}</fax>` +
    `<phone_ext>${Values.ext}</phone_ext>` +
    `<company>${Values.company}</company>` +
    `<alternate_phone>${Values.alternate_phone}</alternate_phone>` +
    `<affiliate_portal_access>on</affiliate_portal_access>` +
    `<affiliate_userid>${Values.Email}</affiliate_userid>` +
    `<send_setup_password_info_via_email>yes</send_setup_password_info_via_email>` +
    '</affiliate>' +
    '  </crcloud>';
  const insertRecordCRC = async () => {
    await fetch(
      `https://app.creditrepaircloud.com/api/affiliate/insertRecord?apiauthkey=${apiKey}&secretkey=${secretkey}&xmlData=${record101}`
    ).then((response) => {
      response
        .text()
        .then((xml) => {
          const parser = new DOMParser();
          const result = parser.parseFromString(xml, 'application/xml');
          const resultnode = result.querySelectorAll('success');
          if (resultnode[0].childNodes[0].data === 'True') {
            console.log('successfully inserted data');
            displayAlert('Success');
            setIsLoading(false);

            props.setTrigger(false);
          } else throw Error('Error Occured! Try Again');
        })
        .catch((error) => {
          displayAlert(error.message);
          setIsLoading(false);
        });
    });
  };

  const requiredSpan = () => {
    return <span className=' text-red-700 text-2xl'>*</span>;
  };

  const OnSubmission = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (isEmail(Values.Email)) {
      await insertRecordCRC();
      displayAlert('Success', true);
    } else {
      setIsLoading(false);
      displayAlert('Invalid Email');
    }
  };

  return props.trigger ? (
    <div className='fixed bottom-0 left-0 right-0 top-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-[100%] justify-center items-center flex'>
      <div className='relative h-[90%] sm:h-[100%] w-full max-w-[800px] max-h-[1000px] sm:max-h-full p-[40px] sm:p-4 bg-ss_black'>
        <h1 className=' text-ss_primary mb-4 text-center text-3xl'>
          Affiliate Registration
        </h1>
        <button
          onClick={() => props.setTrigger(false)}
          type='button'
          className='absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white'>
          <svg
            className='w-5 h-5'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              fillRule='evenodd'
              d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
              clipRule='evenodd'></path>
          </svg>
        </button>
        <form
          onSubmit={OnSubmission}
          className='grid sm:grid-flow-row grid-cols-2 sm:grid-cols-1 ml-4 text-[16px] leading-[24px] sm:text-xs sm:leading-4'>
          <div className='grid grid-flow-row mr-8 gap-2 sm:col-span-2  sm:mr-0 '>
            <label className='text-ss_grey'>First Name {requiredSpan()}</label>
            <input
              onChange={handleChange}
              name='FirstName'
              value={Values.FirstName}
              required
              className='w-[244px] h-[37px] sm:w-[92%] sm:h-[25px] bg-ss_white text-lg rounded-[5px] p-4 sm:p-2'
            />
          </div>
          <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
            <label className=' text-ss_grey'>Last Name {requiredSpan()}</label>
            <input
              onChange={handleChange}
              name='LastName'
              value={Values.LastName}
              required
              className='w-[244px] h-[37px] sm:w-[92%] sm:h-[25px] bg-ss_white text-lg rounded-[5px] p-4 sm:p-2'
            />
          </div>
          <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
            <label className='text-ss_grey'>
              Phone Number {requiredSpan()}
            </label>
            <input
              onChange={handleChange}
              name='PhoneNumber'
              value={Values.PhoneNumber}
              required
              type='tel'
              minLength={10}
              maxLength={10}
              className='w-[244px] h-[37px] sm:w-[92%] sm:h-[25px] bg-ss_white text-lg rounded-[5px] p-4 sm:p-2'
            />
          </div>
          <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
            <label className=' text-ss_grey'>
              Email Address {requiredSpan()}
            </label>
            <input
              onChange={handleChange}
              name='Email'
              autoComplete='email'
              value={Values.Email}
              required
              type='email'
              className='w-[244px] h-[37px] sm:w-[92%] sm:h-[25px] bg-ss_white text-lg rounded-[5px] p-4 sm:p-2'
            />
          </div>
          <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
            <label className=' text-ss_grey'>Ext</label>
            <input
              onChange={handleChange}
              name='ext'
              minLength={10}
              maxLength={10}
              value={Values.ext}
              type='tel'
              className='w-[244px] h-[37px] sm:w-[92%] sm:h-[25px] bg-ss_white text-lg rounded-[5px] p-4 sm:p-2'
            />
          </div>
          <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
            <label className=' text-ss_grey'>Company</label>
            <input
              onChange={handleChange}
              name='company'
              maxLength={36}
              value={Values.company}
              className='w-[244px] h-[37px] sm:w-[92%] sm:h-[25px] bg-ss_white text-lg rounded-[5px] p-4 sm:p-2'
            />
          </div>
          <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
            <label className=' text-ss_grey'>Alternate Phone</label>
            <input
              onChange={handleChange}
              name='alternate_phone'
              minLength={10}
              maxLength={10}
              type='tel'
              value={Values.alternate_phone}
              className='w-[244px] h-[37px] sm:w-[92%] sm:h-[25px] bg-ss_white text-lg rounded-[5px] p-4 sm:p-2'
            />
          </div>
          <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
            <label className=' text-ss_grey'>Fax</label>
            <input
              onChange={handleChange}
              name='fax'
              type='tel'
              minLength={10}
              maxLength={10}
              value={Values.fax}
              className='w-[244px] h-[37px] sm:w-[92%] sm:h-[25px] bg-ss_white text-lg rounded-[5px] p-4 sm:p-2'
            />
          </div>
          <div className=' grid grid-flow-col gap-[80px] sm:gap-6'>
            <button
              type='submit'
              className=' px-2 py-2 w-[300px] sm:w-[100px] h-[41px] sm:h-[auto] text-[16px] sm:text-[11px] leading-[24px] sm:leading-4 bg-ss_primary rounded-[5px] text-ss_white mt-8 sm:mt-4 mb-10 sm:mb-2'>
              Submit My Information Now
            </button>
            <div className='mt-[25px] sm:mt-4 w-[200px]'>
              {showAlert && <Alert />}
            </div>
          </div>
        </form>
        <p className=' text-ss_primary mt-[-10px] sm:mt-14 ml-4 sm:hidden'>
          By entering my contact information and clicking "Submit my information
          now" I consent by electronic signature to be contacted about credit
          repair by telephone or by email.
        </p>
      </div>
    </div>
  ) : (
    ''
  );
};

const SmartAgreement = () => {
  return (
    <div className=' place-self-center text-center sm:text-left  my-20 sm:my-8 sm:mx-4'>
      <p>By clicking on the "Submit" button above</p>
      <p>
        I acknowledge that I have read the SmartCredit Service{' '}
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://www.smartcredit.com/help/terms-and-privacy/service-agreement.htm'
          className=' text-ss_primary'
        >
          Agreement, Terms{' '}
        </a>{' '}
        of Use and{' '}
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://www.smartcredit.com/help/terms-and-privacy/privacy-policy.htm'
          className=' text-ss_primary'
        >
          Privacy Policy
        </a>
        ,
      </p>
      <p>and that I agree to their terms.</p>
    </div>
  );
};

export default SmartAgreement;

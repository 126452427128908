import React from 'react';
import ReactDOM from 'react-dom/client';
import 'normalize.css';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AppProvider } from './context/appContext';
import { FeatureProvider } from './context/FeatureContext';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { AdminProvider } from './context/AdminContext/admincontext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AppProvider>
      <FeatureProvider>
        <AdminProvider>
          <App />
        </AdminProvider>
      </FeatureProvider>
    </AppProvider>
  </BrowserRouter>
);

// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

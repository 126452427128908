import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import image_head from '../../assets/contactUs.webp';
import { Alert, Footer, NavBar } from '../../components';
import { useFeatureContext } from '../../context/FeatureContext';

const PageAData = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  message: '',
};

const ContactUs = () => {
  const apiUrl = process.env.REACT_APP_API_ENVIRONMENT_URL;
  const [Values, setValues] = useState(PageAData);
  const { showAlert, displayAlert, setIsLoading } = useFeatureContext();

  const handleChange = (e) => {
    setValues({ ...Values, [e.target.name]: e.target.value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      name: Values.firstName + ' ' + Values.lastName,
      email: Values.email,
      message: Values.message,
      phone: Values.phoneNumber,
    };
    axios
      .post(`${apiUrl}/contactUs`, data)
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        displayAlert(res.data.message, true);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        displayAlert(err.response.data.message);
      });
  };

  return (
    <div className=' grid place-items-center overflow-hidden'>
      <NavBar pagename='contact-us' />

      {/* //! heading */}

      <div className='w-screen grid place-items-center bg-ss_white h-[392px] sm:h-[146px] mt-[88px] sm:mt-[30px] '>
        <div className=' sm:w-[320px] h-[391px] sm:h-[auto] grid grid-cols-2 bg-ss_white  place-items-center gap-8 '>
          <div className='grid place-items-center sm:w-screen ml-[-147px] sm:ml-0'>
            <p className=' font-aulire text-[45px] leading-[68px] sm:leading-6 sm:text-base  my-4 sm:my-1 ml-[-350px] sm:ml-[-50px]'>
              <span>Having Doubts?</span> <br />
            </p>
            <p className='font-aulire w-[521px] sm:w-[155px] text-[23px] sm:text-[11px] leading-[34px] sm:leading-[16px] text-ss_grey mt-8 sm:mt-4 ml-[-194px] sm:ml-[-20px]'>
              Let us have a friendly talk to understand each other.
            </p>
          </div>
          <div className='grid grid-flow-col mr-[-200px] mt-[-70px] sm:mt-[-18px] sm:mr-0'>
            <img src={image_head} alt='ss' width='408px' height='461px' />
          </div>
        </div>
      </div>

      {/* //!mid section */}
      <div className='w-screen grid place-items-center mt-20 sm:mt-4 text-ss_grey mb-20 sm:mb-4'>
        <div className='grid'>
          <p className=' text-[32px] sm:text-base leading-[48px] font-aulire text-ss_black mb-[30px] sm:mb-6 ml-[27px] sm:ml-[18px] sm:mt-4 '>
            contact us
          </p>
          <p className=' text-[23px] sm:text-[11px] sm:leading-4 leading-[34px] font-aulire text-ss_black text-center mb-12 sm:mb-6'>
            How To Contact Us
          </p>
          <div className='grid grid-cols-2 sm:grid-cols-1 place-items-center gap-[290px] sm:gap-4'>
            <div className='grid gap-8 sm:gap-2 ml-[-59px] sm:ml-0'>
              <p>
                If you are a client you can send us a message in <br /> your
                client portal.
              </p>
              <p className=' text-ss_black'>Monday-Friday 9am – 5pm EST</p>
            </div>

            <form
              onSubmit={onSubmit}
              className='grid grid-cols-1 sm:grid-flow-row ml-[-75px] sm:ml-[16px]'
            >
              <p>If you are not a client you can send us a message in here.</p>
              <div className='grid sm:grid-flow-row grid-cols-2 sm:grid-cols-1 sm:mt-6 '>
                <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
                  <label>First Name</label>
                  <input
                    onChange={handleChange}
                    name='firstName'
                    value={Values.firstName}
                    required
                    className='w-[224px] h-[56px] sm:w-[90%] sm:h-[33px] bg-ss_white px-4 text-base rounded-[5px] text-ss_black'
                  />
                </div>
                <div className='grid grid-flow-row mb-2 gap-2 sm:col-span-2  sm:mr-0'>
                  <label>Last Name</label>
                  <input
                    required
                    name='lastName'
                    value={Values.lastName}
                    onChange={handleChange}
                    className='w-[224px] h-[56px] text-ss_black sm:w-[90%] sm:h-[33px] bg-ss_white px-4 text-base rounded-[5px]'
                  />
                </div>
                <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0'>
                  <label>Phone Number</label>
                  <input
                    required
                    value={Values.phoneNumber}
                    name='phoneNumber'
                    onChange={handleChange}
                    className='w-[224px] h-[56px] text-ss_black sm:w-[90%] sm:h-[33px] bg-ss_white px-4 text-base rounded-[5px]'
                  />
                </div>
                <div className='grid grid-flow-row mb-2 gap-2 sm:col-span-2  sm:mr-0'>
                  <label>Email Address</label>
                  <input
                    required
                    name='email'
                    value={Values.email}
                    onChange={handleChange}
                    pattern="[-a-zA-Z0-9~!$%^&amp;*_=+}{'?]+(\.[-a-zA-Z0-9~!$%^&amp;*_=+}{'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(\.[-a-zA-Z0-9_]+)*\.([cC][oO][mM]))(:[0-9]{1,5})?"
                    className='w-[224px] h-[56px] text-ss_black sm:w-[90%] sm:h-[33px] bg-ss_white px-4 text-base rounded-[5px]'
                  />
                </div>
                <div className=' col-span-2'>
                  <div className='grid grid-flow-row mb-2 gap-2'>
                    <label>Message</label>
                    <textarea
                      name='message'
                      value={Values.message}
                      type='text'
                      required
                      onChange={handleChange}
                      className='w-[483px] h-[56px] text-ss_black sm:w-[90%] sm:h-[33px] pt-2 bg-ss_white px-4 text-base rounded-[5px]'
                    />
                  </div>
                </div>
                <button
                  type='submit'
                  className=' py-2 px-4 w-[90px] h-[40px] text-xs bg-ss_primary rounded-[5px] text-ss_white mt-2'
                >
                  Submit
                </button>
              </div>
              {showAlert && <Alert />}
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;

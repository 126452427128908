import React from 'react';
import { Link } from 'react-router-dom';

const PopUp = (props) => {
  return props.trigger ? (
    <div className='fixed top-2 sm:top-2 w-screen h-screen flex justify-center items-center z-50'>
      <div
        style={{
          boxShadow: '0px 4px 30px rgba(0, 25, 35, 0.15',
        }}
        className='flex justify-center items-center flex-col  p-[64px] sm:p-4 gap-16 w-[1008px] h-[477px] bg-ss_white2 shadow-ss_white'>
        <h1 className='font-aulire text-[45px] leading-[68px] sm:text-[16px] sm:leading-[24px]'>
          Do you want full credit repair service ?
        </h1>
        <Link to='/plans'>
          <button className=' py-2 px-4 text-base bg-ss_primary rounded-sm text-ss_white'>
            Click Here
          </button>
        </Link>
        <span>or</span>
        <p
          onClick={() => props.setTrigger(false)}
          className='sm:text-[16px] sm:leading-[24px] text-ss_primary font-aulire text-[23px] leading-8 cursor-pointer'>
          Continue with Credit Monitoring
        </p>
      </div>
    </div>
  ) : (
    ''
  );
};

export default PopUp;

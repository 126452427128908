const ImageOutput = ({ image_tag, width, height }) => {
  return (
    <div className=' bg-ss_black rounded-[5px]  '>
      <img
        width={`${width}px`}
        height={`${height}px`}
        style={{
          width: `${width}px`,
          height: `${height}px`,
          borderRadius: '5px',
        }}
        className='opacity-50 object-cover'
        src={image_tag}
        alt='_image_'
      />
    </div>
  );
};

export default ImageOutput;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../../../context/appContext';
import { useFeatureContext } from '../../../../../context/FeatureContext';
import {
  //   NavBar,
  //   Footer,
  SmartAgreement,
  CreditPageNav,
  Alert,
  CompanyLogo,
} from '../../../../../components';

const PageADataB = {
  name: '',
  ssn: '',
  birthDate: '',
  streetAdress: '',
  zipCode: '',
  city: '',
  state_country: '',
};

const Page2 = () => {
  const navigate = useNavigate();

  const [customerToken, setcustomerToken] = useState('');

  const environment = process.env.REACT_APP_ENVIRONMENT_SMART_CREDIT;
  const clientKey = process.env.REACT_APP_CLINT_KEY_SMART_CREDIT;

  const [Values, setValues] = useState(PageADataB);
  const {
    firstName,
    lastName,
    email,
    psw,
    phoneNumber,
    sponsorCode,
    trackingToken,
    stateUpdateB,
  } = useAppContext();
  const { showAlert, displayAlert, updatePageName, setIsLoading } =
    useFeatureContext();

  // ! for creating account

  let accountInfo = {
    clientKey: clientKey,
    trackingToken: trackingToken,
    email: email,
    password: psw,
    sponsorCodeString: sponsorCode,
  };

  let formBody = [];
  for (let property in accountInfo) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(accountInfo[property]);
    formBody.push(encodedKey + '=' + encodedValue);
  }
  formBody = formBody.join('&');

  const createAccount = async () => {
    const response = await fetch(
      `https://${environment}/api/signup/customer/create`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: formBody,
      }
    );
    const data = await response.json();
    setcustomerToken(data.customerToken);
  };

  // ! validating ssn
  const encodedclientKey = encodeURIComponent(clientKey);
  const encodedtrackingToken = encodeURIComponent(trackingToken);
  const encodedcustomerToken = encodeURIComponent(customerToken);
  const encodedssn = encodeURIComponent(
    Values.ssn.slice(0, 3) +
      '-' +
      Values.ssn.slice(3, 5) +
      '-' +
      Values.ssn.slice(5)
  );

  const validateSSN = async () => {
    const response = await fetch(
      `https://${environment}/api/signup/validate/ssn?clientKey=${clientKey}&trackingToken=${trackingToken}&customerToken=${customerToken}&ssn=${Values.ssn}`
    );
    if (response.ok === true) {
      return;
    } else {
      throw Error('Invalid SSN !');
    }
  };
  // ! updating identity

  const encodedfirstName = encodeURIComponent(firstName);
  const encodedlastName = encodeURIComponent(lastName);
  const encodedphoneNumber = encodeURIComponent(phoneNumber);
  const encodedtruth = encodeURIComponent('true');
  const encodedstreetAdress = encodeURIComponent(Values.streetAdress);
  const encodedcity = encodeURIComponent(Values.city);
  const encodedstate = encodeURIComponent(Values.state_country);
  const encodedzip = encodeURIComponent(Values.zipCode);
  const encodedbirthDate = encodeURIComponent(
    Values.birthDate.slice(5, 7) +
      '/' +
      Values.birthDate.slice(8) +
      '/' +
      Values.birthDate.slice(0, 4)
  );

  // const encodedbirthDate = encodeURIComponent(Values.birthDate);
  const encodedssnpartial = encodeURIComponent(Values.ssn.slice(5));
  // &identity.ssn=${encodedssn}
  const encodedsecurityQuestionAnswersecurityQuestionId =
    encodeURIComponent('1');
  const encodedsecurityQuestionAnsweranswer = encodeURIComponent('hawk');

  const dataUpdate = `clientKey=${encodedclientKey}&trackingToken=${encodedtrackingToken}&customerToken=${encodedcustomerToken}&firstName=${encodedfirstName}&lastName=${encodedlastName}&homePhone=${encodedphoneNumber}&isBrowserConnection=${encodedtruth}&isConfirmedTerms=${encodedtruth}&identity.ssn=${encodedssn}&homeAddress.street=${encodedstreetAdress}&homeAddress.zip=${encodedzip}&identity.ssnPartial=${encodedssnpartial}&identity.birthDate=${encodedbirthDate}&homeAddress.city=${encodedcity}&homeAddress.state=${encodedstate}&securityQuestionAnswer.securityQuestionId=${encodedsecurityQuestionAnswersecurityQuestionId}&securityQuestionAnswer.answer=${encodedsecurityQuestionAnsweranswer}`;

  const customerUpdateIdentity = async () => {
    const response = await fetch(
      `https://${environment}/api/signup/customer/update/identity`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: dataUpdate,
      }
    );
    let data = await response.json();

    if (response.ok === true) {
      return;
    } else if (
      data.canCollectPartialSsn === false &&
      data.errors[0].code === 'SSN_NO_MATCH'
    ) {
      await customerUpdateIdentity();
      return;
    } else {
      throw Error(data.errors[0].code);
    }
  };

  const handleChange = (e) => {
    setValues({ ...Values, [e.target.name]: e.target.value });
  };

  // !-------------------------------------------------------------------

  // ! for id verification

  const [referenceNumber, setreferanceNumber] = useState('');

  const questionData = {
    question: '',
    choice1: '',
    choice2: '',
    choice3: '',
    choice4: '',
    none: 'None of the above',
  };

  const [question1, setQuestion1] = useState(questionData);
  const [question2, setQuestion2] = useState(questionData);
  const [question3, setQuestion3] = useState(questionData);

  // !get

  const idVerificationGet = async () => {
    const response = await fetch(
      `https://${environment}/api/signup/id-verification?clientKey=${clientKey}&trackingToken=${trackingToken}&customerToken=${customerToken}`
    );
    if (response.ok === true) {
      const data = await response.json();
      setQuestion1({
        ...question1,
        question: data.idVerificationCriteria.question1.displayName,
        choice1:
          data.idVerificationCriteria.question1.choiceList.choice[0].display,
        choice2:
          data.idVerificationCriteria.question1.choiceList.choice[1].display,
        choice3:
          data.idVerificationCriteria.question1.choiceList.choice[2].display,
        choice4:
          data.idVerificationCriteria.question1.choiceList.choice[3].display,
      });
      setQuestion2({
        ...question2,
        question: data.idVerificationCriteria.question2.displayName,
        choice1:
          data.idVerificationCriteria.question2.choiceList.choice[0].display,
        choice2:
          data.idVerificationCriteria.question2.choiceList.choice[1].display,
        choice3:
          data.idVerificationCriteria.question2.choiceList.choice[2].display,
        choice4:
          data.idVerificationCriteria.question2.choiceList.choice[3].display,
      });
      setQuestion3({
        ...question3,
        question: data.idVerificationCriteria.question3.displayName,
        choice1:
          data.idVerificationCriteria.question3.choiceList.choice[0].display,
        choice2:
          data.idVerificationCriteria.question3.choiceList.choice[1].display,
        choice3:
          data.idVerificationCriteria.question3.choiceList.choice[2].display,
        choice4:
          data.idVerificationCriteria.question3.choiceList.choice[3].display,
      });
      setreferanceNumber(data.idVerificationCriteria.referenceNumber);
      // console.log(data.idVerificationCriteria.referenceNumber);
    } else {
      throw Error('Error Occured! Try Again');
    }
  };

  //  !-----------------------------------------------------------------------------------------

  const [answer1, setAnswer1] = useState('');
  const [answer2, setAnswer2] = useState('');
  const [answer3, setAnswer3] = useState('');

  // !post

  const encodedreferenceNumber = encodeURIComponent(referenceNumber);
  const encodedAnswer1 = encodeURIComponent(answer1);
  const encodedAnswer2 = encodeURIComponent(answer2);
  const encodedAnswer3 = encodeURIComponent(answer3);
  // const encodedAnswer4 = encodeURIComponent('2003');
  // const encodedAnswer5 = encodeURIComponent('2003');
  // &idVerificationCriteria.answer4=${encodedAnswer4}&idVerificationCriteria.answer5=${encodedAnswer5}

  const idVerificationData = `clientKey=${encodedclientKey}&trackingToken=${encodedtrackingToken}&customerToken=${encodedcustomerToken}&idVerificationCriteria.referenceNumber=${encodedreferenceNumber}&idVerificationCriteria.answer1=${encodedAnswer1}&idVerificationCriteria.answer2=${encodedAnswer2}&idVerificationCriteria.answer3=${encodedAnswer3}`;

  const idVerificationPost = async () => {
    const response = await fetch(
      `https://${environment}/api/signup/id-verification`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: idVerificationData,
      }
    );
    const data = await response.json();
    // console.log(data.errors[0].code);
    if (response.ok === true) {
      console.log('success');
    } else if (data.errors[0].code === 'ID_ALREADY_VERIFIED') {
      console.log('success');
    } else {
      throw Error('Id Verifiacation Error! Try Again ');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await createAccount();
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [disableButton, setdisableButton] = useState(false);

  const [showQus, setShowQus] = useState(false);

  const apiCalls = async () => {
    try {
      await validateSSN();
      await customerUpdateIdentity();
      await idVerificationGet();
      setShowQus(true);
      setdisableButton(true);
      setIsLoading(false);
    } catch (err) {
      displayAlert(err.message);
      setIsLoading(false);
    }
  };

  const OnSubmission = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    stateUpdateB(
      Values.ssn,
      Values.birthDate,
      Values.streetAdress,
      Values.zipCode,
      Values.city,
      Values.state_country,
      customerToken
    );
    await apiCalls();
  };

  const submitQusForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await idVerificationPost();
      updatePageName('page3');
      navigate('/smart-credit/package');
    } catch (error) {
      displayAlert(error.message);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const ansCheck1 = (e) => {
    if (e.target.checked) {
      setAnswer1(e.target.value);
    }
    return;
  };
  const ansCheck2 = (e) => {
    if (e.target.checked) {
      setAnswer2(e.target.value);
    }
    return;
  };
  const ansCheck3 = (e) => {
    if (e.target.checked) {
      setAnswer3(e.target.value);
    }
    return;
  };

  return (
    <div className='grid place-items-center'>
      {/* <div>
        <NavBar pagename='credit-monitoring' />
      </div> */}

      <div className='box_shadow_universal grid place-items-start w-[1230px] sm:w-[auto] my-[100px] sm:my-4 shadow-ss_white '>
        {/* //! page 1 */}
        <div className='grid ml-[40px] sm:ml-0'>
          <div className=' sm:ml-4'>
            <CompanyLogo />
          </div>
          {/* //! process bar */}
          <CreditPageNav processState='verify' />
          {/* //! form */}
          <form
            onSubmit={OnSubmission}
            className='grid sm:grid-flow-row grid-cols-2 sm:grid-cols-1 text-[23px] sm:text-xs leading-[34px] sm:leading-4 mt-[60px] sm:mt-4 mx-4'
          >
            <div className='grid grid-flow-row mr-8  mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
              <label className=' text-ss_grey'>Name</label>
              <input
                name='name'
                defaultValue={firstName + ' ' + lastName}
                disabled
                className='w-[544px] h-[67px] sm:w-[92%] sm:h-[33px] bg-ss_white text-lg rounded-[5px] p-4'
              />
            </div>
            <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
              <label className=' text-ss_grey'>Social Security Number</label>
              <input
                onChange={handleChange}
                name='ssn'
                defaultValue={Values.ssn}
                type='text'
                maxLength='9'
                minLength='9'
                required
                className='w-[544px] h-[67px] sm:w-[92%] sm:h-[33px] bg-ss_white text-lg rounded-[5px] p-4'
              />
            </div>
            <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
              <label className=' text-ss_grey'>Birth Date</label>
              <input
                onChange={handleChange}
                name='birthDate'
                defaultValue={Values.birthDate}
                required
                type='date'
                min='1970-01-01'
                max='2001-01-01'
                className='w-[544px] h-[67px] sm:w-[92%] sm:h-[33px] bg-ss_white text-lg rounded-[5px] p-4'
              />
            </div>
            <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
              <label className=' text-ss_grey'>Street Address</label>
              <input
                onChange={handleChange}
                name='streetAdress'
                maxLength={60}
                defaultValue={Values.streetAdress}
                required
                className='w-[544px] h-[67px] sm:w-[92%] sm:h-[33px] bg-ss_white text-lg rounded-[5px] p-4'
              />
            </div>
            <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
              <label className=' text-ss_grey'>City</label>
              <input
                onChange={handleChange}
                name='city'
                defaultValue={Values.city}
                maxLength={100}
                required
                className='w-[544px] h-[67px] sm:w-[92%] sm:h-[33px] bg-ss_white text-lg rounded-[5px] p-4'
              />
            </div>
            <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
              <label className=' text-ss_grey'>State</label>
              <input
                onChange={handleChange}
                name='state_country'
                maxLength={2}
                minLength={2}
                defaultValue={Values.state_country}
                required
                className='w-[544px] h-[67px] sm:w-[92%] sm:h-[33px] bg-ss_white text-lg rounded-[5px] p-4'
              />
            </div>
            <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
              <label className=' text-ss_grey'>Zip Code</label>
              <input
                onChange={handleChange}
                name='zipCode'
                minLength={5}
                maxLength={5}
                type='tel'
                pattern='^\d{5}$'
                defaultValue={Values.zipCode}
                required
                className='w-[544px] h-[67px] sm:w-[92%] sm:h-[33px] bg-ss_white text-lg rounded-[5px] p-4'
              />
            </div>
            <div className='grid grid-flow-row mr-8 mb-2 gap-2 sm:col-span-2  sm:mr-0 '>
              <label className='text-ss_grey'>Phone</label>
              <input
                name='phoneNumber'
                defaultValue={phoneNumber}
                type='number'
                minLength={10}
                maxLength={10}
                disabled
                className='w-[544px] h-[67px] sm:w-[92%] sm:h-[33px] bg-ss_white text-lg rounded-[5px] p-4'
              />
            </div>
            <button
              disabled={disableButton}
              className=' py-2 px-4 w-[113px] sm:w-[71px] h-[51px] sm:h-[33px] text-[23px] sm:text-[11px] leading-[34px] sm:leading-4 bg-ss_primary rounded-[5px] text-ss_white mt-8  sm:mb-10 z-50'
            >
              Submit
            </button>
            <div className='ml-[-950px] sm:ml-[-330px] mt-[33px]'>
              {showAlert && <Alert />}
            </div>
          </form>

          {showQus && (
            <form
              onSubmit={submitQusForm}
              className=' flex flex-col justify-start gap-4 sm:gap-2 px-4 sm:w-full mt-6 '
            >
              <h1 className='text-3xl sm:text-xl font-aulire mb-6 sm:mb-2'>
                Please answer these questions :
              </h1>
              <div className=' flex justify-start flex-col gap-2 sm:max-w-[350px]'>
                <p className=' text-2xl sm:text-sm'>{question1.question}</p>
                <div className=' flex gap-10 sm:gap-3 sm:text-[10px] text-center'>
                  <div className=' flex sm:flex-col gap-3 sm:gap-1'>
                    <input
                      className=' cursor-pointer'
                      type='radio'
                      value={question1.choice1}
                      name='question1'
                      onChange={ansCheck1}
                    />
                    <label htmlFor='question1'>{question1.choice1}</label>
                  </div>
                  <div className=' flex gap-3 sm:gap-1 sm:flex-col'>
                    <input
                      className=' cursor-pointer'
                      type='radio'
                      value={question1.choice2}
                      name='question1'
                      onChange={ansCheck1}
                    />
                    <label htmlFor='question1'>{question1.choice2}</label>
                  </div>
                  <div className=' flex gap-3 sm:gap-1 sm:flex-col'>
                    <input
                      className=' cursor-pointer'
                      type='radio'
                      value={question1.choice3}
                      name='question1'
                      onChange={ansCheck1}
                    />
                    <label htmlFor='question1'>{question1.choice3}</label>
                  </div>
                  <div className=' flex gap-3 sm:gap-1 sm:flex-col'>
                    <input
                      className=' cursor-pointer'
                      type='radio'
                      value={question1.choice4}
                      name='question1'
                      onChange={ansCheck1}
                    />
                    <label htmlFor='question1'>{question1.choice4}</label>
                  </div>
                  <div className='flex gap-3 sm:gap-1 sm:flex-col'>
                    <input
                      className=' cursor-pointer'
                      type='radio'
                      value={question1.none}
                      name='question1'
                      onChange={ansCheck1}
                    />
                    <label htmlFor='question1'>{question1.none}</label>
                  </div>
                </div>
              </div>

              <div className=' flex justify-start flex-col gap-2'>
                <p className=' text-2xl sm:text-sm'>{question2.question}</p>
                <div className=' flex gap-10 sm:gap-3 sm:text-[10px] text-center'>
                  <div className=' flex gap-3 sm:gap-1 sm:flex-col'>
                    <input
                      className=' cursor-pointer'
                      type='radio'
                      value={question2.choice1}
                      name='question2'
                      onChange={ansCheck2}
                    />
                    <label htmlFor='question2'>{question2.choice1}</label>
                  </div>
                  <div className='flex gap-3 sm:gap-1 sm:flex-col'>
                    <input
                      className=' cursor-pointer'
                      type='radio'
                      value={question2.choice2}
                      name='question2'
                      onChange={ansCheck2}
                    />
                    <label htmlFor='question2'>{question2.choice2}</label>
                  </div>
                  <div className='flex gap-3 sm:gap-1 sm:flex-col'>
                    <input
                      className=' cursor-pointer'
                      type='radio'
                      value={question2.choice3}
                      name='question2'
                      onChange={ansCheck1}
                    />
                    <label htmlFor='question2'>{question2.choice3}</label>
                  </div>
                  <div className='flex gap-3 sm:gap-1 sm:flex-col'>
                    <input
                      className=' cursor-pointer'
                      type='radio'
                      value={question2.choice4}
                      name='question2'
                      onChange={ansCheck2}
                    />
                    <label htmlFor='question2'>{question2.choice4}</label>
                  </div>
                  <div className='flex gap-3 sm:gap-1 sm:flex-col'>
                    <input
                      className=' cursor-pointer'
                      type='radio'
                      value={question2.none}
                      name='question2'
                      onChange={ansCheck2}
                    />
                    <label htmlFor='question2'>{question2.none}</label>
                  </div>
                </div>
              </div>

              <div className=' flex justify-start flex-col gap-2'>
                <p className=' text-2xl sm:text-lg'>{question3.question}</p>
                <div className=' flex gap-10 sm:gap-1 sm:text-[10px] text-center'>
                  <div className=' flex gap-3 sm:gap-1 sm:flex-col'>
                    <input
                      className=' cursor-pointer'
                      type='radio'
                      value={question3.choice1}
                      name='question3'
                      onChange={ansCheck3}
                    />
                    <label htmlFor='question3'>{question3.choice1}</label>
                  </div>
                  <div className=' flex gap-3 sm:gap-1 sm:flex-col'>
                    <input
                      className=' cursor-pointer'
                      type='radio'
                      value={question3.choice2}
                      name='question3'
                      onChange={ansCheck3}
                    />
                    <label htmlFor='question3'>{question3.choice2}</label>
                  </div>
                  <div className=' flex gap-3 sm:gap-1 sm:flex-col'>
                    <input
                      className=' cursor-pointer'
                      type='radio'
                      value={question3.choice3}
                      name='question3'
                      onChange={ansCheck3}
                    />
                    <label htmlFor='question3'>{question3.choice3}</label>
                  </div>

                  <div className=' flex gap-3 sm:gap-1 sm:flex-col'>
                    <input
                      className=' cursor-pointer'
                      type='radio'
                      value={question3.choice4}
                      name='question3'
                      onChange={ansCheck3}
                    />
                    <label htmlFor='question3'>{question3.choice4}</label>
                  </div>
                  <div className=' flex gap-3 sm:gap-1 sm:flex-col'>
                    <input
                      className=' cursor-pointer'
                      type='radio'
                      value={question3.none}
                      name='question3'
                      onChange={ansCheck3}
                    />
                    <label htmlFor='question3'>{question3.none}</label>
                  </div>
                </div>
              </div>

              <button
                type='submit'
                className=' py-2 px-4 w-[113px] sm:w-[71px] h-[51px] sm:h-[33px] text-[23px] sm:text-[11px] leading-[34px] sm:leading-4 bg-ss_primary rounded-[5px] text-ss_white mt-8 mb-20 sm:mb-10 z-50'
              >
                Verify
              </button>
            </form>
          )}

          <SmartAgreement />
        </div>

        {/* <div>
          <Footer />
        </div> */}
      </div>
    </div>
  );
};

export default Page2;
